import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxOutlined, CropSquareOutlined } from "@mui/icons-material";
import { Card, Grid, IconButton, Tab, Table, TableCell, TableContainer, TableRow, Tabs } from "@mui/material";
import { Box, Button, ControlLabelCheckBox, CustomIcon, DataTableBodyCell, DataTableHeadCell, FormField, Modal, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import DashboardLayout from "layout/dashboardLayout";
import SystemSettingService from "services/sale/systemsetting.service";

import { RootState } from "store";
import { IInput } from "commons/interfaces";
import { onUpdateSetting } from "./systemSetting";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ActionRequest, IsTrue, Mode, SystemSettingCode } from "constants/enum";


interface IPaymentGatewayRequest {
    id: string;
    tax?: IInput;
    name?: IInput;
    credentials?: IInput;
    isDefault?: number;
    action: number;
}

enum ValPaymentGateway {
    web = "web",
    ios = "ios",
    android = "android",
}

const systemSettingService = new SystemSettingService();

const SettingPaymentGatewayScreen = () => {
    const dispatch = useDispatch();

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);

    const [tabIndex, setTabIndex] = useState<number>(0);
    const [tabType, setTabType] = useState<any>(ValPaymentGateway.android);
    const arrTabLabel = [ValPaymentGateway.web, ValPaymentGateway.ios, ValPaymentGateway.android];

    const [model, setModel] = useState<any>({ mode: Mode.View });
    const [dataSettingCode, setDataSettingCode] = useState<{
        id?: string;
        settingCode?: string;
        serviceCode?: string;
        organizationId?: string;
    }>({});

    const [valueWeb, setValueWeb] = useState<IPaymentGatewayRequest[]>([]);
    const [valueIos, setValueIos] = useState<IPaymentGatewayRequest[]>([]);
    const [valueAndroid, setValueAndroid] = useState<IPaymentGatewayRequest[]>([]);
    const [dataModal, setDataModal] = useState<{ open: boolean, data?: IPaymentGatewayRequest }>({ open: false });

    useEffect(() => { onStarted(); }, [userProfile?.organizationId]);

    const onStarted = async () => {
        try {
            dispatch(showLoading(true));

            const result = await systemSettingService.getDetail(SystemSettingCode.PaymentGateway, userProfile?.organizationId);

            const dataParse = JSON.parse((`${result.settingValue}`));

            arrTabLabel.forEach(key => {
                const valueArray = dataParse[key];
                if (!Helpers.isNullOrEmpty(valueArray) && valueArray.length > 0) {
                    let newValue: IPaymentGatewayRequest[] = [];
                    valueArray.forEach((k: any, index: number) => {
                        newValue.push({
                            id: index + key,
                            action: ActionRequest.Update,
                            tax: { value: Number(k.tax) * 100 },
                            name: { value: k.name },
                            isDefault: k.isDefault,
                            credentials: { value: k.credentials },
                        });
                    });

                    if (key === ValPaymentGateway.web) {
                        setValueWeb(newValue);
                    } else if (key === ValPaymentGateway.ios) {
                        setValueIos(newValue);
                    } else if (key === ValPaymentGateway.android) {
                        setValueAndroid(newValue);
                    } else { }
                }
            });

            setDataSettingCode({
                id: result.id,
                settingCode: result.settingCode,
                serviceCode: result.serviceCode,
                organizationId: result.organizationId,
            });

            setModel({
                mode: Mode.View,
                title: Strings.SYSTEM_SETTING.DETAIL,
                route: [
                    {
                        title: Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Title,
                        route: "",
                    },
                ],
            });

        } catch (error: any) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    };

    const getFormatValue = (newdata: IPaymentGatewayRequest[]) => {
        let temp: any[] = [];

        newdata.forEach(el => {
            temp.push({
                name: el.name?.value,
                tax: (Number(el.tax?.value) / 100),
                credentials: el.credentials?.value,
                isDefault: el.isDefault,
            });
        });

        return temp;
    }

    const handleSubmit = async () => {
        if (model.mode === Mode.View) {
            setModel({
                mode: Mode.Update,
                title: Strings.SYSTEM_SETTING.UPDATE,
                route: [
                    {
                        title: Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Title,
                        route: "",
                    },
                ],
            });
        } else {
            let isCheckNull: boolean = false;

            if ([...valueAndroid || []].length === 0) {
                isCheckNull = true;
            }
            if ([...valueIos || []].length === 0) {
                isCheckNull = true;
            }
            if ([...valueWeb || []].length === 0) {
                isCheckNull = true;
            }

            if (isCheckNull) {
                dispatch(setDataAlert({ message: "Vui lòng thêm cổng thanh toán", type: "error" }))
            } else {
                let settingValueTemp: any = {
                    [ValPaymentGateway.android]: getFormatValue(valueAndroid),
                    [ValPaymentGateway.ios]: getFormatValue(valueIos),
                    [ValPaymentGateway.web]: getFormatValue(valueWeb),
                };

                const newDataSubmit = {
                    id: dataSettingCode?.id,
                    settingCode: dataSettingCode?.settingCode,
                    settingValue: JSON.stringify(settingValueTemp),
                    serviceCode: dataSettingCode.serviceCode || Constants.SERVICE_CODE,
                    organizationId: (
                        Helpers.isNullOrEmpty(dataSettingCode?.organizationId)
                        || (dataSettingCode?.organizationId === "0")
                    )
                        ? (userProfile?.organizationId || "0")
                        : dataSettingCode?.organizationId,
                };

                onUpdateSetting({
                    dispatch: dispatch,
                    query: newDataSubmit,
                    callback() {
                        onStarted();
                    },
                });
            }
        }
    }

    const onEditItemByType = (type: string, idEdit: string) => {
        const checked = onCheckValidateDataModal();
        if (checked) {
            let isVal: boolean = true;
            let dataTemp: IPaymentGatewayRequest[] = [];

            // Android
            if (ValPaymentGateway.android === type) {
                dataTemp = [...valueAndroid || []];
            }
            // IOS
            if (ValPaymentGateway.ios === type) {
                dataTemp = [...valueIos || []];
            }
            // WEB
            if (ValPaymentGateway.web === type) {
                dataTemp = [...valueWeb || []];
            }

            const index = dataTemp.findIndex(el => el.id === idEdit);
            if (index !== -1) {
                if (dataModal?.data?.isDefault === IsTrue.True) {
                    dataTemp = dataTemp.map(item => {
                        item.isDefault = IsTrue.False;
                        return item;
                    });
                    dataTemp[index] = { ...dataModal?.data };
                } else {
                    if (dataTemp.findIndex(item => (item.isDefault === IsTrue.True) && (item.id !== idEdit)) === -1) {
                        isVal = false;
                        dispatch(setDataAlert({ message: "Vui lòng chọn cổng thanh toán mặc định", type: "error" }));
                    } else {
                        dataTemp[index] = { ...dataModal?.data };
                    }
                }

                if (isVal) {
                    // Android
                    if (ValPaymentGateway.android === type) {
                        setValueAndroid(dataTemp);
                    }
                    // IOS
                    if (ValPaymentGateway.ios === type) {
                        setValueIos(dataTemp);
                    }
                    // WEB
                    if (ValPaymentGateway.web === type) {
                        setValueWeb(dataTemp);
                    }
                    setDataModal({ open: false });
                } else { }
            } else {
                dispatch(setDataAlert({ message: "Không tìm thấy id", type: "error" }))
            }
        }
    };

    const onAddNewItemByType = (type: any) => {
        const checked = onCheckValidateDataModal();
        if (checked) {
            let dataTemp: IPaymentGatewayRequest[] = [];

            if (ValPaymentGateway.android === type) {
                dataTemp = [...valueAndroid || []];
            } else if (ValPaymentGateway.ios === type) {
                dataTemp = [...valueIos || []];
            } else if (ValPaymentGateway.web === type) {
                dataTemp = [...valueWeb || []];
            } else { }

            let isVal: boolean = true;
            const newID = Date.now().toString();
            if (dataTemp.length === 0) {
                dataTemp.push({
                    ...dataModal?.data,
                    id: newID,
                    isDefault: IsTrue.True,
                    action: ActionRequest.Create,
                });
            } else {
                if (dataModal?.data?.isDefault === IsTrue.True) {
                    dataTemp = dataTemp.map(item => {
                        item.isDefault = IsTrue.False;
                        return item;
                    });

                    dataTemp.push({
                        ...dataModal?.data,
                        id: newID,
                        action: ActionRequest.Create,
                    });
                } else {
                    const index = dataTemp.findIndex(item => item.isDefault === IsTrue.True);
                    if (index === -1) {
                        isVal = false;
                        dispatch(setDataAlert({ message: "Vui lòng chọn cổng thanh toán mặc định", type: "error" }))
                    } else {
                        dataTemp.push({
                            ...dataModal?.data,
                            id: newID,
                            action: ActionRequest.Create,
                        });
                    }
                }
            }

            if (isVal) {
                if (ValPaymentGateway.android === type) {
                    setValueAndroid(dataTemp);
                }
                if (ValPaymentGateway.ios === type) {
                    setValueIos(dataTemp);
                }
                if (ValPaymentGateway.web === type) {
                    setValueWeb(dataTemp);
                }
                setDataModal({ open: false });
            }
        }
    };

    const onDeleteItemByType = (type: any, id: string) => {
        if (ValPaymentGateway.android === type) {
            let dataTemp: any = [...valueAndroid || []].filter(el => el.id !== id) || [];
            setValueAndroid(dataTemp);
        }
        if (ValPaymentGateway.ios === type) {
            let dataTemp: any = [...valueIos || []].filter(el => el.id !== id) || [];
            setValueIos(dataTemp);
        }
        if (ValPaymentGateway.web === type) {
            let dataTemp: any = [...valueWeb || []].filter(el => el.id !== id) || [];
            setValueWeb(dataTemp);
        }
    };

    const renderTableCodeValue = (type: any, data: IPaymentGatewayRequest[]) => (
        <Box m={0}>
            {
                model.mode !== Mode.View &&
                <Box mt={1} display="flex" alignItems="center" justifyContent="end">
                    <Button color="info" onClick={() => { setDataModal({ open: true }); }} >
                        {Strings.Common.ADD_NEW}
                    </Button>
                </Box>
            }
            <Box mt={1}>
                <TableContainer>
                    <Table>
                        <Box component="thead">
                            <TableRow>
                                <DataTableHeadCell width={30} sorted={false}>STT</DataTableHeadCell>
                                <DataTableHeadCell sorted={false}>&ensp;{Strings.Common.ACTION}</DataTableHeadCell>
                                <DataTableHeadCell sorted={false}>{Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.IsDefault}</DataTableHeadCell>
                                <DataTableHeadCell sorted={false}>{Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Name}</DataTableHeadCell>
                                <DataTableHeadCell sorted={false}>{Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Credentials}</DataTableHeadCell>
                                <DataTableHeadCell sorted={false}>{Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Tax}</DataTableHeadCell>
                            </TableRow>
                        </Box>
                        {(data.length === 0)
                            ? (
                                <TableRow>
                                    <TableCell colSpan={model.mode === Mode.View ? 3 : 4}>
                                        <Box textAlign="center">
                                            <Typography variant="button" color="secondary" fontWeight="regular">
                                                {Strings.Common.NO_DATA}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                            : data.map((element, index) => (
                                <TableRow key={index}>
                                    <TableCell padding="checkbox">
                                        <Typography variant="caption" fontWeight="medium" color="text">
                                            &ensp;&ensp;{index + 1}
                                        </Typography>
                                    </TableCell>
                                    <DataTableBodyCell>
                                        <IconButton sx={{ p: 0 }}
                                            onClick={() => {
                                                setDataModal({ open: true, data: element });
                                            }}
                                        >
                                            <CustomIcon iconName={(model.mode !== Mode.View) ? "edit" : "view"} />
                                        </IconButton>
                                        {
                                            (model.mode !== Mode.View) &&
                                            <IconButton sx={{ p: 0, pl: 2 }}
                                                onClick={() => {
                                                    onDeleteItemByType(type, element.id);
                                                }}
                                            >
                                                <CustomIcon iconName="delete" />
                                            </IconButton>
                                        }
                                    </DataTableBodyCell>
                                    <DataTableBodyCell>
                                        <IconButton sx={{ p: 0 }} disabled>
                                            {(element.isDefault === IsTrue.True)
                                                ? <CheckBoxOutlined color="info" />
                                                : <CropSquareOutlined />
                                            }
                                        </IconButton>
                                    </DataTableBodyCell>
                                    <DataTableBodyCell width={200}>{element.name?.value}</DataTableBodyCell>
                                    <DataTableBodyCell width={200}>{element.credentials?.value}</DataTableBodyCell>
                                    <DataTableBodyCell>{`${element.tax?.value || ""} %`}</DataTableBodyCell>
                                </TableRow>
                            ))
                        }
                    </Table>
                </TableContainer>
            </Box>
        </Box >
    );

    const onChangeValueDataModal = (key: string, newValue: any) => {
        let dataTemp: any = { ...dataModal?.data };
        if (key === "isDefault") {
            dataTemp[key] = newValue;
        } else {
            dataTemp[key] = { value: newValue };
        }
        setDataModal({ open: true, data: dataTemp });
    }

    const onCheckValidateDataModal = () => {
        let checked: boolean = true;
        let dataTemp: any = { ...dataModal?.data };

        if (Helpers.isNullOrEmpty(dataTemp?.name?.value)) {
            checked = false;
            dataTemp["name"] = { error: Strings.Validation.REQUIRED };
        }
        if (Helpers.isNullOrEmpty(dataTemp?.credentials?.value)) {
            checked = false;
            dataTemp["credentials"] = { error: Strings.Validation.REQUIRED };
        }
        if (Helpers.isNullOrEmpty(dataTemp?.tax?.value)) {
            checked = false;
            dataTemp["tax"] = { error: Strings.Validation.REQUIRED };
        }

        if (!checked) {
            setDataModal({ open: true, data: dataTemp });
        }

        return checked;
    };

    const renderModal = () => (
        <Modal
            fullWidth
            maxWidth={"md"}
            visible={dataModal?.open || false}
            hasActionButton={model.mode !== Mode.View}
            onClose={() => { setDataModal({ open: false }); }}
            onAction={() => {
                Helpers.isNullOrEmpty(dataModal?.data?.id)
                    ? onAddNewItemByType(tabType)
                    : onEditItemByType(tabType, dataModal?.data?.id)
            }}
            title={`${Helpers.isNullOrEmpty(dataModal?.data?.id)
                ? Strings.Common.ADD_NEW
                : (model.mode !== Mode.View)
                    ? Strings.Common.UPDATE
                    : Strings.Common.DETAIL
                } cổng thanh toán trên ${tabType}`}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ControlLabelCheckBox
                        mode={model.mode}
                        value={dataModal?.data?.isDefault === IsTrue.True}
                        label={Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.IsDefault}
                        onChangeValue={(value) => {
                            onChangeValueDataModal("isDefault", value ? IsTrue.True : IsTrue.False);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        required
                        maxLength={255}
                        mode={model.mode}
                        variant="outlined"
                        defaultValue={dataModal?.data?.name?.value}
                        errorMessage={dataModal?.data?.name?.error}
                        label={Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Name}
                        placeholder={Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Enter_Name}
                        onBlur={(value) => {
                            onChangeValueDataModal("name", value);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        required
                        multiline
                        mode={model.mode}
                        variant="outlined"
                        defaultValue={dataModal?.data?.credentials?.value}
                        errorMessage={dataModal?.data?.credentials?.error}
                        label={Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Credentials}
                        placeholder={Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Enter_Credentials}
                        onBlur={(value) => {
                            onChangeValueDataModal("credentials", value);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        isMoney
                        required
                        unit={"%"}
                        type="number"
                        maxLength={10}
                        mode={model.mode}
                        variant="outlined"
                        decimalFormat="3,1"
                        value={dataModal?.data?.tax?.value}
                        errorMessage={dataModal?.data?.tax?.error}
                        label={Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Tax}
                        placeholder={Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Enter_Tax}
                        onChangeValue={(value) => {
                            const newVal = (Number(value) > 100) ? 100 : ((Number(value) < 0) ? 0 : value);
                            onChangeValueDataModal("tax", newVal);
                        }}
                    />
                </Grid>
            </Grid>
        </Modal >
    );

    return (
        <DashboardLayout
            isPermission
            title={model.title}
            route={model.route}
            onActionPress={handleSubmit}
            nameActionPress={(model.mode === Mode.View) ? Strings.Common.EDIT : Strings.Common.SAVE}
        >
            {renderModal()}
            <Card>
                <Box p={2}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tabIndex}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            onChange={(event: any, newValue: number) => {
                                let isCheckNull: boolean = false;

                                if (ValPaymentGateway.android === tabType) {
                                    isCheckNull = [...valueAndroid || []].length === 0;
                                } else if (ValPaymentGateway.ios === tabType) {
                                    isCheckNull = [...valueIos || []].length === 0;
                                } else if (ValPaymentGateway.web === tabType) {
                                    isCheckNull = [...valueWeb || []].length === 0;
                                } else { isCheckNull = false }

                                if (isCheckNull) {
                                    dispatch(setDataAlert({ message: "Vui lòng thêm cổng thanh toán", type: "error" }))
                                } else {
                                    const newType = arrTabLabel[newValue];
                                    setTabIndex(newValue);
                                    setTabType(newType);
                                }
                            }}
                        >
                            {
                                arrTabLabel.map((label, index: number) => (
                                    <Tab key={index} label={label} />
                                ))
                            }
                        </Tabs>
                    </Box>
                    {
                        (tabType === ValPaymentGateway.web) &&
                        renderTableCodeValue(ValPaymentGateway.web, valueWeb)
                    }
                    {
                        (tabType === ValPaymentGateway.ios) &&
                        renderTableCodeValue(ValPaymentGateway.ios, valueIos)
                    }
                    {
                        (tabType === ValPaymentGateway.android) &&
                        renderTableCodeValue(ValPaymentGateway.android, valueAndroid)
                    }
                </Box >
            </Card>
        </DashboardLayout>
    );
};

export default SettingPaymentGatewayScreen;
