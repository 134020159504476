import { useState } from "react";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

import Strings from "constants/strings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@maysoft/common-component-react";

import { arrow, textMore } from "./styles";

const ReadMore = (props: any) => {
    const [readMore, setReadMore] = useState(false);
    return (
        <Box>
            <Box sx={{ transition: "all 0.4s" }}>{readMore && props.children}</Box>
            <Box onClick={() => setReadMore(!readMore)}>
                <Typography sx={textMore}>{!readMore ? Strings.Common.READ_MORE : Strings.Common.COLLAPSE}</Typography>
                <ExpandMoreIcon sx={(theme: Theme) => arrow(theme, { isDown: readMore })} />
            </Box>
            <div className="separator"></div>
        </Box>
    );
};

export default ReadMore;
