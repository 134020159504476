import Helpers from "commons/helpers";
import Constants from "../../constants";
import SaleService from "./sale.service";

class SystemSettingService extends SaleService {

    public getBySettingCode = async (settingCode: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.SYSTEM_SETTING.GET_BY_SETTING_CODE + `/${settingCode}`,
        });
        return result.data.result;
    }

    public getListSettingCode = async (data: { settingCodes: any[], organizationId?: string, serviceCode?: string }) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: Constants.ApiPath.SYSTEM_SETTING.GET_BY_LIST_SETTING_CODES + `?${query}`,
        });
        return result.data.result;
    }

    public getSystemSetting = async (data: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.SYSTEM_SETTING.GET_SYSTEM_SETTING + `?${data}`,
        });
        return result.data.result;
    }

    public getDetail = async (settingCode: string, organizationId?: string) => {
        const query = !Helpers.isNullOrEmpty(organizationId) ? `?organizationId=${organizationId}` : "";

        const result = await this.api.get({
            path: Constants.ApiPath.SYSTEM_SETTING.GET_BY_SETTING_CODE + `/${settingCode}${query}`,
        });

        return result.data.result;
    }

    public update = async (data: { settingCode: string, settingValue: string }) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SYSTEM_SETTING.UPDATE,
            data: data
        });
        return result.data;
    }

}

export default SystemSettingService;