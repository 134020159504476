import { CircularProgress } from "@mui/material";
import MUIAutocomplete from "@mui/material/Autocomplete";
import { useEffect, useState, useRef, useCallback } from "react";

import Helpers from "commons/helpers";

import { debounce } from "lodash";
import { ICodename } from "commons/interfaces";
import { FormField } from "@maysoft/common-component-react";

interface IDataReq {
    pageNumber: number;
    searchText?: string,
}

interface IProps {
    size?: any;
    label?: string;
    placeholder?: string;
    multiple?: boolean;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    isSelectedBox?: boolean;
    variant?: "outlined" | "standard" | undefined;

    // dataOptions: ICodename[];
    defaultValue?: any,
    errorMessage?: string;
    onChange: (value: any) => void;

    allowLoadMore?: boolean;
    dataOptions: ICodename[];
    onGetAPI?: (params?: IDataReq) => void;
}

const AutocompleteLoadMore: React.FunctionComponent<IProps> = (props: IProps) => {
    const { dataOptions, allowLoadMore = true, onGetAPI } = props;

    const wrapperRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [defaultValue, setDefaultValue] = useState<any>(props.defaultValue);
    const [loadMoreRequestData, setLoadMoreRequestData] = useState<IDataReq>({
        searchText: undefined,
        pageNumber: 1,
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        if (loadMoreRequestData.pageNumber === 1 && loadMoreRequestData.searchText === undefined) {
            return;
        }
        onGetAPI?.({ pageNumber: 1, searchText: undefined });
    };

    useEffect(() => {
        setDefaultValue(props.defaultValue);
    }, [props.defaultValue])

    // useEffect(() => {
    //     function handleClickOutside(event: any) {
    //         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //             setOpen(false);
    //             setDataReq({ ...dataReq, searchText: "" });
    //         }
    //     }
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [wrapperRef]);

    const deboundGetPaged = useCallback(
        debounce(async (nextValue: string) => {
            await onGetAPI?.({ searchText: nextValue, pageNumber: 1 });
            setLoading(false);
        }, 500),
        [onGetAPI]
    );

    const formatValue = (v: any) => {
        if (dataOptions.length > 0) {
            if (props.multiple || Array.isArray(v)) {
                if ([...v || []].length > 0) {
                    const selectedOption: ICodename[] = []
                    v.forEach((item: any) => {
                        const temp = dataOptions.find(el => el.code == item)
                        temp && selectedOption.push(temp)
                    })
                    return selectedOption
                }
            } else {
                const selectedOption = dataOptions.find(el => el.code == v)
                return selectedOption || undefined;
            }
        } else {
            return undefined;
        }
    }

    const getPlaceholder = () => {
        if (props.multiple && Array.isArray(props.defaultValue)) {
            return (props.defaultValue.length > 0) ? "" : props.placeholder;
        } else {
            return Helpers.isNullOrEmpty(props.defaultValue) ? props.placeholder : "";
        }
    }

    return (
        <div ref={wrapperRef}>
            <MUIAutocomplete
                options={dataOptions}

                open={open}
                onOpen={handleOpen}
                onClose={handleClose}

                readOnly={props.readOnly}
                multiple={props.multiple}
                disabled={props.disabled}
                disableClearable={props.isSelectedBox}
                value={formatValue(defaultValue)}
                getOptionLabel={(option: any) => option?.name || ""}
                renderInput={(params) => (
                    <FormField {...params}
                        size={props.size}
                        label={props.label}
                        variant={props.variant}
                        disabled={props.disabled}
                        required={props.required}
                        value={loadMoreRequestData.searchText}
                        errorMessage={props.errorMessage}
                        placeholder={getPlaceholder()}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        onChangeValue={(value) => {
                            setLoading(true);
                            setLoadMoreRequestData(o => Object.assign({ searchText: value }, o));
                            deboundGetPaged(value)
                        }}
                    />
                )}
                onClick={(e) => { }}
                onChange={(e, selectedOption: any) => {
                    if (props.multiple && Array.isArray(selectedOption)) {
                        let result: string[] = [];
                        for (let items of selectedOption) {
                            result.push(items.code);
                        }
                        props.onChange(result)
                    } else {
                        props.onChange(selectedOption ? selectedOption?.code : undefined)
                    }
                    handleClose();
                }}
                renderOption={(props, option) => {
                    return (
                        <li {...props} data-id={option.code}>
                            {option.name}
                        </li>
                    );
                }}
                ListboxProps={allowLoadMore ? ({
                    onScroll: async (event: React.SyntheticEvent) => {
                        const listboxNode = event.currentTarget;

                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                            setLoading(true);
                            await onGetAPI?.({ pageNumber: loadMoreRequestData.pageNumber + 1, searchText: loadMoreRequestData.searchText });
                            setLoading(false);
                            const index = dataOptions.length - 4;
                            const movieToScrollTo = dataOptions?.[(index > 0) ? index : 0]?.code;
                            setTimeout(() => {
                                const optionEl = document.querySelector(
                                    `[data-id="${movieToScrollTo}"]`
                                );
                                optionEl?.scrollIntoView();
                            }, 3);
                        }
                    }
                }) : undefined}
            // PaperComponent={(param) => (
            //     loading
            //         ? <div style={{ display: "flex", alignItems: "center", }}>
            //             <CircularProgress color="inherit" size={20} />
            //             &emsp;<p>{"Loading..."}</p>
            //         </div>
            //         : <Paper {...param} />
            // )}
            />
        </div>
    )
}

export default AutocompleteLoadMore;
