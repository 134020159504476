const Screens = {
    // =========================================================================
    // Auth
    // =========================================================================
    AUTH: "/auth",
    LOGIN: "/auth/login",
    LOGIN_REDIRECT: "/auth/callback",

    NOT_FOUND: "/404",
    RESET_PASSWORD: "/resetPassAuth",
    SECOND_LOGIN: "/auth/second_login",
    FORGOT_PASSWORD: "/auth/forgot-password",

    // =========================================================================
    // Main
    // =========================================================================
    /** / */
    HOME: "/",
    PROFILE: "/profile",
    DASHBOARD: "/dashboard",

    // product template
    PRODUCT_TEMPLATE_EDIT: "/product-template/edit",
    PRODUCT_TEMPLATE_LIST: "/product-template/list",

    // product
    PRODUCT_LIST: "/product-module/product/list",
    PRODUCT_EDIT: "/product-module/product/edit",

    //bundle product
    BUNDLE_PRODUCT_LIST: "/product-module/bundle/list",
    BUNDLE_PRODUCT_EDIT: "/product-module/bundle/edit",

    //Membership
    MEMBERSHIP_LIST: "/product-module/membership/list",
    MEMBERSHIP_EDIT: "/product-module/membership/edit",

    PROMOTION_LIST: "/program-module/promotion/list",
    PROMOTION_EDIT: "/program-module/promotion/edit",

    VOUCHER_EDIT: "/productManage/promotion/voucher",

    CASH_BOOK: "/cashbook/list",
    PAYMENT_TICKET: "/cashbook/payment/edit",
    RECEIVE_VOUCHER_EDIT: "/cashbook/receiveVoucher/edit",

    //Customer
    CUSTOMER_LIST: "/customer-module/customer/list",
    CUSTOMER_EDIT: "/customer-module/customer/edit",
    LOYALTY_CUSTOMER: "/customer-module/loyalty-customer/setting",

    // Supplier
    SUPPLIER_LIST: "/partner/supplier/list",
    SUPPLIER_EDIT: "/partner/supplier/edit",

    // order
    ORDER_LIST: "/transaction-module/pos-order/list",
    ORDER_DETAIL: "/transaction-module/pos-order/view",
    ORDER_EDIT: "/transaction-module/pos-order/edit",

    // codename
    CODENAME_LIST: "/system-module/codename/list",
    CODENAME_EDIT: "/system-module/codename/edit",

    // attribute
    ATTRIBUTE_LIST: "/system-module/attribute/list",
    ATTRIBUTE_EDIT: "/system-module/attribute/edit",

    SYSTEM_SETTING: "/system-module/config",
    SETTING_PAYMENT_GATEWAY: "/system-module/payment-gateway",

    // program
    SPONSOR_PROGRAM_LIST: "/program-module/sponsor/list",
    SPONSOR_PROGRAM_EDIT: "/program-module/sponsor/edit",
    AFFILIATE_PROGRAM_LIST: "/program-module/affiliate/list",
    AFFILIATE_PROGRAM_EDIT: "/program-module/affiliate/edit",
    AFFILIATER_LIST: "/program-module/affiliater/list",

    PARTNER_COMMISSION_LIST: "/program-module/partner-commission/list",
    PARTNER_COMMISSION_DETAIL: "/program-module/partner-commission/detail",
    PARTNER_COMMISSION_CREATE: "/program-module/partner-commission/create",

    //order
    ORDER_NEW_VERSION_LIST: "/transaction-module/order/list",
    ORDER_NEW_VERSION_DETAIL: "/transaction-module/order/detail",

    //service-fee
    SERVICE_FEE_LIST: "/system-module/service-fee/list",
};

export default Screens;
