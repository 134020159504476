import Helpers from "commons/helpers";
import Constants from "constants/index";
import ProfileService from "services/identity/profile.service";

import { ICodename, IGroupUser, IOrganizationUserProfile, IUserAuthorization, IUserInfo } from "commons/interfaces";
import { GroupType, GroupUserDefault, OrganizationType, RoleLevel, RoleType } from "constants/enum";
import { call, put, takeLatest } from "redux-saga/effects";
import { UserInfoState, UserProfile, fetchUserInfo, fetchUserInfoFailed, fetchUserInfoSuccess } from "store/slice/userInfo.slice";

const profileService = new ProfileService();

function* fetchingUser() {
    try {
        // const oldState: UserInfoState = yield select(state => state.userInfo);
        // if (oldState?.userProfile?.id) {
        //     yield put(fetchUserInfoSuccess(undefined));
        //     return;
        // }

        let organizationId = Helpers.getItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, "") as string;

        const resultProfile: IUserInfo = yield call(profileService.getUserInfo);

        const currentServiceOrganizations = [...resultProfile?.currentServiceOrganizations || []].filter(el => (el.tenantCode === Constants.TENANT_CODE)) || [];

        let organizationType = OrganizationType.Normal;

        if (currentServiceOrganizations.length > 0) {
            const itemFirst = currentServiceOrganizations?.[0];
            const index = currentServiceOrganizations?.findIndex((el) => el.id === organizationId);
            if (index === -1 && organizationId !== "0") {
                organizationId = itemFirst?.id;
                organizationType = itemFirst?.type;
            }
        } else {
            organizationId = "0";
        }

        const userAuthorization: IUserAuthorization = yield call(profileService.getUserAuthorization, (organizationId === "0") ? undefined : organizationId);

        let itemOrganizationUserProfile: IOrganizationUserProfile = [...resultProfile.organizationUserProfiles || []]?.find((item) => (
            item.organizationId === organizationId
        ));

        const itemUserAuthorizationResponse = [...userAuthorization?.userAuthorizationResponse || []].reduce((prev, curr) => {
            return (prev.roleLevel < curr.roleLevel) ? prev : curr;
        });

        let currency: string = undefined;
        let groupDefaultByUser: IGroupUser;

        const listOrganization: ICodename[] = currentServiceOrganizations?.map((item) => {
            if (item.id === organizationId) {
                currency = item.currency;
                organizationType = item.type;
            };

            return {
                code: item.id,
                name: Helpers.renderValueByLanguage(item.name?.value) || "",
                detail: {
                    gene: item.gene,
                    organizationCode: item.organizationCode,
                },
            };
        });

        if (Helpers.isNullOrEmpty(currency)) {
            currency = resultProfile?.defaultCurrency || Constants.CURRENCY_DEFAULT;
        }

        let listGroup: ICodename[] = [...(resultProfile.groupUsers || [])]?.map((item) => {
            if (item.default === GroupUserDefault.Default
                && item.organizationId === organizationId) {
                groupDefaultByUser = item;
            }

            return {
                code: item.groupId,
                name: Helpers.renderValueByLanguage(item.groupName?.value) || "",
                detail: {
                    gene: item.groupGene,
                    group: item.organizationId,
                },
            } as ICodename;
        });

        const userProfile: UserProfile = {
            id: resultProfile?.userProfile?.id || itemOrganizationUserProfile?.id || "",
            organizationId: (organizationId === "0") ? "" : organizationId,
            groupId: groupDefaultByUser?.groupId || "",
            organizationType: organizationType,

            roleCode: itemUserAuthorizationResponse?.roleCode || "",
            roleName: itemUserAuthorizationResponse?.roleName || "",
            roleType: itemUserAuthorizationResponse?.roleType ?? RoleType.Default,
            roleLevel: itemUserAuthorizationResponse?.roleLevel ?? RoleLevel.Default,

            userName: resultProfile?.userProfile?.userName || "",
            avatarId: resultProfile?.userProfile?.avatarId || "",
            avatarUrl: resultProfile?.userProfile?.avatarUrl || "",
            identityId: resultProfile?.userProfile?.identityId || "",
            email: resultProfile?.userProfile?.email || itemOrganizationUserProfile?.email || "",
            gender: resultProfile?.userProfile?.gender || itemOrganizationUserProfile?.gender || 0,
            fullName: resultProfile?.userProfile?.fullName || itemOrganizationUserProfile?.firstName || "",
            birthDate: resultProfile?.userProfile?.birthDate || itemOrganizationUserProfile?.dateOfBirth || "",
            phoneNumber: resultProfile?.userProfile?.phoneNumber || itemOrganizationUserProfile?.phoneNumber || "",

            status: resultProfile?.userProfile?.status || itemOrganizationUserProfile?.activeStatus || 0,

            currency: currency,
        };

        const resourceMenu = [...userAuthorization?.menus || []];
        const menuDetails = [...userAuthorization?.menuDetails || []];

        const resourceCodes = [...userAuthorization?.roleResourcePermissions || []]?.map((item) => ({ resourceURI: item.resourceURI, permission: item.permission }));


        const result: UserInfoState = {
            userProfile,

            listGroup,
            listOrganization,
            currentOrganization: organizationId || "",

            menuDetails,
            resourceMenu,
            resourceCodes,
        };

        Helpers.setItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, organizationId || "");

        yield put(fetchUserInfoSuccess(result));
    } catch (error) {
        yield put(fetchUserInfoFailed());
    }
}

export default function* userInfoSaga() {
    yield takeLatest(fetchUserInfo().type, fetchingUser);
}
