import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { User, UserManager as OidcUserManager } from "oidc-client-ts";
import React, { createContext, useContext, useEffect, useState } from "react";
import { browserName, browserVersion, deviceType, osVersion, osName } from "react-device-detect";

import Helpers from "commons/helpers";
import Constants from "./../constants";
import Strings from "constants/strings";
import Screens from "constants/screens";
import SystemSettingService from "services/sale/systemsetting.service";
import AdministrativeDivisionService from "services/common/administrativeDivision.service";
import { RootState } from "store";
import { UserManager } from "commons/userManager";
import { SystemSettingCode } from "constants/enum";
import { ICodename, ISessionUser } from "commons/interfaces";
import { resetAuth, setAuthInfo } from "store/slice/auth.slice";
import { fetchUserInfo, resetUserInfo } from "store/slice/userInfo.slice";
import { setItemSettingValue } from "store/slice/settingCode.slice";

interface AuthContextType {
    user?: ISessionUser | null;
    loading: boolean;
    signinRedirect: () => Promise<void>;
    signoutRedirect: () => Promise<void>;
    onSwitchAccount: () => Promise<void>;
}

const administrativeDivisionService = new AdministrativeDivisionService();
const systemSettingService = new SystemSettingService();

const AuthContext = createContext<AuthContextType>(null!);
const useAuth = () => useContext(AuthContext);

function AuthProvider({ children }: { children: React.ReactNode }) {
    const currentUser = useSelector((state: RootState) => state.auth.authInfo);
    const [loading, setLoading] = useState(currentUser === undefined);
    const [userManager] = useState<OidcUserManager>(() => UserManager());
    const dispatch = useDispatch();

    useEffect(() => {
        const handleSignin = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const hashParams = new URLSearchParams(window.location.hash.replace("#", "?"));
            if (
                searchParams.get("code") ||
                searchParams.get("id_token") ||
                searchParams.get("session_state") ||
                hashParams.get("code") ||
                hashParams.get("id_token") ||
                hashParams.get("session_state")
            ) {
                const user = (await userManager.signinCallback()) || null;
                dispatch(setAuthInfo({ ...user }));
                axios.defaults.headers["Authorization"] = "Bearer " + user?.access_token;
                Helpers.setItemInLocalStorage(Constants.StorageKeys.ACCESS_TOKEN, user?.access_token);

                if (!Helpers.isNullOrEmpty(user)) {
                    dispatch(fetchUserInfo());
                }
            } else if (loading) {
                try {
                    await userManager.signinSilent();
                } catch (error) {
                    dispatch(setAuthInfo(null));
                    if (window.location.pathname === Screens.LOGIN_REDIRECT) {
                        signinRedirect();
                    }
                }
            }
        };
        handleSignin();

        __EventEmitter.addListener(Constants.EventName.TOKEN_EXPIRED, handleTokenExpired);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.TOKEN_EXPIRED, handleTokenExpired);
        };
    }, []);

    useEffect(() => {
        if (currentUser !== undefined) {
            setLoading(false);

            if (currentUser) {
                handleLoadActiveUser();
            }
        }
    }, [currentUser]);

    useEffect(() => {
        if (!userManager) {
            return;
        }

        const handleUserLoaded = async (newUser: User) => {
            if (newUser) {
                dispatch(setAuthInfo({ ...newUser }));

                axios.defaults.headers.common["Authorization"] = "Bearer " + newUser?.access_token;

                dispatch(fetchUserInfo());

                const dataProvinces: ICodename[] = [];
                const result = await administrativeDivisionService.getCities();
                (Object.values(result) || []).forEach((item: any) => {
                    dataProvinces.push({ group: item.type, code: item.id, name: item.name });
                });
                Helpers.setItemInLocalStorage(Constants.StorageKeys.PROVINCE_LIST, dataProvinces);
                Helpers.setItemInLocalStorage(Constants.StorageKeys.ACCESS_TOKEN, newUser?.access_token);

                const resultAttributeSetting = await systemSettingService.getBySettingCode(SystemSettingCode.AttributeCommon);

                if (!Helpers.isNullOrEmpty(resultAttributeSetting.settingValue)) {
                    const settingValue = JSON.parse(resultAttributeSetting.settingValue);
                    let dataCode: any[] = [];
                    Object.keys(settingValue).forEach((item: string, index: number) => {
                        dataCode.push({
                            name: item,
                            code: `${Object.values(settingValue)[index]}`,
                        });
                    });
                    dispatch(setItemSettingValue({ key: Constants.SETTING_CODE_SALE_ATTRIBUTE, value: dataCode }));
                }
            } else {
                dispatch(setAuthInfo(null));
            }
        };

        userManager.events.addUserLoaded(handleUserLoaded);
        userManager.events.addSilentRenewError(handleTokenExpired);

        return () => {
            userManager.events.removeUserLoaded(handleUserLoaded);
            userManager.events.removeSilentRenewError(handleTokenExpired);
        };
    }, [userManager]);

    const handleTokenExpired = () => {
        Helpers.showAlert(Strings.Message.TOKEN_EXPIRED, "error", async () => {
            await signoutRedirect();
        });
    };

    const handleLoadActiveUser = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const activeUser = searchParams.get("activeUser");
        if (activeUser && activeUser !== currentUser.profile?.sub) {
            signoutLocal();
            await signinRedirect({ active_user: activeUser });
        }
    };

    const signinRedirect = async (params?: { [key: string]: string | number | boolean }) => {
        try {
            const deviceId = await Helpers.getDeviceId();
            const deviceInfo = JSON.stringify({
                browserName,
                browserVersion,
                deviceType,
                osVersion,
                osName,
            });

            let extraQueryParams: { [key: string]: string | number | boolean } = {
                deviceId,
                deviceInfo,
            };

            if (!Helpers.isNullOrEmpty(extraQueryParams)) {
                extraQueryParams = {
                    ...extraQueryParams,
                    ...params,
                };
            }

            await userManager.signinRedirect({ extraQueryParams });
        } catch (error) {
            dispatch(setAuthInfo(null));
        }
    };

    const signoutRedirect = async () => {
        const idTokenHint = currentUser?.id_token;
        dispatch(resetAuth());
        dispatch(resetUserInfo());
        sessionStorage.clear();
        localStorage.clear();
        const origin = window.location.origin;
        await userManager.signoutRedirect({
            id_token_hint: idTokenHint,
            post_logout_redirect_uri: origin + Constants.IdentityPath.POST_LOGOUT_REDIRECT_URL,
        });
    };

    const onSwitchAccount = async () => {
        signoutLocal();
        await signinRedirect({ switchUser: 1 });
    };

    const signoutLocal = () => {
        dispatch(resetUserInfo());
        sessionStorage.clear();
        localStorage.clear();
    };

    const value = {
        user: currentUser,
        loading,
        signinRedirect,
        signoutRedirect,
        onSwitchAccount,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthProvider, useAuth };
