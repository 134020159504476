import Helpers from "commons/helpers";
import Constants from "../../constants";
import IdentityService from "./identity.service";

class ProfileService extends IdentityService {
    public getUserInfo = async () => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.PROFILE.USER_INFO}`,
        });
        return result.data.result;
    };

    public getUserOrganization = async () => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.PROFILE.USER_ORGANIZATION}`,
        });
        return result.data.result;
    };

    public getUserAuthorization = async (organizationId?: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.PROFILE.USER_AUTHORIZATION,
            query: Helpers.isNullOrEmpty(organizationId) ? undefined : { organizationId: organizationId },
        });
        return result.data.result;
    };

    public changePassword = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.PROFILE.CHANGE_PASSWORD}`,
            data,
        });
        return result.data.result;
    };

    public changePhoneNumber = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.PROFILE.CHANGE_PHONE_NUMBER}`,
            data,
        });
        return result.data.result;
    };

    public getPhoneNumberOTP = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.PROFILE.GET_CHANGE_PHONE_NUMBER_OTP}`,
            data,
        });
        return result.data.result;
    };

    public getChangeEmailOTP = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.PROFILE.GET_CHANGE_EMAIL_OTP,
            data,
        });
        return result.data.result;
    };

    public changeEmail = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.PROFILE.CHANGE_EMAIL,
            data,
        });
        return result.data.result;
    };

    public updateProfile = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.PROFILE.UPDATE_PROFILE}`,
            data,
        });
        return result.data.result;
    };

    public updateAvatar = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.PROFILE.UPDATE_AVATAR}`,
            data,
        });
        return result.data.result;
    };

    public getUserSummary = async () => {
        const result = await this.api.get({
            path: Constants.ApiPath.PROFILE.USER_SUMMARY,
        });
        return result.data;
    };

    public updateUserProfile = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.PROFILE.UPDATE_USER_PROFILE,
            data,
        });
        return result.data.result;
    };
}

export default ProfileService;
