import { useEffect } from "react";
import { Card } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "../../constants";
import Strings from "constants/strings";
import useAttribute from "./container/useAttribute";
import BasicInfoAttribute from "./container/basicInfoAttribute";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { Mode, PermissionType } from "constants/enum";
import { Box } from "@maysoft/common-component-react";

const AttributeEditScreen = () => {
    const [searchParams] = useSearchParams();

    const resourceCodes = useSelector((state: RootState) => state.userInfoSlice?.resourceCodes);

    const acCreate = Helpers.checkResourceModule({
        data: resourceCodes,
        permission: PermissionType.Create,
        code: Constants.ResourceCode.ATTRIBUTE,
    });

    const acUpdate = Helpers.checkResourceModule({
        data: resourceCodes,
        permission: PermissionType.Update,
        code: Constants.ResourceCode.ATTRIBUTE,
    });

    const idDetail: string = searchParams.get("id");

    const {
        model,
        languages,
        isOnChaged,
        dataValueType,
        isAdminService,
        organizationList,
        dataDetailAttribute,

        setLanguages,
        onChangeDataDetail,

        onStart,
        handleGoBack,
        onCreateOrUpdate,
        onChangeValueType,
        handleOnchangeValueItem,
        handleAddNewItemAttributeValue,
        handleDeleteItemAttributeValue,
    } = useAttribute({
        idDetail: idDetail,
        onCallbackData(data) {
            handleGoBack();
        },
    });

    useEffect(() => {
        onStart();
    }, [idDetail]);

    return (
        <DashboardLayout
            isPermission
            title={model.title}
            route={model.route}
            nameActionPress={model.mode === Mode.View ? Strings.Common.EDIT : Strings.Common.SAVE}
            onBackPress={() => {
                if (isOnChaged) {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                } else {
                    handleGoBack();
                }
            }}
            onActionPress={acCreate || acUpdate ? onCreateOrUpdate : undefined}
        >
            <Card>
                <Box p={2}>
                    <BasicInfoAttribute
                        isModal={false}
                        mode={model.mode}
                        languages={languages}
                        dataValueType={dataValueType}
                        isAdminService={isAdminService}
                        organizationList={organizationList}
                        dataDetailAttribute={dataDetailAttribute}
                        onChangeDataDetail={onChangeDataDetail}
                        onChangeValueType={onChangeValueType}
                        handleOnchangeValueItem={handleOnchangeValueItem}
                        setLanguages={setLanguages}
                        handleAddNewItemAttributeValue={handleAddNewItemAttributeValue}
                        handleDeleteItemAttributeValue={handleDeleteItemAttributeValue}
                    />
                </Box>
            </Card>
        </DashboardLayout>
    );
};

export default AttributeEditScreen;
