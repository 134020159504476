import AddIcon from "@mui/icons-material/Add";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "../../constants";
import OrderService from "services/sale/order.service";
import OrderStatusCell from "components/CustomCell/orderStatus";

import { RootState } from "store";
import { IsTrue } from "constants/enum";
import { DashboardLayout } from "layout";
import { ICodename } from "commons/interfaces";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from "store/slice/titleRoute.slice";
import { Box, Button, DataTable, FormField, Autocomplete } from "@maysoft/common-component-react";

interface RequestData {
    organizationId: string;
    buyer: string;
    seller: string;
    orderStatus: string;
    searchText: string;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    paymentStatus?: number;
}

interface IModel {
    tableData: {
        columns: any[];
        rows: any[];
    };

    orderStatusList?: ICodename[];
    sellerList: ICodename[];
    buyerList: ICodename[];

    requestData: RequestData;
    requestDataTemp: RequestData;
}

const OrderScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    // const userService = new UserService();
    const orderService = new OrderService();
    // const partnerService = new PartnerService();

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const userProfile = useSelector((state: RootState) => state.userInfoSlice?.userProfile);
    const organizationId = userProfile?.organizationId;
    const groupId = userProfile?.groupId;

    const [model, setModel] = useState<IModel>({
        tableData: {
            columns: [
                {
                    Header: Strings.ORDER.CODE,
                    accessor: "code",
                    width: "80px",
                    Cell: (row: any) => {
                        const id = row?.row.original?.id;
                        const organizationId = row?.row?.original?.organizationId;
                        return (
                            <Link className="hyperlink" to={Screens.ORDER_DETAIL + `?id=${id}`} state={{ id: id, organizationId: organizationId }}>
                                {row.value}
                            </Link>
                        );
                    },
                },
                { Header: Strings.ORDER.PURCHASE_TIME, accessor: "purchaseTime", width: "150px" },
                // { Header: Strings.ORDER.BRAND, accessor: "brand", width: "200px" },
                { Header: Strings.ORDER.CUSTOMER, accessor: "customer", width: "150px" },
                // { Header: Strings.ORDER.SELLER, accessor: "seller", width: "150px" },
                { Header: Strings.ORDER.TOTAL_VALUE, accessor: "totalValue", width: "150px" },
                {
                    Header: Strings.ORDER.PAYMENT_STATUS,
                    accessor: "status",
                    width: "150px",
                    Cell: ({ value }: any) => <OrderStatusCell status={value} />,
                },
            ],
            rows: [],
        },
        sellerList: [],
        buyerList: [],

        requestData: {
            groupId: groupId,
            organizationId: organizationId,
            buyer: "",
            seller: "",
            orderStatus: "",
            searchText: "",
            pageNumber: 1,
            pageSize: Constants.ROW_PER_PAGE_10,
            totalCount: 0,
            isBookOrder: IsTrue.False,
        },

        requestDataTemp: {
            groupId: groupId,
            organizationId: organizationId,
            buyer: "",
            seller: "",
            orderStatus: "",
            searchText: "",
            pageNumber: 1,
            pageSize: Constants.ROW_PER_PAGE_10,
            totalCount: 0,
            isBookOrder: IsTrue.False,
        },
    } as IModel);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(organizationId)) {
            const itemPathName = listPathName.find((el) => el.pathName === Screens.ORDER_LIST);
            getData({
                totalCount: itemPathName?.totalCount || 0,
                buyer: searchParams.get("buyer"),
                seller: searchParams.get("seller"),
                searchText: searchParams.get("searchText"),
                orderStatus: searchParams.get("orderStatus"),
                organizationId: searchParams.get("organizationId"),
                pageNumber: Number(searchParams.get("pageNumber") || 1),
                pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE_10),
                paymentStatus: Helpers.isNullOrEmpty(searchParams.get("paymentStatus")) ? undefined : Number(searchParams.get("paymentStatus")),
            });
        }
    }, [organizationId]);

    const getData = async (data?: RequestData) => {
        try {
            dispatch(showLoading(true));
            // const buyerList = await getBuyerList();
            // const sellerList = await getSellerList();
            let requestData: RequestData = {
                ...model.requestData,
                ...data,
                pageNumber: Helpers.getPageNumber(data?.pageNumber, data?.pageSize, data?.totalCount),
            };

            const { orderList, totalCount } = await getOrderList(requestData);
            requestData.totalCount = Number(totalCount || 0);
            let newModel = {
                ...model,
                // buyerList,
                // sellerList,
                tableData: {
                    ...model.tableData,
                    rows: orderList,
                },
                requestData,
            };
            setModel(newModel);
            dispatch(showLoading(false));
        } catch (error: any) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    // const getBuyerList = async () => {
    //     let buyerList: ICodename[] = [];
    //     let data = {
    //         searchText: "",
    //         pageNumber: 1,
    //         pageSize: unlimit,
    //         customerType: "",
    //         loyaltiClass: "",
    //         status: "",
    //         organizationId: organizationId,
    //         isCustomerOrSupplier: IsCustomerOrSupplier.Customer,
    //     };
    //     const result = await partnerService.getPaged(Helpers.handleFormatParams(data));
    //     result.items?.forEach((e: any) =>
    //         buyerList.push({
    //             code: e.id,
    //             name: e.partnerName,
    //         })
    //     );
    //     return buyerList;
    // };

    // const getSellerList = async () => {
    //     let sellerList: ICodename[] = [];
    //     let data = {
    //         searchText: "",
    //         pageNumber: 1,
    //         pageSize: unlimit,
    //         listStatus: Status.Active,
    //         organizationId: organizationId,
    //     };
    //     const result = await userService.getPaged(Helpers.handleFormatParams(data));
    //     result.items?.forEach((e: any) =>
    //         sellerList.push({
    //             code: e.id,
    //             name: e.organizationUserProfile?.firstName,
    //         })
    //     );
    //     return sellerList;
    // };

    const getOrderList = async (data: any) => {
        let orderList: any[] = [];
        let requestData: any = {
            organizationId: data?.organizationId || undefined,
            searchText: data?.searchText || undefined,
            buyer: data?.buyer || undefined,
            seller: data?.seller || undefined,
            paymentStatus: data?.paymentStatus || undefined,
            pageNumber: data?.pageNumber || 1,
            pageSize: data?.pageSize || Constants.ROW_PER_PAGE_10,
        };

        const result = await orderService.getPaged(Helpers.handleFormatParams(requestData));
        result.items?.forEach((e: any) => {
            orderList.push({
                id: e.id,
                code: e.orderCode,
                purchaseTime: Helpers.formatDate(Number(e.orderDate) * 1000, "DD/MM/YYYY HH:mm"),
                // brand: e.organizationBranch?.name?.value?.vi,
                customer: e.buyerName || Strings.ORDER.GUEST,
                seller: e.sellerName,
                totalValue: Helpers.formatCurrency(e.amount),
                status: e.orderStatus,
                return: !Helpers.isNullOrEmpty(e.returnOrderCode),
            });
        });
        let query: string = `?pageNumber=${data?.pageNumber || 1}&pageSize=${data.pageSize || Constants.ROW_PER_PAGE_10}`;
        if (!Helpers.isNullOrEmpty(data?.seller)) {
            query += `&seller=${data?.seller}`;
        }
        if (!Helpers.isNullOrEmpty(data?.buyer)) {
            query += `&buyer=${data?.buyer}`;
        }
        if (!Helpers.isNullOrEmpty(data?.searchText)) {
            query += `&searchText=${data?.searchText}`;
        }
        if (!Helpers.isNullOrEmpty(data?.orderStatus)) {
            query += `&orderStatus=${data?.orderStatus}`;
        }

        navigate(Screens.ORDER_LIST + query, { state: { ...data, totalCount: result.totalCount } });
        dispatch(setListPathName({ pathname: Screens.ORDER_LIST, query, totalCount: result.totalCount }));

        return {
            orderList: orderList,
            totalCount: result.totalCount,
        };
    };

    const onReset = async () => {
        try {
            dispatch(showLoading(true));
            let requestDataTemp = { ...model.requestDataTemp };
            const { orderList, totalCount } = await getOrderList(requestDataTemp);
            requestDataTemp.totalCount = Number(totalCount || 0);
            let newModel = {
                ...model,
                tableData: {
                    ...model.tableData,
                    rows: orderList,
                },
                requestData: requestDataTemp,
            };
            setModel(newModel);
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleConfirmFilter = async (searchText?: string) => {
        try {
            if (Helpers.isNullOrEmpty(model.requestData?.organizationId)) {
                dispatch(setDataAlert({ message: Strings.Validation.REQUIRED_ORGANIZATION, type: "warning" }));
                return false;
            }
            dispatch(showLoading(true));
            let requestData = {
                ...model.requestData,
                organizationId: model.requestData?.organizationId,
                searchText: searchText || undefined,
                pageNumber: 1,
                pageSize: model.requestData?.pageSize || Constants.ROW_PER_PAGE_10,
                seller: model.requestData?.seller,
                buyer: model.requestData?.buyer,
                paymentStatus: model.requestData?.paymentStatus,
            };
            const { orderList, totalCount } = await getOrderList(requestData);
            requestData.totalCount = Number(totalCount || 0);
            let newModel = {
                ...model,
                tableData: {
                    ...model.tableData,
                    rows: orderList,
                },
                requestData,
            };
            setModel(newModel);
            dispatch(showLoading(false));
        } catch (error: any) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onValueChange = async (newValue: any, key: string) => {
        let requestDataTemp: any = { ...model.requestData };
        requestDataTemp[key] = newValue;
        setModel({ ...model, requestData: requestDataTemp });
    };

    const filterForm = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            defaultValue={model.requestData?.orderStatus}
                            key={(model.requestData?.orderStatus as any) || model.orderStatusList}
                            data={model.orderStatusList || []}
                            label={Strings.ORDER.STATUS}
                            placeholder={Strings.ORDER.SELECT_STATUS}
                            onChange={(value) => onValueChange(value, "orderStatus")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            defaultValue={model.requestData?.seller}
                            key={(model.requestData?.seller as any) || model.sellerList}
                            data={model.sellerList || []}
                            label={Strings.ORDER.SELLER}
                            placeholder={Strings.ORDER.SELECT_SELLER}
                            onChange={(value) => onValueChange(value, "seller")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            defaultValue={model.requestData?.buyer}
                            key={(model.requestData?.buyer as any) || model.buyerList}
                            data={model.buyerList || []}
                            label={Strings.ORDER.CUSTOMER}
                            placeholder={Strings.ORDER.SELECT_CUSTOMER}
                            onChange={(value) => onValueChange(value, "buyer")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            required
                            value={model.requestData?.searchText}
                            label={Strings.ORDER.SEARCH_TEXT}
                            placeholder={Strings.ORDER.ENTER_SEARCH_TEXT}
                            onChangeValue={(value: any) => onValueChange(value, "searchText")}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <DashboardLayout isPermission title={Strings.ORDER.TITLE_LIST_VIEW} route={[{ title: Strings.ORDER.TITLE_MENU, route: "" }]}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent="flex-end" display="flex" my={2}>
                        <Button
                            onClick={() => {
                                window.open(`${Screens.ORDER_EDIT}`, "_blank");
                            }}
                        >
                            <AddIcon />
                            &nbsp;
                            {Strings.Common.ADD_NEW}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                isSorted
                                // isSelectedBox
                                table={model.tableData}
                                totalCount={model.requestData?.totalCount}
                                pageNumber={model.requestData?.pageNumber}
                                rowPerPage={model.requestData?.pageSize || Constants.ROW_PER_PAGE_10}
                                rowPerPageOptions={[10, 20, 50, 100]}
                                onChangePageSize={(pageSize) => getData({ ...model.requestData, pageSize })}
                                onChangePageNumber={(pageNumber) => getData({ ...model.requestData, pageNumber })}
                                searchText={model.requestData?.searchText}
                                placeholderSearch={Strings.ORDER.SEARCH_TEXT}
                                filterForm={filterForm()}
                                onFilter={() => handleConfirmFilter(model.requestData?.searchText)}
                                onSearchChange={(val) => onValueChange(val, "searchText")}
                                onSearch={(val) => handleConfirmFilter(val)}
                                onReset={onReset}
                                onCloseFilter={() => {}}
                                actionList={(row: any) => {
                                    return [
                                        {
                                            key: "detail",
                                            iconName: "view",
                                            title: Strings.Common.VIEW,
                                            callback: (row: any) =>
                                                navigate(Screens.ORDER_DETAIL + `?id=${row.id}`, {
                                                    state: { id: row.id, organizationId: row.organizationId },
                                                }),
                                        },
                                    ];
                                }}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default OrderScreen;
