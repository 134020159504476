import { UserManager as OidcUserManager, WebStorageStateStore } from "oidc-client-ts";
import Constants, { IDENTITY_URL } from "../constants";

export const UserManager = (): OidcUserManager => {
    const origin = window.location.origin;
    return new OidcUserManager({
        authority: IDENTITY_URL || "",
        client_id: Constants.CLIENT_ID || "",
        client_secret: "secret",
        redirect_uri: origin + Constants.SaleAdminPath.REDIRECT_URI,
        post_logout_redirect_uri: origin + Constants.SaleAdminPath.POST_LOGOUT_REDIRECT_URL,
        response_type: "code",
        scope: "openid offline_access profile IdentityServerApi",
        loadUserInfo: true,
        includeIdTokenInSilentRenew: true,
        accessTokenExpiringNotificationTimeInSeconds: 180,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        revokeTokensOnSignout: true
    });
}