import authReducer from "./slice/auth.slice";
import { configureStore } from "@reduxjs/toolkit";

import loadingAPIReducer from "./slice/loadingAPI.slice";
import titleRouteReducer from "./slice/titleRoute.slice";
import userInfoReducer from "./slice/userInfo.slice";
import tokenInfoReducer from "./slice/tokenInfo.slice";
import SettingCodeReducer from "./slice/settingCode.slice";
import messageReducer from "./slice/message.slice";

import rootSaga from "./saga/root.saga";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        auth: authReducer,
        messageSlice: messageReducer,
        loadingAPI: loadingAPIReducer,
        titleRoute: titleRouteReducer,
        userInfo: userInfoReducer,
        userInfoSlice: userInfoReducer,
        tokenInfoSlice: tokenInfoReducer,
        settingCodeSlice: SettingCodeReducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
