import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITitleRoute } from "../../commons/interfaces";

export interface TitleRouteState {
    titleScreen: string;
    route: ITitleRoute[];
    targerScreen: string;
    listPathName?: { pathName: string, query: string, totalCount: number }[];
}

const initialState: TitleRouteState = {
    targerScreen: "",
    titleScreen: "",
    route: [],
    listPathName: [],
}

export const titleRouteSlice = createSlice({
    name: "titleRoute",
    initialState,
    reducers: {
        setTargerScreen: (state, action: PayloadAction<string>) => {
            state.targerScreen = action.payload;
        },
        updateTitleRoute: (state, action: PayloadAction<TitleRouteState>) => {
            state.route = action.payload.route;
            state.titleScreen = action.payload.titleScreen;
            state.targerScreen = action.payload.targerScreen;
        },
        setListPathName: (state, action: PayloadAction<{ pathname: string, query: string, totalCount?: number }>) => {
            let listTemp = [...state.listPathName || []];
            const index = listTemp.findIndex(el => el.pathName === action.payload.pathname);
            if (index === -1) {
                listTemp.push({
                    pathName: action.payload.pathname,
                    query: action.payload.query,
                    totalCount: action.payload.totalCount || 0,
                });
            } else {
                listTemp[index].query = action.payload.query;
                listTemp[index].totalCount = action.payload.totalCount || 0;
            }

            state.listPathName = listTemp;
        },
        resetTitleRoute: (state) => {
            state.route = [];
            state.titleScreen = "";
            state.targerScreen = "";
            state.listPathName = [];
        },
    }
});

// Action creators are generated for each case reducer function
export const {
    setTargerScreen,
    updateTitleRoute,
    resetTitleRoute,
    setListPathName,
} = titleRouteSlice.actions

export default titleRouteSlice.reducer;