import DashboardScreen from "screens/dashboard";
import ProfileScreen from "screens/profile";

import AffiliateProgramScreen from "screens/affiliate";
import AffiliaterScreen from "screens/affiliater";
import AttributeScreen from "screens/attribute";
import AttributeEditScreen from "screens/attribute/editAttributeScreen";
import BundleProductScreen from "screens/bundleProduct";
import CashBookScreen from "screens/cashBook";
import PaymentTicketScreen from "screens/cashBook/paymentTicketScreen";
import ReceiveVoucherEditScreen from "screens/cashBook/receiveVoucherEditScreen";
import CodenameScreen from "screens/codename";
import CustomerScreen from "screens/customer";
import MemberShipScreen from "screens/membership";
import OrderNewVersionScreen from "screens/orderNewVersion";
import ProductScreen from "screens/product";
import ProductTemplateScreen from "screens/productTemplate";
import PromotionScreen from "screens/promotion";
import SponsorProgramScreen from "screens/sponsorProgram";
import SupplierScreen from "screens/supplier";
import SettingLoyalCustomerScreen from "screens/systemSettingCommon/loyaltyCustomer";
import SettingPaymentGatewayScreen from "screens/systemSettingCommon/settingPaymentGateway";
import SystemSettingScreen from "screens/systemSettingCommon/systemSetting";
import PartnerCommissionScreen from "screens/partnerCommission";
import ServiceFeeSettingScreen from "screens/systemSettingCommon/serviceFeeSetting";

export const useMapScreenName = () => {
    let data: { [key: string]: JSX.Element } = {
        ProfileScreen: <ProfileScreen />, // done
        DashboardScreen: <DashboardScreen />, // done

        // /product-module/
        ProductScreen: <ProductScreen />, // done
        MembershipScreen: <MemberShipScreen />, // done
        BundleProductScreen: <BundleProductScreen />, // done

        // /transaction-module/
        OrderScreen: <OrderNewVersionScreen />, // done
        PromotionScreen: <PromotionScreen />, // done

        CashBookScreen: <CashBookScreen />,
        PaymentTicketScreen: <PaymentTicketScreen />,
        ReceiveVoucherEditScreen: <ReceiveVoucherEditScreen />,

        // /product-template/
        ProductTemplateScreen: <ProductTemplateScreen />, // done

        // /customer-module/
        CustomerScreen: <CustomerScreen />, // done

        SponsorProgramScreen: <SponsorProgramScreen />, // done

        AffiliateProgramScreen: <AffiliateProgramScreen />, // done
        AffiliaterScreen: <AffiliaterScreen />, // done

        // /system-module
        SystemSettingScreen: <SystemSettingScreen />, //done
        SettingLoyalCustomerScreen: <SettingLoyalCustomerScreen />, // done
        SettingPaymentGatewayScreen: <SettingPaymentGatewayScreen />, // done
        CodenameScreen: <CodenameScreen />, // done
        AttributeScreen: <AttributeScreen />, // done
        AttributeEditScreen: <AttributeEditScreen />, // done
        ServiceFeeSettingScreen: <ServiceFeeSettingScreen />,

        // /partner/
        SupplierScreen: <SupplierScreen />, // done
        AgentScreen: <SupplierScreen />, // done
        ManufacturerScreen: <SupplierScreen />, // done

        // /partner-commission/
        PartnerCommissionScreen: <PartnerCommissionScreen />, // done
    };

    return data;
};
