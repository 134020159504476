import APIAccessor from "../apiProcessor";
import Constants, { ASSET_API_URL, COMMON_API_URL } from "../../constants";

class BaseService {
    protected api: APIAccessor;

    constructor() {
        this.api = new APIAccessor(COMMON_API_URL);
    }

    uploadFile = async (data: any) => {
        const result = await this.api.postFormData({
            baseUrl: ASSET_API_URL,
            path: Constants.ApiPath.UPLOAD_FILE,
            data,
        });
        return result.data.result;
    };
}

export default BaseService;
