import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Constants from "constants/index";

import { RootState } from "store";
import { LoadingLayout } from "layout";
import { useAuth } from "providers/authProvider";

interface IProps {
    children: React.ReactElement;
}

const ProtectedRoute: React.FC<IProps> = (props: IProps) => {
    const auth = useAuth();
    const userInfo = useSelector((state: RootState) => state.userInfoSlice);

    if (auth?.loading) {
        return <LoadingLayout />;
    }

    if (auth.user === null) {
        const pathNameWithSearch = window.location.pathname + window.location.search;

        sessionStorage.setItem(Constants.StorageKeys.FROM, pathNameWithSearch);

        return <Navigate to={Screens.LOGIN_REDIRECT} replace />
    }

    return !Helpers.isNullOrEmpty(userInfo?.userProfile?.id) ? props.children : <LoadingLayout />;
};

export default ProtectedRoute;
