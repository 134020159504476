import { AvailableLanguage } from "../constants/enum";

interface RecursiveRecord<A> {
    [key: string]: RecursiveRecord<A> | A;
}

const StringLocalization = <T extends RecursiveRecord<string>,>(obj: { [key in AvailableLanguage]?: T }) => {
    const _props = obj;
    let _language: AvailableLanguage = Object.keys(_props)[0] as AvailableLanguage;

    const getLanguage = () => _language;

    function get(key: string, language?: AvailableLanguage): string {
        try {
            const paths = key.split(".");
            let out: any = _props[language || _language];
            for (let i = 0; i < paths.length; i += 1) {
                if (out[paths[i]] === undefined) {
                    throw Error(paths[i]);
                }
                out = out[paths[i]];
            }

            if (typeof out === "string") {
                return out;
            }
            return "-";
        } catch (ex: any) {
            // console.log(
            //     `No localization found for key '${key}' and language '${language}', failed on ${ex.message}`
            // );
            return key;
        }
    }

    function format(str: string, ...values: (string | number)[]) {
        for (let i = 0; i < values.length; i++) {
            const searchValue = "{" + i + "}";
            const replaceValue = values[i].toString();
            str = str.replace(searchValue, replaceValue);
        }
        return str;
    }

    function shallowClone() {
        return Object.assign({}, _strings);
    }

    function setLanguage(language?: string) {
        if (language === _language) return;
        if (!language || !Object.keys(_props).includes(language)) {
            throw Error("Invalid language");
        }

        _language = language as AvailableLanguage;

        let localizedStrings: T = Object.assign({}, _props[_language]);
        Object.keys(localizedStrings).forEach((key: keyof T) => {
            // @ts-ignore
            _strings[key] = localizedStrings[key];
        });
    }

    function getInstance() {
        return _strings;
    }
    function setInstance(strings: any) {
        _strings = strings;
    }

    let _strings = {
        setInstance,
        getInstance,

        setLanguage,
        getLanguage,

        get,
        format,
        shallowClone,
        ...obj[_language] as T
    }

    return _strings;
}

export default StringLocalization;