import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { ModuleSponsorProgram } from "@maysoft/sale-common-react";
import { Mode } from "constants/enum";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";
import { DashboardLayout } from "layout";
import { useDispatch } from "react-redux";
import { setListPathName } from "store/slice/titleRoute.slice";
import Helpers from "commons/helpers";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface RequestData {
    searchText?: string;
    pageNumber?: number;
    pageSize?: number;
    totalCount?: number;
}

const SponsorProgramScreen = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();


    // list
    const [request] = useState<RequestData>({
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: 0,
    });
    const handleGetPaged = useCallback(
        ({ query }: { query: string }) => {
            dispatch(setListPathName({ query, pathname: Screens.PROMOTION_LIST, }));
            navigate(Screens.SPONSOR_PROGRAM_LIST + query, { replace: true });
        }, [navigate, dispatch]);
    const handleNavigate = (data: { mode?: number; id?: string }) => {
        const navigatePath = data.id ? Screens.SPONSOR_PROGRAM_EDIT + `?id=${data.id}` : Screens.SPONSOR_PROGRAM_EDIT;
        navigate(navigatePath, { state: { mode: data.id ? data.mode : Mode.Create } });
    };

    // id
    const location = useLocation();
    const [mode, setMode] = useState<Mode>((location?.state as any)?.mode || Mode.Create);
    const id: string = searchParams.get("id");
    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.SPONSOR_PROGRAM_LIST);
        navigate(Screens.SPONSOR_PROGRAM_LIST + (itemPathName ? itemPathName.query : ""));
    };

    const modeList = (Screens.SPONSOR_PROGRAM_LIST.includes(window.location.pathname));
    const editTitle = useMemo(() => {
        if (Helpers.isNullOrEmpty(id)) return Strings.SPONSOR_PROGRAM.TITLE_CREATE_VIEW;
        return mode === Mode.View ? Strings.SPONSOR_PROGRAM.TITLE_DETAIL_VIEW : Strings.SPONSOR_PROGRAM.TITLE_UPDATE_VIEW;
    }, [id, mode]);

    const editRoute = useMemo(() => {
        const temp = [{ title: Strings.SPONSOR_PROGRAM.TITLE_MENU, route: Screens.SPONSOR_PROGRAM_LIST }];
        if (Helpers.isNullOrEmpty(id)) {
            temp.push({ title: Strings.Common.CREATE_NEW, route: "" });
        } else {
            temp.push({
                title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [id, mode]);

    return (
        <DashboardLayout
            isPermission
            title={modeList ? Strings.SPONSOR_PROGRAM.TITLE_LIST_VIEW : editTitle}
            route={modeList ? [{ title: Strings.SPONSOR_PROGRAM.TITLE_MENU, route: "" }] : editRoute}
        >
            <ModuleSponsorProgram
                keyModuleContainer={{
                    list: { pathName: Screens.SPONSOR_PROGRAM_LIST },
                    detail: { pathName: Screens.SPONSOR_PROGRAM_EDIT },
                }}

                requestGetPaged={request}
                onGetPaged={handleGetPaged}
                onNavigate={handleNavigate}

                mode={mode}
                idDetail={id}
                onGoBack={handleGoBack}
                onChangeMode={(value) => { setMode(value); }}
            />
        </DashboardLayout>
    );
};

export default SponsorProgramScreen;
