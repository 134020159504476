import Constants from "../../constants";
import SaleService from "./sale.service";

class ItemService extends SaleService {
    public getAll = async (data: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ITEM.GET_ALL,
            query: data
        });
        return result.data.result;
    }

    public getSupportCurrency = async () => {
        const result = await this.api.get({
            path: Constants.ApiPath.EXCHANGE_RATE.GET_SUPPORT_CURRENCY
        });
        return result.data.result;
    }

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.ITEM.GET_PAGED}?${data}`
        });
        return result.data.result;
    }

    public getDetail = async (id: string ) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ITEM.DETAIL + `/${id}`,
        });
        return result.data.result;
    }

    public deleteItems = async (data: any) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.ITEM.DELETE,
            data
        });
        return result.response;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ITEM.CREATE,
            data: data,
        });
        return result.data;
    }

    public createService = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ITEM.CREATE_SERVICE,
            data: data,
        });
        return result.data;
    }

    public createMembership = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ITEM.CREATE_MEMBERSHIP,
            data: data,
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ITEM.UPDATE,
            data: data,
        });
        return result.data;
    }

    public updateService = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ITEM.UPDATE_SERVICE,
            data: data,
        });
        return result.data;
    }

    public updateMembership = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ITEM.UPDATE_MEMBERSHIP,
            data: data,
        });
        return result.data;
    }

}

export default ItemService;