import APIAccessor from "../apiProcessor";
import { SALE_API_URL } from "constants/index";

class SaleService {
    protected api: APIAccessor;

    constructor() {
        this.api = new APIAccessor(SALE_API_URL);
    }
}

export default SaleService;