import { Theme } from "@mui/material/styles";

export const textMore = (theme: Theme) => {
    const { palette } = theme;
    const color = palette.info.main;
    return {
        fontSize: `.8rem`,
        color,
        fontWeight: 400,
        cursor: "pointer",
    };
};

export const arrow = (theme: Theme, ownerState: { isDown: boolean }) => {
    const { isDown } = ownerState;
    const transform = isDown ? `rotate(0deg)` : `rotate(-180deg)`;
    return {
        transform,
        transition: `transform 0.1s linear`,
        marginLeft: `.5rem`,
        width: `1rem`,
        height: `1.rem`,
    };
};
