import { ModulePartnerCommission, SaleCommonProvider } from "@maysoft/sale-common-react";
import Helpers from "commons/helpers";
import { Mode } from "constants/enum";
import Constants from "constants/index";
import Screens from "constants/screens";
import useStrings from "hooks/useStrings";
import DashboardLayout from "layout/dashboardLayout";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "store";
import { setListPathName } from "store/slice/titleRoute.slice";

const PartnerCommissionScreen = () => {
    const navigate = useNavigate();
    const strings = useStrings();
    const [searchParams] = useSearchParams();
    const organizationId = useSelector((state: RootState) => state.userInfo.currentOrganization);
    const location = useLocation();
    const dispatch = useDispatch();

    const [request] = useState<any>(() => ({
        pageSize: searchParams.get("pageSize") || Constants.ROW_PER_PAGE_20,
        pageNumber: searchParams.get("pageNumber") || 1,
        searchText: searchParams.get("searchText"),
        organizationId: organizationId,
        status: searchParams.get("status"),
        manual: searchParams.get("manual"),
        partnerId: searchParams.get("partnerId"),
        programId: searchParams.get("programId"),
        orderId: searchParams.get("orderId"),
        paymentStatus: searchParams.get("paymentStatus"),
        confirmStatus: searchParams.get("confirmStatus"),
    }))

    const isFirstNavigateRef = useRef(true)
    const onGetpaged = useCallback(({ query }: any) => {
        dispatch(setListPathName({ query, pathname: Screens.PARTNER_COMMISSION_LIST, }));
        navigate(Screens.PARTNER_COMMISSION_LIST.concat(query), { replace: isFirstNavigateRef.current });
        isFirstNavigateRef.current = false;
    }, [navigate, dispatch])
    const onNavigate = useCallback(({ id }: { id?: string }) => id 
        ? navigate(Screens.PARTNER_COMMISSION_DETAIL.concat("?id=").concat(id))
        : navigate(Screens.PARTNER_COMMISSION_CREATE), [navigate])


    const id = searchParams.get("id");
    const isListScreen = location.pathname.startsWith(Screens.PARTNER_COMMISSION_LIST);
    const isCreateScreen = Helpers.isNullOrEmpty(id);
    const title = useMemo(() => {
        if (isListScreen) {
            return strings.PARTNER_COMMISSION.TITLE_LIST;
        }
        if (isCreateScreen) {
            return strings.PARTNER_COMMISSION.TITLE_DETAIL_VIEW;
        }
        return strings.PARTNER_COMMISSION.TITLE_CREATE_VIEW;
    }, [strings, isListScreen, isCreateScreen])
    const routes = useMemo(() => {
        if (isListScreen) {
            return [ { title: strings.PARTNER_COMMISSION.TITLE_LIST, route: "" } ]
        }
        if (isCreateScreen) {
            return [
                { title: strings.PARTNER_COMMISSION.TITLE_LIST, route: Screens.PARTNER_COMMISSION_LIST },
                { title: strings.PARTNER_COMMISSION.TITLE_CREATE_VIEW, route: "" },
            ]
        }
        return [
            { title: strings.PARTNER_COMMISSION.TITLE_LIST, route: Screens.PARTNER_COMMISSION_LIST },
            { title: strings.PARTNER_COMMISSION.TITLE_DETAIL_VIEW, route: "" },
        ]
    }, [isCreateScreen, isListScreen, strings])
    const [mode, setMode] = useState<Mode>((location?.state as any)?.mode || Mode.Create);

    return (
        <SaleCommonProvider>
            <DashboardLayout
                isPermission
                isRequiredOrganization
                title={title}
                route={routes}
            >
                <ModulePartnerCommission
                    onGetPaged={onGetpaged}
                    onNavigate={onNavigate}
                    requestGetPaged={request}

                    onGoBack={() => navigate(Screens.PARTNER_COMMISSION_LIST)}
                    idDetail={id}

                    mode={mode}
                    onChangeMode={setMode}
                    keyModuleContainer={{
                        create: {
                            pathName: Screens.PARTNER_COMMISSION_CREATE,
                        },
                        detail: {
                            pathName: Screens.PARTNER_COMMISSION_DETAIL,
                        },
                        list: {
                            pathName: Screens.PARTNER_COMMISSION_LIST,
                        }
                    }}
                />
            </DashboardLayout>
        </SaleCommonProvider>
    )
}

export default PartnerCommissionScreen;