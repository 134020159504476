import Screens from "constants/screens";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Strings from "constants/strings";
import { DashboardLayout } from "layout";
import { ModuleAffiliater } from "@maysoft/sale-common-react";
import { Mode } from "constants/enum";

interface AffiliaterScreenProps {}

const AffiliaterScreen: React.FC<AffiliaterScreenProps> = (props) => {
    const navigate = useNavigate();
    
    const handleGetPaged = useCallback(
        ({ query }: any) => {
            navigate(Screens.AFFILIATER_LIST + "?" + query, { replace: true });
        },
        [navigate]
    );

    const defaultHandler = useCallback(() => { }, [])

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={Strings.PROGRAM_AFFILIATE.TITLE_LIST_VIEW}
            route={[{ title: Strings.PROGRAM_AFFILIATE.TITLE_MENU, route: "" }]}
        >
            <ModuleAffiliater
                keyModuleContainer={{
                    list: {
                        pathName: Screens.AFFILIATER_LIST,
                    }
                }} 
                mode={Mode.View}
                onChangeMode={defaultHandler}
                onGetPaged={handleGetPaged}
                onGoBack={defaultHandler}
                onNavigate={defaultHandler}
                requestGetPaged={undefined}
            />
        </DashboardLayout>
    );
};

export default AffiliaterScreen;
