import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "../../constants";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { setListPathName } from "store/slice/titleRoute.slice";
import { ModulePartner, PartnerType } from "@maysoft/sale-common-react";



const SupplierScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const id: string = searchParams.get("id");

    const modeList = (Screens.SUPPLIER_LIST.includes(window.location.pathname));

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const itemPathName = listPathName.find((el) => el.pathName === Screens.SUPPLIER_LIST);

    const [mode, setMode] = useState<Mode>((location?.state as any)?.mode || Mode.View);

    const [requestData] = useState<any>({
        totalCount: itemPathName?.totalCount || 0,
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        loyaltyClasses: searchParams.getAll("loyaltyClasses")?.map((el) => Number(el)),
        type: Helpers.isNullOrEmpty(searchParams.get("type")) ? undefined : Number(searchParams.get("type")),
    });

    useEffect(() => {
        if (Helpers.isNullOrEmpty(id)) {
            setMode(Mode.Create);
        } else {
            setMode((location?.state as any)?.mode || Mode.View);
        }
    }, [id]);

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(id)) return Strings.SUPPLIER.TITLE_CREATE_VIEW;
        return mode === Mode.View ? Strings.SUPPLIER.TITLE_DETAIL_VIEW : Strings.SUPPLIER.TITLE_UPDATE_VIEW;
    }, [id, mode]);

    const route = useMemo(() => {
        const temp = [{ title: Strings.SUPPLIER.TITLE_MENU, route: Screens.SUPPLIER_LIST }];
        if (Helpers.isNullOrEmpty(id)) {
            temp.push({ title: Strings.Common.CREATE_NEW, route: "" });
        } else {
            temp.push({
                title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [id, mode]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.SUPPLIER_LIST);
        navigate(Screens.SUPPLIER_LIST + (itemPathName ? itemPathName.query : ""));
    };

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={modeList ? Strings.SUPPLIER.TITLE_LIST : title}
            route={modeList ? [{ title: Strings.SUPPLIER.TITLE_MENU, route: "" }] : route}
        >
            <ModulePartner
                keyModuleContainer={{
                    list: { pathName: Screens.SUPPLIER_LIST },
                    detail: { pathName: Screens.SUPPLIER_EDIT },
                }}

                requestGetPaged={requestData}
                partnerType={PartnerType.Supplier}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.PRODUCT_LIST, query, totalCount }));
                    navigate(Screens.SUPPLIER_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    const id = data.id ? `?id=${data.id}` : "";
                    navigate(Screens.SUPPLIER_EDIT + id, { state: { mode: data.mode } });
                }}

                mode={mode}
                idDetail={id}
                onGoBack={handleGoBack}
                onChangeMode={(value) => { setMode(value); }}
            />
        </DashboardLayout>
    );
};

export default SupplierScreen;
