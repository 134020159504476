import Strings from "constants/strings";

import { OrderStatus } from "constants/enum";
import CustomCell from ".";

interface IProps {
    status: OrderStatus;
}

const OrderStatusCell = (props: IProps) => {
    switch (props.status) {
        case OrderStatus.Cancel:
            return <CustomCell icon="cancel" color="error" status={Strings.STATUS.ORDER.Cancel} />;
        case OrderStatus.Pending:
            return <CustomCell icon="timer" color="secondary" status={Strings.STATUS.ORDER.Pending} />;
        case OrderStatus.Confirm:
            return <CustomCell icon="add_circle" color="info" status={Strings.STATUS.ORDER.Confirm} />;
        case OrderStatus.Editing:
            return <CustomCell icon="edit" color="warning" status={Strings.STATUS.ORDER.Editing} />;
        case OrderStatus.PaymentConfirm:
            return <CustomCell icon="verified" color="info" status={Strings.STATUS.ORDER.PaymentConfirm} />;
        case OrderStatus.Completed:
            return <CustomCell icon="check" color="success" status={Strings.STATUS.ORDER.Completed} />;
        default:
            break;
    }
};

export default OrderStatusCell;
