import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Mode } from "constants/enum";
import Constants from "constants/index";
import Screens from "constants/screens";
import { DashboardLayout } from "layout";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ModuleAffiliateProgram } from "@maysoft/sale-common-react";
import Helpers from "commons/helpers";
import useStrings from "hooks/useStrings";
import { useDispatch } from "react-redux";
import { setListPathName } from "store/slice/titleRoute.slice";

interface RequestData {
    searchText?: string;
    pageNumber?: number;
    pageSize?: number;
    organizationId?: string;
}

const AffiliateProgramScreen = () => {
    const strings = useStrings();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const state = location?.state as any;
    const locationMode = state?.mode ?? Mode.View;
    const [mode, setMode] = useState<Mode>(locationMode);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const dispatch = useDispatch();
    const [request] = useState<RequestData>({
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        organizationId: userInfo.userProfile.organizationId,
    });

    const handleGetPaged = useCallback(
        ({ query }: any) => {
            dispatch(setListPathName({ query, pathname: Screens.AFFILIATE_PROGRAM_LIST, }));
            navigate(Screens.AFFILIATE_PROGRAM_LIST + query, { replace: true });
        }, [navigate, dispatch]);
    const handleNavigate = (data: { mode?: number; id?: string }) => {
        const navigatePath = data.id ? Screens.AFFILIATE_PROGRAM_EDIT + `?id=${data.id}` : Screens.AFFILIATE_PROGRAM_EDIT;
        navigate(navigatePath, { state: { mode: data.id ? data.mode : Mode.Create } });
    };
    const idDetail = searchParams.get("id");
    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            setMode(Mode.Create);
        }
    }, [idDetail]);

    const isListScreen = location.pathname.startsWith(Screens.AFFILIATE_PROGRAM_LIST);
    const isCreateScreen = !isListScreen && Helpers.isNullOrEmpty(idDetail);
    const title = useMemo(() => {
        if (isListScreen) {
            return strings.AFFILIATE_PROGRAM.TITLE_LIST_VIEW;
        }
        if (isCreateScreen) return strings.AFFILIATE_PROGRAM.TITLE_CREATE_VIEW;
        return mode === Mode.View ? strings.AFFILIATE_PROGRAM.TITLE_DETAIL_VIEW : strings.AFFILIATE_PROGRAM.TITLE_UPDATE_VIEW;
    }, [isListScreen, mode, strings, isCreateScreen]);
    const routes = useMemo(() => {
        if (isListScreen) {
            return [{ title: strings.AFFILIATE_PROGRAM.TITLE_MENU, route: "" }];
        }
        const temp = [{ title: strings.AFFILIATE_PROGRAM.TITLE_MENU, route: Screens.AFFILIATE_PROGRAM_LIST }];
        if (isCreateScreen) {
            temp.push({
                title: strings.Common.CREATE_NEW,
                route: "",
            });
        } else {
            temp.push({
                title: mode === Mode.View ? strings.Common.DETAIL : strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [isCreateScreen, mode, strings, isListScreen]);

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={title}
            route={routes}
        >
            <ModuleAffiliateProgram
                keyModuleContainer={{
                    list: {
                        pathName: Screens.AFFILIATE_PROGRAM_LIST,
                    },
                    detail: {
                        pathName: Screens.AFFILIATE_PROGRAM_EDIT,
                    },
                }}
                onGetPaged={handleGetPaged}
                requestGetPaged={request}
                onNavigate={handleNavigate}

                mode={Mode.View}
                onChangeMode={() => { }}
                onGoBack={handleGoBack}
                idDetail={idDetail}
            />
        </DashboardLayout>
    );
};

export default AffiliateProgramScreen;
