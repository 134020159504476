import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDataAlert {
    message?: string;
    type: "warning" | "success" | "error" | "info";
}


export interface IMessageFirebase {
    messageId?: string,
    data?: {
        Title?: string,
        Body?: string,
    }
}

export interface MessageState {
    dataMessage: IMessageFirebase;
    totalUnreadNotifi?: number;
    dataAlert: IDataAlert;
}

const initialState: MessageState = {
    totalUnreadNotifi: 0,
    dataMessage: {
        messageId: undefined,
        data: {
            Title: undefined,
            Body: undefined,
        },
    },
    dataAlert: { message: "", type: "info" },
};

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setDataMessage: (state, action: PayloadAction<IMessageFirebase>) => {
            state.dataMessage = action.payload;
        },
        resetDataMessage: (state) => {
            state.dataMessage = {
                messageId: undefined,
                data: {
                    Title: undefined,
                    Body: undefined,
                }
            };
        },

        setDataAlert: (state, action: PayloadAction<IDataAlert>) => {
            state.dataAlert = action.payload;
        },
        resetDataAlert: (state) => {
            state.dataAlert = { message: "", type: undefined };
        },

        setAddUnreadNotifi: (state) => {
            state.totalUnreadNotifi = (state.totalUnreadNotifi || 0) + 1;
        },
        setTotalUnreadNotifi: (state, action: PayloadAction<number | undefined>) => {
            state.totalUnreadNotifi = action.payload || 0;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setAddUnreadNotifi, setTotalUnreadNotifi,
    setDataMessage, resetDataMessage,
    setDataAlert, resetDataAlert,
} = messageSlice.actions;

export default messageSlice.reducer;
