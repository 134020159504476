import { DashboardLayout } from "layout";

const NotFoundScreen = () => {
    return (
        <DashboardLayout isPermission={false} title={""} route={[]}>
            <h1 className="text-center">404 | Page Not Found</h1>
        </DashboardLayout>
    );
};

export default NotFoundScreen;
