import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "../../constants";
import Screens from "constants/screens";
import Strings from "constants/strings";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { ModulePromotion } from "@maysoft/sale-common-react";
import { setListPathName } from "store/slice/titleRoute.slice";



const PromotionScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);

    const itemPathName = listPathName.find((el) => el.pathName === Screens.PROMOTION_LIST);

    const id: string = searchParams.get("id");

    const modeList = (Screens.PROMOTION_LIST.includes(window.location.pathname));

    const [mode, setMode] = useState<Mode>((location?.state as any)?.mode || Mode.Create);

    const [requestData] = useState<any>({
        totalCount: itemPathName?.totalCount || 0,
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

        endTime: searchParams.get("endTime"),
        startTime: searchParams.get("startTime"),
        searchText: searchParams.get("searchText"),
        discountType: Helpers.isNullOrEmpty(searchParams.get("discountType")) ? undefined : Number(searchParams.get("discountType")),
        discountMethod: Helpers.isNullOrEmpty(searchParams.get("discountMethod")) ? undefined : Number(searchParams.get("discountMethod")),
    });

    useEffect(() => {
        if (Helpers.isNullOrEmpty(id)) {
            setMode(Mode.Create);
        } else {
            setMode((location?.state as any)?.mode || Mode.View);
        }
    }, [id]);

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(id)) return Strings.PROMOTION.TITLE_CREATE;
        return mode === Mode.View ? Strings.PROMOTION.TITLE_DETAIL : Strings.PROMOTION.TITLE_UPDATE;
    }, [id, mode]);

    const route = useMemo(() => {
        const temp = [{ title: Strings.PROMOTION.TITLE_MENU, route: Screens.PROMOTION_LIST }];
        if (Helpers.isNullOrEmpty(id)) {
            temp.push({ title: Strings.Common.CREATE_NEW, route: "" });
        } else {
            temp.push({
                title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [id, mode]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.PROMOTION_LIST);
        navigate(Screens.PROMOTION_LIST + (itemPathName ? itemPathName.query : ""));
    };

    return (
        <DashboardLayout
            isPermission
            title={modeList ? Strings.PROMOTION.TITLE_LIST_VIEW : title}
            route={modeList ? [{ title: Strings.PROMOTION.TITLE_MENU, route: "" }] : route}
        >
            <ModulePromotion
                keyModuleContainer={{
                    list: { pathName: Screens.PROMOTION_LIST },
                    detail: { pathName: Screens.PROMOTION_EDIT },
                }}

                requestGetPaged={requestData}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ query, totalCount, pathname: Screens.PROMOTION_LIST, }));
                    navigate(Screens.PROMOTION_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    const id = data.id ? `?id=${data.id}` : "";
                    navigate(Screens.PROMOTION_EDIT + id, { state: { mode: data.mode } });
                }}

                mode={mode}
                idDetail={id}
                onGoBack={handleGoBack}
                onChangeMode={(value) => { setMode(value); }}
            />
        </DashboardLayout>
    );
};

export default PromotionScreen;
