import Strings from "constants/strings";

import { VoucherHistoryStatus } from "constants/enum";
import CustomCell from ".";

interface IProps {
    status: VoucherHistoryStatus;
}

const CashBookStatusCell = (props: IProps) => {
    switch (props.status) {
        case VoucherHistoryStatus.Cancel:
            return <CustomCell icon="cancel" color="error" status={Strings.STATUS.ORDER.Cancel} />;
        case VoucherHistoryStatus.Pending:
            return <CustomCell icon="timer" color="secondary" status={Strings.Common.PROCESSING} />;
        case VoucherHistoryStatus.Commit:
            return <CustomCell icon="add_circle" color="info" status={Strings.PAYMENT_TICKET.COMMIT} />;
        case VoucherHistoryStatus.Updated:
            return <CustomCell icon="edit" color="warning" status={Strings.PAYMENT_TICKET.UPDATED} />;
        case VoucherHistoryStatus.CreateAuto:
            return <CustomCell icon="verified" color="info" status={Strings.PAYMENT_TICKET.CREATE_AUTO} />;
        case VoucherHistoryStatus.Completed:
            return <CustomCell icon="check" color="success" status={Strings.STATUS.ORDER.Completed} />;
        default:
            break;
    }
}

export default CashBookStatusCell