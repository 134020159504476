import { FormField, Typography } from "@maysoft/common-component-react";

import Strings from "constants/strings";

import { ICodename } from "commons/interfaces";
import { AttributeValueType, Mode } from "constants/enum";
import { IAttributeValue, IDataEditAttribute } from "./interface";

const RenderInputValueByType = (props: {
    mode?: Mode;
    isModal: boolean;
    disabled: boolean;
    languages: string;
    itemValue: IAttributeValue;
    dataValueType: ICodename[];
    dataDetailAttribute: IDataEditAttribute;
    handleOnchangeValueItem: (newValue: any, key: string, idItem: string) => void;
}) => {
    switch (Number(props?.dataDetailAttribute?.valueType?.value || props?.dataValueType[0].code)) {
        case AttributeValueType.MultiLanguage:
            return props?.mode !== Mode.View ? (
                <FormField
                    required
                    disabled={props?.disabled}
                    placeholder={Strings.ATTRIBUTE.INPUT_VALUE}
                    errorMessage={props?.itemValue?.value?.error}
                    value={props?.itemValue?.value?.value?.[props?.languages] || ""}
                    label={props?.isModal ? Strings.ATTRIBUTE.INPUT_VALUE : undefined}
                    onChangeValue={(value) => {
                        props?.handleOnchangeValueItem(value, "value", props?.itemValue?.id);
                    }}
                    id={`myInputMultiLanguage_${props?.itemValue?.id}`}
                />
            ) : (
                <Typography variant="button">{props?.itemValue?.value?.value?.[props?.languages] || ""}</Typography>
            );
        case AttributeValueType.Number:
            return props?.mode !== Mode.View ? (
                <FormField
                    required
                    type="number"
                    disabled={props?.disabled}
                    value={props?.itemValue?.valueNumber}
                    placeholder={Strings.ATTRIBUTE.INPUT_VALUE}
                    errorMessage={props?.itemValue?.value?.error}
                    label={props?.isModal ? Strings.ATTRIBUTE.INPUT_VALUE : undefined}
                    onChangeValue={(value) => {
                        props?.handleOnchangeValueItem(value, "valueNumber", props?.itemValue?.id);
                    }}
                    id={`myInputNumber_${props?.itemValue?.id}`}
                />
            ) : (
                <Typography variant="button">{props?.itemValue?.valueNumber || ""}</Typography>
            );
        case AttributeValueType.String:
            return props?.mode !== Mode.View ? (
                <FormField
                    required
                    disabled={props?.disabled}
                    value={props?.itemValue?.valueString || ""}
                    placeholder={Strings.ATTRIBUTE.INPUT_VALUE}
                    errorMessage={props?.itemValue?.value?.error}
                    label={props?.isModal ? Strings.ATTRIBUTE.INPUT_VALUE : undefined}
                    onChangeValue={(value) => {
                        props?.handleOnchangeValueItem(value, "valueString", props?.itemValue?.id);
                    }}
                    id={`myInputString_${props?.itemValue?.id}`}
                />
            ) : (
                <Typography variant="button">{props?.itemValue?.valueString || ""}</Typography>
            );
        default:
            return <></>;
    }
};

export default RenderInputValueByType;
