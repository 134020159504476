import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface TokenInfoState {
    id_token?: string,
    token_type: string,
    access_token: string,
    refresh_token?: string,
    expires_at?: number,
    scope?: string,
    session_state: string,
    profile: any,
}

const initialState: TokenInfoState = {
    id_token: "",
    token_type: "",
    access_token: "",
    refresh_token: "",
    expires_at: 0,
    scope: "",
    session_state: "",
    profile: "",
}

export const tokenInfoSlice = createSlice({
    name: "tokenInfo",
    initialState,
    reducers: {
        setTokenInfo: (state, action: PayloadAction<TokenInfoState>) => {
            state.id_token = action.payload.id_token;
            state.access_token = action.payload.access_token;
            state.token_type = action.payload.token_type;
            state.expires_at = action.payload.expires_at;
            state.profile = action.payload.profile;
            state.refresh_token = action.payload.refresh_token;
            state.scope = action.payload.scope;
            state.session_state = action.payload.session_state;
        },
        resetTokenInfo: (state) => {
            state.id_token = initialState.id_token;
            state.access_token = initialState.access_token;
            state.token_type = initialState.token_type;
            state.expires_at = initialState.expires_at;
            state.profile = initialState.profile;
            state.refresh_token = initialState.refresh_token;
            state.scope = initialState.scope;
            state.session_state = initialState.session_state;
        },
    }
})

// Action creators are generated for each case reducer function
export const { setTokenInfo, resetTokenInfo } = tokenInfoSlice.actions

export default tokenInfoSlice.reducer