import moment from "moment";
import printJS from "print-js";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Grid, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Helpers from "commons/helpers";
import Constants from "../../constants";
import Strings from "constants/strings";
import Screens from "constants/screens";
import UserService from "services/identity/user.service";
import PartnerService from "services/sale/partner.service";
import CashBookService from "services/sale/cashBook.service";
import CashBookStatusCell from "components/CustomCell/cashBookStatus";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { ICodename, IInput } from "commons/interfaces";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from "store/slice/titleRoute.slice";
import { PaymentType, OrderStatus, ReceiveVoucherType, CashBookType, ActionTypeSelectBox, VoucherHistoryStatus } from "constants/enum";
import { Autocomplete, Box, Button, DatePicker, FormField, DataTable } from "@maysoft/common-component-react";
import { AutocompleteLoadMore } from "components";

interface RequestData {
    searchText?: string;
    fromDate?: IInput;
    toDate?: IInput;
    groupId?: IInput;
    organizationId?: IInput;
    type?: IInput;
    partnerId?: IInput;
    createUser?: IInput;
    voucherCode?: IInput;
    revenueRecognition?: IInput;
    paymentMethod?: IInput;
    partnerTypes?: IInput;
    userGroup?: IInput;
    pageNumber?: number;
    pageSize?: number;
    totalCount?: number;
    createUserName?: IInput;
    partnerName?: IInput;
}

interface IModel {
    // tableData: any;
    endMoney: number;
    startMoney: number;
    totalPayment: number;
    totalReceive: number;
    requestData: RequestData;
    requestDataTemp: RequestData;
}

const CashBookScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userService = new UserService();
    const partnerService = new PartnerService();
    const cashbookService = new CashBookService();

    // const reportService = new ReportService();
    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const userProfile = useSelector((state: RootState) => state.userInfoSlice?.userProfile);
    const { groupId, organizationId } = userProfile;

    const [partnerList, setPartnerList] = useState<ICodename[]>([]);
    const [staffList, setStaffList] = useState<ICodename[]>([]);

    const voucherTypeList: ICodename[] = [
        { code: `${ReceiveVoucherType.SelfCreated}`, name: Strings.RECEIVE_VOUCHER.SELF_CREATED },
        { code: `${ReceiveVoucherType.Automatic}`, name: Strings.RECEIVE_VOUCHER.AUTOMATIC },
    ];

    const paymentMethodList: ICodename[] = [
        { code: `${PaymentType.Cash}`, name: Strings.ORDER.CASH },
        { code: `${PaymentType.Banking}`, name: Strings.ORDER.BANKING },
        { code: `${PaymentType.Paypal}`, name: Strings.ORDER.PAYPAL },
        { code: `${PaymentType.VnPay}`, name: Strings.ORDER.VNPAY },
    ];

    const typeList: ICodename[] = [
        { code: `${CashBookType.Receive}`, name: Strings.CASH_BOOK.RECEIVE },
        { code: `${CashBookType.Payment}`, name: Strings.CASH_BOOK.PAYMENT },
        { code: `${CashBookType.All}`, name: Strings.CASH_BOOK.ALL },
    ];

    const voucherStatusList: ICodename[] = [
        { code: `${VoucherHistoryStatus.Cancel}`, name: Strings.Common.CANCELED, group: "error" },
        { code: `${VoucherHistoryStatus.Completed}`, name: Strings.Common.COMPLETE, group: "success" },
        { code: `${VoucherHistoryStatus.Pending}`, name: Strings.Common.PROCESSING, group: "warning" },
        { code: `${VoucherHistoryStatus.Updated}`, name: Strings.PAYMENT_TICKET.UPDATED, group: "warning" },
        { code: `${VoucherHistoryStatus.Commit}`, name: Strings.PAYMENT_TICKET.COMMIT, group: "warning" },
        { code: `${VoucherHistoryStatus.CreateAuto}`, name: Strings.PAYMENT_TICKET.CREATE_AUTO, group: "warning" },
    ];

    const [dataTable, setDataTable] = useState<any>({
        columns: [
            {
                Header: Strings.CASH_BOOK.STATUS,
                accessor: "voucherStatus",
                width: "150px",
                Cell: ({ value }: any) => <CashBookStatusCell status={value} />,
            },
            { Header: Strings.CASH_BOOK.TYPE, accessor: "type", width: "100px" },
            {
                Header: Strings.CASH_BOOK.CODE,
                accessor: "voucherCode",
                width: "100px",
                Cell: (row: any) => {
                    let screens =
                        Number(row.row.original?.cashBookType) === CashBookType.Receive ? Screens.RECEIVE_VOUCHER_EDIT : Screens.PAYMENT_TICKET;

                    return (
                        <Link className="hyperlink" to={`${screens}?id=${row.row.original?.id}`}>
                            {row.value}
                        </Link>
                    );
                },
            },
            { Header: Strings.CASH_BOOK.PARTNER_NAME, accessor: "partnerName", width: "200px" },
            { Header: Strings.CASH_BOOK.CREATE_TIME, accessor: "date", width: "100px" },
            { Header: Strings.CASH_BOOK.CREATE_USER, accessor: "createUserName", width: "100px" },
            { Header: Strings.CASH_BOOK.PAYMENT_TYPE, accessor: "paymentMethod", width: "100px" },
            { Header: Strings.CASH_BOOK.MONEY, accessor: "amount", width: "100px" },
            { Header: Strings.CASH_BOOK.CURRENCY, accessor: "currency", width: "100px" },
            {
                Header: Strings.CASH_BOOK.REFERENCE_DOCUMENT,
                accessor: "referenceCode",
                width: "100px",
                Cell: (row: any) => {
                    return (
                        <Link className="hyperlink" to={`${Screens.ORDER_EDIT}?id=${row.row.original?.referenceId}&type=${OrderStatus.Confirm}`}>
                            {row.value}
                        </Link>
                    );
                },
            },
            { Header: Strings.CASH_BOOK.DESCRIPTION, accessor: "description", width: "300px" },
        ],
        rows: [],
    });

    const [model, setModel] = useState<IModel>({
        requestData: {
            fromDate: {
                value: moment().startOf("month").unix(),
                error: "",
            },
            toDate: {
                value: moment(new Date()).unix(),
                error: "",
            },
            organizationId: {
                value: organizationId,
                error: "",
            },
            groupId: {
                value: groupId,
                error: "",
            },
            // type: { value: `${CashBookType.All}` },
            paymentMethod: { value: `${PaymentType.Cash}` },
            searchText: "",
            pageNumber: 1,
            pageSize: Constants.ROW_PER_PAGE,
            totalCount: 0,
        },
        requestDataTemp: {
            fromDate: {
                value: moment().startOf("month").unix(),
                error: "",
            },
            toDate: {
                value: moment(new Date()).unix(),
                error: "",
            },
            groupId: {
                value: groupId,
                error: "",
            },
            organizationId: {
                value: organizationId,
                error: "",
            },
            // type: { value: `${CashBookType.All}` },
            paymentMethod: { value: `${PaymentType.Cash}` },
            searchText: "",
            pageNumber: 1,
            pageSize: Constants.ROW_PER_PAGE,
            totalCount: 0,
        },
    } as IModel);

    useEffect(() => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.CASH_BOOK);

        if (!Helpers.isNullOrEmpty(organizationId)) {
            const {
                fromDate,
                toDate,
                groupId,
                organizationId,
                type,
                partnerId,
                createUser,
                voucherCode,
                paymentMethod,
                revenueRecognition,
                searchText,
                partnerTypes,
                pageSize,
                pageNumber,
            } = Helpers.getUrlParams([
                "fromDate",
                "toDate",
                "groupId",
                "organizationId",
                "type",
                "partnerId",
                "createUser",
                "voucherCode",
                "paymentMethod",
                "revenueRecognition",
                "searchText",
                "partnerTypes",
                "pageNumber",
                "pageSize",
            ]);
            let data: any = {
                fromDate: { value: fromDate || model?.requestData?.fromDate?.value },
                toDate: { value: toDate || model?.requestData?.toDate?.value },
                organizationId: { value: organizationId || model?.requestData?.organizationId?.value },
                type: { value: type || model?.requestData?.type?.value },
                partnerId: { value: partnerId || model?.requestData?.partnerId?.value },
                createUser: { value: createUser || model?.requestData?.createUser?.value },
                voucherCode: { value: voucherCode || model?.requestData?.voucherCode?.value },
                paymentMethod: { value: paymentMethod || model?.requestData?.paymentMethod?.value },
                // revenueRecognition: { value: revenueRecognition || model?.requestData?.revenueRecognition?.value },
                searchText: searchText || model?.requestData?.searchText,
                pageNumber: pageNumber || model?.requestData?.pageNumber,
                userGroup: partnerTypes || model?.requestData?.userGroup,
                pageSize: pageSize || model?.requestData?.pageSize,
                totalCount: itemPathName?.totalCount || 0,
            };

            getStaffList({ pageNumber: 1, searchText: undefined });
            getPartnerList();
            getData(data);
        }
    }, [userProfile?.organizationId]);

    const getData = async (data?: any) => {
        try {
            if (!handleValidated()) {
                dispatch(setDataAlert({ message: Strings.Validation.FILTER_REQUIRED, type: "warning" }));
                return;
            } else {
                dispatch(showLoading(true));

                const pageSize = data?.pageSize || Constants.ROW_PER_PAGE;
                const pageNumber = Helpers.getPageNumber(data?.pageNumber || 1, pageSize, data?.totalCount || 0);
                let requestData: RequestData = {
                    ...model.requestData,
                    fromDate: data?.fromDate,
                    toDate: data?.toDate,
                    groupId: data?.groupId,
                    organizationId: data?.organizationId,
                    type: data?.type,
                    partnerId: data?.partnerId,
                    createUser: data?.createUser,
                    voucherCode: data?.voucherCode,
                    paymentMethod: data?.paymentMethod,
                    // revenueRecognition: data?.revenueRecognition,
                    searchText: data?.searchText,
                    userGroup: data?.userGroup,
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                };

                const [rowData, totalCount, dataPayment] = await getCashbookService(requestData);
                requestData.totalCount = totalCount;

                setDataTable({ ...dataTable, rows: rowData || [] });
                setModel({
                    ...model,
                    endMoney: dataPayment?.endMoney,
                    startMoney: dataPayment?.startMoney,
                    totalPayment: dataPayment?.totalPayment,
                    totalReceive: dataPayment?.totalReceive,
                    requestData: requestData,
                });

                dispatch(showLoading(false));
            }
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getPartnerList = async () => {
        const partnerList: ICodename[] = [];
        const result = await partnerService.getAll(Helpers.handleFormatParams({ organizationId }));
        result.forEach((e: any) =>
            partnerList.push({
                code: e.id,
                name: e.partnerName,
            })
        );
        setPartnerList(partnerList);
    };

    const getStaffList = async (data: any) => {
        const staffList: ICodename[] = [];
        let request = {
            organizationId,
            pageNumber: data?.pageNumber,
            searchText: data?.searchText,
            pageSize: Constants.ROW_PER_PAGE_5,
        };
        const result = await userService.getPaged(Helpers.handleFormatParams(request));

        result?.items?.forEach((e: any) =>
            staffList.push({
                code: e.id,
                name: e.organizationUserProfile?.firstName || "",
            })
        );
        setStaffList(staffList);
    };

    const onReset = async () => {
        try {
            dispatch(showLoading(true));
            const [recordList, totalCount, dataPayment] = await getCashbookService(model.requestDataTemp);
            setDataTable({ ...dataTable, rows: recordList || [] });
            setModel({
                ...model,
                endMoney: dataPayment?.endMoney,
                startMoney: dataPayment?.startMoney,
                totalPayment: dataPayment?.totalPayment,
                totalReceive: dataPayment?.totalReceive,
                requestData: { ...model.requestDataTemp, totalCount },
            });
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onValueChange = (key: string, newValue: any) => {
        let requestDataTemp: any = { ...model.requestData };
        requestDataTemp[key] = { value: newValue, error: "" };
        setModel({ ...model, requestData: requestDataTemp });
    };

    const getCashbookService = async (data: any) => {
        const requestData: any = {
            searchText: data?.searchText,
            fromDate: data?.fromDate?.value,
            toDate: data?.toDate?.value,
            groupId: data?.groupId?.value,
            organizationId: data?.organizationId?.value,
            cashBookType: data?.type?.value ? Number(data?.type?.value) : CashBookType.All,
            partnerId: data?.partnerId?.value,
            createUser: data?.createUser?.value,
            voucherCode: data?.voucherCode?.value,
            paymentMethod: data?.paymentMethod?.value && Number(data?.paymentMethod?.value),
            // revenueRecognition: data?.revenueRecognition?.value && Number(data?.revenueRecognition?.value),
            pageNumber: data?.pageNumber || 1,
            pageSize: data?.pageSize || Constants.ROW_PER_PAGE,
        };

        // if (Number(data?.userGroup?.value) === UserGroup.Customer) {
        //     requestData.partnerTypes = [VoucherPartnerType.IndividualCustomer, VoucherPartnerType.CompanyCustomer];
        // } else if (Number(data?.userGroup?.value) === UserGroup.Supplier) {
        //     requestData.partnerTypes = [VoucherPartnerType.IndividualSupplier, VoucherPartnerType.CompanySupplier];
        // } else if (Number(data?.userGroup?.value) === UserGroup.Other) {
        //     requestData.partnerTypes = [VoucherPartnerType.DeliveryCompany];
        // }
        const result = await cashbookService.getPaged(Helpers.handleFormatParams(requestData));
        let rowData: any[] = [];
        result?.pagedList?.items?.forEach((el: any) => {
            rowData.push({
                id: el.id,
                date: moment(el.date * 1000).format(Constants.DateFormat.DDMMYYYY),
                type: voucherTypeList?.find((e) => Number(e.code) === Number(el.type))?.name,
                revenueRecognition: el.revenueRecognition,
                referenceCode: el.referenceCode,
                referenceId: el.referenceId,
                partnerId: el.partnerId,
                cashBookType: el.cashBookType,
                partnerName: el.partnerName,
                createUser: el.createUser,
                createUserName: el?.createUserName,
                voucherCode: el.voucherCode,
                paymentMethod: paymentMethodList?.find((e) => Number(e.code) === Number(el.paymentMethod))?.name,
                description: el.description,
                voucherStatus: Number(el.voucherStatus),
                amount: Helpers.formatCurrency(el.amount),
                currency: el.currency,
            });
        });

        let dataPayment = {
            startMoney: result?.startMoney,
            endMoney: result?.endMoney,
            totalPayment: result?.totalPayment,
            totalReceive: result?.totalReceive,
        };

        let query: string = `?pageNumber=${data?.pageNumber || 1}&pageSize=${data.pageSize || Constants.ROW_PER_PAGE}`;
        if (!Helpers.isNullOrEmpty(data?.searchText?.value)) {
            query += `&searchText=${data?.searchText?.value}`;
        }
        // if (!Helpers.isNullOrEmpty(data?.groupId?.value)) {
        //     query += `&groupId=${data?.groupId?.value}`;
        // }
        if (!Helpers.isNullOrEmpty(organizationId)) {
            query += `&organizationId=${data?.organizationId?.value}`;
        }
        if (!Helpers.isNullOrEmpty(data?.fromDate?.value)) {
            query += `&fromDate=${data?.fromDate?.value}`;
        }
        if (!Helpers.isNullOrEmpty(data?.toDate?.value)) {
            query += `&toDate=${data?.toDate?.value}`;
        }
        if (!Helpers.isNullOrEmpty(data?.type?.value)) {
            query += `&type=${data?.type?.value}`;
        }
        if (!Helpers.isNullOrEmpty(data?.partnerId?.value)) {
            query += `&partnerId=${data?.partnerId?.value}`;
        }
        if (!Helpers.isNullOrEmpty(data?.createUser?.value)) {
            query += `&createUser=${data?.createUser?.value}`;
        }
        if (!Helpers.isNullOrEmpty(data?.voucherCode?.value)) {
            query += `&voucherCode=${data?.voucherCode?.value}`;
        }
        if (!Helpers.isNullOrEmpty(data?.paymentMethod?.value)) {
            query += `&paymentMethod=${data?.paymentMethod?.value}`;
        }
        // if (!Helpers.isNullOrEmpty(data?.revenueRecognition?.value)) {
        //     query += `&revenueRecognition=${data?.revenueRecognition?.value}`;
        // }
        if (!Helpers.isNullOrEmpty(data?.partnerTypes?.value)) {
            query += `&partnerTypes=${data?.partnerTypes?.value}`;
        }

        navigate(Screens.CASH_BOOK + query, { state: { ...data, totalCount: result?.pagedList?.totalCount } });
        dispatch(setListPathName({ pathname: Screens.CASH_BOOK, query, totalCount: result?.pagedList?.totalCount }));

        return [rowData, result?.pagedList?.totalCount, dataPayment || {}];
    };

    const handleViewReport = async () => {
        try {
            if (!handleValidated()) {
                dispatch(setDataAlert({ message: Strings.Validation.FILTER_REQUIRED, type: "warning" }));
                return;
            } else {
                dispatch(showLoading(true));
                let requestData = {
                    ...model.requestData,
                    searchText: model.requestData?.searchText,
                    fromDate: model?.requestData?.fromDate,
                    toDate: model?.requestData?.toDate,
                    groupId: model?.requestData?.groupId,
                    organizationId: model?.requestData?.organizationId,
                    type: model?.requestData?.type,
                    partnerId: model?.requestData?.partnerId,
                    createUser: model?.requestData?.createUser,
                    voucherCode: model?.requestData?.voucherCode,
                    paymentMethod: model?.requestData?.paymentMethod,
                    revenueRecognition: model?.requestData?.revenueRecognition,
                    pageNumber: 1,
                    pageSize: model.requestData?.pageSize || Constants.ROW_PER_PAGE,
                };
                const [recordList, totalCount, dataPayment] = await getCashbookService(requestData);
                requestData.totalCount = totalCount;
                setDataTable({ ...dataTable, rows: recordList || [] });
                setModel({
                    ...model,
                    endMoney: dataPayment?.endMoney,
                    startMoney: dataPayment?.startMoney,
                    totalPayment: dataPayment?.totalPayment,
                    totalReceive: dataPayment?.totalReceive,
                    requestData: { ...requestData, totalCount },
                });
                dispatch(showLoading(false));
            }
        } catch (error: any) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleConfirmFilter = async (searchText?: string) => {
        try {
            if (!handleValidated()) {
                dispatch(setDataAlert({ message: Strings.Validation.FILTER_REQUIRED, type: "warning" }));
                return;
            } else {
                dispatch(showLoading(true));
                let requestData = {
                    ...model.requestData,
                    searchText: searchText,
                    fromDate: model?.requestData?.fromDate,
                    toDate: model?.requestData?.toDate,
                    groupId: model?.requestData?.groupId,
                    organizationId: model?.requestData?.organizationId,
                    type: model?.requestData?.type,
                    partnerId: model?.requestData?.partnerId,
                    createUser: model?.requestData?.createUser,
                    voucherCode: model?.requestData?.voucherCode,
                    paymentMethod: model?.requestData?.paymentMethod,
                    revenueRecognition: model?.requestData?.revenueRecognition,
                    userGroup: model?.requestData?.userGroup,
                    pageNumber: 1,
                    pageSize: model.requestData?.pageSize || Constants.ROW_PER_PAGE,
                };
                const [recordList, totalCount, dataPayment] = await getCashbookService(requestData);
                requestData.totalCount = totalCount;
                setDataTable({ ...dataTable, rows: recordList || [] });
                setModel({
                    ...model,
                    endMoney: dataPayment?.endMoney,
                    startMoney: dataPayment?.startMoney,
                    totalPayment: dataPayment?.totalPayment,
                    totalReceive: dataPayment?.totalReceive,
                    requestData: { ...requestData, totalCount },
                });
                dispatch(showLoading(false));
            }
        } catch (error: any) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleValidated = () => {
        let isCheck: boolean = true;
        let data: any = { ...model.requestData };
        if (Helpers.isNullOrEmpty(data["fromDate"]?.value)) {
            isCheck = false;
            data["fromDate"] = { error: Strings.Validation.REQUIRED };
        }
        if (Helpers.isNullOrEmpty(data["toDate"]?.value)) {
            isCheck = false;
            data["toDate"] = { error: Strings.Validation.REQUIRED };
        }

        setModel({ ...model, requestData: data });
        return isCheck;
    };

    const handlePrintOrder = async (ids?: string[]) => {
        try {
            dispatch(showLoading(true));
            let data = {
                organizationId: organizationId,
                groupId: model?.requestData?.groupId?.value,
                fromDate: model?.requestData?.fromDate?.value,
                toDate: model?.requestData?.toDate?.value,
                cashBookType: model?.requestData?.type?.value || CashBookType.All,
                ids: ids,
            };
            const result = await cashbookService.export(Helpers.handleFormatParams(data));
            Helpers.getBase64(result, (data) =>
                printJS({
                    printable: data,
                    type: "pdf",
                    base64: true,
                })
            );
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleOnSubmitActionCheckedBox = async (dataID: string[]) => {
        await handlePrintOrder(dataID);
    };

    const renderCardFilterReport = () => (
        <Card>
            <Box p={2}>
                <Box component="form">
                    <Grid container spacing={3} p={2}>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                data={typeList || []}
                                label={Strings.CASH_BOOK.TYPE}
                                placeholder={`${Strings.Common.SELECT} ${Strings.CASH_BOOK.TYPE}`}
                                defaultValue={model.requestData?.type?.value}
                                errorMessage={model.requestData?.type?.error}
                                onChange={(value) => {
                                    onValueChange("type", value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteLoadMore
                                dataOptions={staffList || []}
                                label={Strings.CASH_BOOK.STAFF_NAME}
                                placeholder={`${Strings.Common.SEARCH} ${Strings.CASH_BOOK.STAFF_NAME}`}
                                defaultValue={model.requestData?.createUser?.value}
                                errorMessage={model.requestData?.createUser?.error}
                                onChange={(value) => {
                                    onValueChange("createUser", value);
                                }}
                                allowLoadMore
                                onGetAPI={(data) => getStaffList(data)}
                            />
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <Autocomplete
                                data={organizationBranchList || []}
                                label={Strings.BRANCH.NAME_BRANCH}
                                placeholder={`${Strings.Common.SELECT} ${Strings.BRANCH.NAME_BRANCH}`}
                                defaultValue={model.requestData?.groupId?.value}
                                errorMessage={model.requestData?.groupId?.error}
                                onChange={(value) => {
                                    onValueChange("groupId", value);
                                }}
                            />
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <FormField
                                value={model.requestData?.voucherCode?.value || ""}
                                label={Strings.RECEIVE_VOUCHER.VOUCHER_CODE}
                                placeholder={`${Strings.Common.INPUT} ${Strings.RECEIVE_VOUCHER.VOUCHER_CODE}`}
                                onChangeValue={(value) => {
                                    onValueChange("voucherCode", value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                data={paymentMethodList || []}
                                defaultValue={model.requestData?.paymentMethod?.value}
                                label={Strings.RECEIVE_VOUCHER.PAYMENT_METHOD}
                                placeholder={`${Strings.Common.SELECT} ${Strings.RECEIVE_VOUCHER.PAYMENT_METHOD}`}
                                onChange={(value) => {
                                    onValueChange("paymentMethod", value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                data={partnerList || []}
                                label={Strings.CASH_BOOK.CUSTOMER}
                                placeholder={`${Strings.Common.SEARCH} ${Strings.CASH_BOOK.CUSTOMER}`}
                                defaultValue={model.requestData?.partnerId?.value}
                                errorMessage={model.requestData?.partnerId?.error}
                                onChange={(value) => {
                                    onValueChange("partnerId", value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                required
                                errorMessage={model.requestData?.fromDate?.error}
                                value={Helpers.getDateValue(model.requestData?.fromDate?.value)}
                                label={Strings.CASH_BOOK.FROM_DATE}
                                placeholder={Strings.CASH_BOOK.FROM_DATE}
                                onChangeValue={(value: any) => {
                                    onValueChange("fromDate", value ? moment(value).unix() : undefined);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DatePicker
                                required
                                errorMessage={model.requestData?.toDate?.error}
                                value={Helpers.getDateValue(model.requestData?.toDate?.value)}
                                label={Strings.CASH_BOOK.TO_DATE}
                                placeholder={Strings.CASH_BOOK.TO_DATE}
                                onChangeValue={(value: any) => {
                                    onValueChange("toDate", value ? moment(value).unix() : undefined);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="secondary" onClick={() => handleViewReport()} style={{ marginRight: 5, float: "right" }}>
                                {Strings.CASH_BOOK.VIEW_REPORT}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Card>
    );

    return (
        <DashboardLayout isPermission title={Strings.CASH_BOOK.TITLE} route={[{ title: Strings.CASH_BOOK.TITLE, route: "" }]}>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" my={2}>
                <Button color="info" onClick={() => navigate(Screens.RECEIVE_VOUCHER_EDIT)}>
                    {Strings.CASH_BOOK.CREATE_RECEIVE_VOUCHER}
                </Button>
                <Button color="success" style={{ marginLeft: 5 }} onClick={() => navigate(Screens.PAYMENT_TICKET)}>
                    {Strings.CASH_BOOK.CREATE_PAYMENT_VOUCHER}
                </Button>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {renderCardFilterReport()}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Card>
                            <Box component="form">
                                <Grid container spacing={3} p={2}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={13} variant="body2" fontWeight="medium">
                                            {Strings.CASH_BOOK.THIS_RESULT_IS_RECORDED_ON_A_MONTHLY_BASIS}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container xs={12} spacing={3}>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Strings.CASH_BOOK.ENDING_MONEY}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {"="}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Strings.CASH_BOOK.BEGINNING_MONEY}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {"+"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Strings.CASH_BOOK.TOTAL_REVENUE}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {"-"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Strings.CASH_BOOK.TOTAL_EXPENDITURE}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container xs={12} spacing={3}>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Helpers.formatCurrency(model.endMoney || 0)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {"="}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Helpers.formatCurrency(model.startMoney || 0)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {"+"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Helpers.formatCurrency(model.totalReceive || 0)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {"-"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography fontSize={13} variant="body2" fontWeight="regular">
                                                    {Helpers.formatCurrency(model.totalPayment || 0)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Card>
                            <Box p={2}>
                                <Box
                                    justifyContent="flex-start"
                                    display="flex"
                                    sx={{
                                        position: "absolute",
                                        top: 15,
                                        left: 30,
                                    }}
                                >
                                    <Button variant="outlined" onClick={() => handlePrintOrder()}>
                                        <FileDownloadIcon fontSize="small" />
                                        {Strings.Common.PRINT_ALL}
                                    </Button>
                                </Box>
                                {
                                    // dataTable?.rows?.length > 0 &&
                                    <DataTable
                                        isSorted
                                        allowCheckbox
                                        table={dataTable}
                                        totalCount={model.requestData?.totalCount}
                                        pageNumber={model.requestData?.pageNumber || 1}
                                        rowPerPage={model.requestData?.pageSize || Constants.ROW_PER_PAGE}
                                        onChangePageSize={(pageSize) => getData({ ...model.requestData, pageSize })}
                                        onChangePageNumber={(pageNumber) => getData({ ...model.requestData, pageNumber })}
                                        searchText={model.requestData?.searchText}
                                        placeholderSearch={Strings.ORDER.SEARCH_TEXT}
                                        onSearch={(val) => handleConfirmFilter(val)}
                                        onSearchChange={(val) => onValueChange("searchText", val)}
                                        // filterForm={filterForm()}
                                        // onFilter={() => handleConfirmFilter()}
                                        // onReset={onReset}
                                        // onCloseFilter={() => { }}

                                        onSubmitActionCheckedBox={(data, actionType) => {
                                            if (actionType === ActionTypeSelectBox.Print) {
                                                handleOnSubmitActionCheckedBox([...(data || [])]);
                                            }
                                        }}
                                        actionTypeSelectBox={[
                                            { actionType: ActionTypeSelectBox.Print, actionName: Strings.Common.PRINT, color: "info" },
                                        ]}
                                    />
                                }
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </DashboardLayout>
    );
};

export default CashBookScreen;
