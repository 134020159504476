import { Box, Button, DataTable } from "@maysoft/common-component-react";
import { Add } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import AttributeService from "services/sale/attribute.service";
import Constants from "../../constants";

import { AttributeType, Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { RootState } from "store";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setDataAlert } from "store/slice/message.slice";
import { setListPathName } from "store/slice/titleRoute.slice";
import { IRecordAttribute, IRequestGetPagedAttribute } from "./container/interface";
import useAttribute from "./container/useAttribute";
import { ModuleAttribute } from "@maysoft/sale-common-react";
import { ITitleRoute } from "commons/interfaces";

interface IModel {
    mode?: number;
    title?: string;
    route?: ITitleRoute[];
}

const AttributeScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const { dataValueType } = useAttribute();

    // const attributeService = new AttributeService();

    // const userInfo = useSelector((state: RootState) => state.userInfoSlice?.userProfile);
    // const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);

    // const [model, setModel] = useState<IModel>({ languages: "vi" });
    // const [dataTableRows, setDataTableRows] = useState<IRecordAttribute[]>([]);

    // const columns: any[] = [
    //     {
    //         Header: Strings.ATTRIBUTE.NAME,
    //         accessor: "title",
    //         width: "100px",
    //         Cell: (row: any) => <>{row.value?.value?.[model.languages]}</>,
    //     },
    //     {
    //         Header: Strings.ATTRIBUTE.VALUE_TYPE,
    //         accessor: "valueType",
    //         width: "100px",
    //         Cell: ({ value }: any) => <>{dataValueType.find((el) => `${el.code}` === `${value}`)?.name}</>,
    //     },
    //     {
    //         Header: Strings.Common.TYPE,
    //         accessor: "type",
    //         width: "100px",
    //         Cell: ({ value }: any) => {
    //             let text = "";
    //             if (Number(value) === AttributeType.Product) {
    //                 text = Strings.PRODUCT.PRODUCT;
    //             }
    //             if (Number(value) === AttributeType.Common) {
    //                 text = "Common";
    //             }
    //             return <>{text}</>;
    //         },
    //     },
    // ];

    // useEffect(() => {
    //     const itemPathName = listPathName.find((el) => el.pathName === Screens.ATTRIBUTE_LIST);
    //     const { searchText } = Helpers.getUrlParams(["searchText"]);
    //     const { pageSize } = Helpers.getUrlParams(["pageSize"]);
    //     const { pageNumber } = Helpers.getUrlParams(["pageNumber"]);

    //     getPaged({
    //         searchText,
    //         pageNumber: Number(pageNumber || 1),
    //         totalCount: itemPathName?.totalCount || 0,
    //         pageSize: Number(pageSize || Constants.ROW_PER_PAGE),
    //     });
    // }, [userInfo?.organizationId]);

    // const getPaged = async (dataRequest?: IRequestGetPagedAttribute) => {
    //     try {
    //         dispatch(showLoading(true));

    //         const pageSize = dataRequest?.pageSize || Constants.ROW_PER_PAGE;
    //         const pageNumber = Helpers.getPageNumber(dataRequest?.pageNumber || 1, pageSize, dataRequest?.totalCount || 0);
    //         const data: any = {
    //             searchText: dataRequest?.searchText,
    //             organizationId: userInfo.organizationId,
    //             pageSize: pageSize,
    //             pageNumber: pageNumber,
    //             orderby: dataRequest?.orderby,
    //         };

    //         const result = await attributeService.getPaged(data);

    //         let query: string = `?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    //         if (!Helpers.isNullOrEmpty(data.searchText)) {
    //             query += `&searchText=${data.searchText}`;
    //         }

    //         setModel({
    //             ...model,
    //             dataRequest: { ...dataRequest, totalCount: result.totalCount },
    //             dataRequestTemp: { ...dataRequest, totalCount: result.totalCount },
    //         });
    //         setDataTableRows(result.items || []);

    //         navigate(Screens.ATTRIBUTE_LIST + query, { replace: true });

    //         dispatch(setListPathName({ pathname: Screens.ATTRIBUTE_LIST, query, totalCount: result.totalCount }));
    //     } catch (error) {
    //         const e = Helpers.renderExceptionError(error);
    //         dispatch(setDataAlert({ message: e, type: "error" }));
    //     } finally {
    //         dispatch(showLoading(false));
    //     }
    // };

    // const handleDeleteByID = (row: any) => {
    //     Helpers.showConfirmAlert(Strings.Message.CONFIRM_DELETE, async () => {
    //         try {
    //             dispatch(showLoading(true));
    //             await attributeService.delete(row.id);
    //             await getPaged(model.dataRequest);

    //             dispatch(setDataAlert({ message: Strings.Message.DELETE_SUCCESS, type: "success" }));
    //             dispatch(showLoading(false));
    //         } catch (error) {
    //             dispatch(showLoading(false));
    //             const e = Helpers.renderExceptionError(error);
    //             dispatch(setDataAlert({ message: e, type: "error" }));
    //         }
    //     });
    // };

    // 
    const [searchParams] = useSearchParams();

    const idDetail = searchParams.get("id");
    const modeList = (Screens.ATTRIBUTE_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemPathName = listPathName.find(el => el.pathName === Screens.ATTRIBUTE_LIST);

    const [request] = useState<any>({
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const [model, setModel] = useState<IModel>({} as IModel);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            handleChangeMode(Mode.Create);
        } else {
            let mode = (pramsMode || Mode.Update);
            handleChangeMode(mode);
        }
    }, [idDetail, pramsMode]);

    const handleGoBack = () => {
        const itemP = listPathName.find(el => el.pathName === Screens.ATTRIBUTE_LIST);
        navigate(Screens.ATTRIBUTE_LIST + (itemP ? itemP.query : ""));
    }

    const handleChangeMode = (value: Mode) => {
        let title: string = "";
        let route = [{ title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST }];

        if (value === Mode.Create) {
            title = Strings.ATTRIBUTE.TITLE_CREATE_VIEW;
            route = [
                { title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST },
                { title: Strings.Common.CREATE_NEW, route: "" }
            ];
        }
        if (value === Mode.Update) {
            title = Strings.ATTRIBUTE.TITLE_UPDATE_VIEW;
            route = [
                { title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST },
                { title: Strings.Common.UPDATE, route: "" }
            ];
        }
        if (value === Mode.View) {
            title = Strings.ATTRIBUTE.TITLE_DETAIL_VIEW;
            route = [
                { title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST },
                { title: Strings.Common.VIEW, route: "" }
            ];
        }

        setModel({
            ...model,
            mode: value,
            title: title,
            route: route,
        });

        if (!Helpers.isNullOrEmpty(idDetail)) {
            navigate(Screens.ATTRIBUTE_EDIT + `?id=${idDetail}&mode=${value}`, { replace: true });
        }
    }

    return (
        <DashboardLayout
            isPermission
            // title={Strings.ATTRIBUTE.TITLE_LIST_VIEW}
            // route={[{ title: Strings.ATTRIBUTE.TITLE_MENU, route: "" }]}
            title={modeList ? Strings.ATTRIBUTE.TITLE_LIST_VIEW : model.title}
            route={modeList ? [{ title: Strings.ATTRIBUTE.TITLE_MENU, route: "" }] : model.route}
        >
            {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="end" mb={2}>
                        <Button
                            onClick={() => {
                                navigate(Screens.ATTRIBUTE_EDIT, { state: { mode: Mode.Create } });
                            }}
                        >
                            <Add />
                            &nbsp;{Strings.Common.ADD_NEW}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                table={{ columns, rows: dataTableRows }}
                                totalCount={model.dataRequest?.totalCount || 0}
                                pageNumber={model.dataRequest?.pageNumber || 1}
                                rowPerPage={model.dataRequest?.pageSize || Constants.ROW_PER_PAGE}
                                onChangePageSize={(pageSize) => getPaged({ ...model.dataRequest, pageSize })}
                                onChangePageNumber={(pageNumber) => getPaged({ ...model.dataRequest, pageNumber })}
                                searchText={model.dataRequestTemp?.searchText}
                                placeholderSearch={Strings.ATTRIBUTE.SEARCH_TEXT}
                                onSearch={(val: string) => getPaged({ ...model.dataRequestTemp, pageNumber: 1, searchText: val })}
                                onSearchChange={(val: string) =>
                                    setModel({ ...model, dataRequestTemp: { ...model.dataRequestTemp, searchText: val } })
                                }
                                actionList={(row: any) => {
                                    return [
                                        {
                                            key: "detail",
                                            iconName: "view",
                                            title: Strings.Common.VIEW,
                                            callback: (row: any) =>
                                                navigate(Screens.ATTRIBUTE_EDIT + `?id=${row.id}`, { state: { mode: Mode.View } }),
                                        },
                                        {
                                            key: "edit",
                                            iconName: "edit",
                                            title: Strings.Common.EDIT,
                                            callback: (row: any) =>
                                                navigate(Screens.ATTRIBUTE_EDIT + `?id=${row.id}`, { state: { mode: Mode.Update } }),
                                        },
                                        {
                                            key: "delete",
                                            iconName: "delete",
                                            title: Strings.Common.DELETE,
                                            callback: (row: any) => handleDeleteByID(row),
                                        },
                                    ];
                                }}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid> */}
            <ModuleAttribute
                keyModuleContainer={{
                    list: { pathName: Screens.ATTRIBUTE_LIST },
                    detail: { pathName: Screens.ATTRIBUTE_EDIT },
                    create: { pathName: Screens.ATTRIBUTE_EDIT },
                }}

                requestGetPaged={request}
                onNavigate={(data) => {
                    if (data.id) {
                        navigate(Screens.ATTRIBUTE_EDIT + `?id=${data.id}&mode=${data.mode}`);
                    } else {
                        navigate(Screens.ATTRIBUTE_EDIT);
                    }
                }}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.ATTRIBUTE_LIST, query, totalCount }));
                    navigate(Screens.ATTRIBUTE_LIST + query, { replace: true });
                }}

                mode={model.mode}
                idDetail={idDetail}
                onGoBack={handleGoBack}
                onChangeMode={(value) => { handleChangeMode(value); }}
            />
        </DashboardLayout>
    );
};

export default AttributeScreen;
