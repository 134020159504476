import { Box, Button, DataTableHeadCell, FormField, Radio, SelectMultiLanguage, TitleValue, Typography } from "@maysoft/common-component-react";
import { Add } from "@mui/icons-material";
import { FormControl, FormControlLabel, Grid, RadioGroup, Table, TableBody, TableCell, TableRow } from "@mui/material";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import RenderItemAttributeValue from "./itemAttributeValue";

import { ICodename } from "commons/interfaces";
import { ActionRequest, AttributeValueType, Mode } from "constants/enum";
import { IAttributeValue, IDataEditAttribute } from "./interface";

interface IProps {
    mode?: Mode;
    isModal?: boolean;
    languages: string;
    isAdminService: boolean;
    dataValueType: ICodename[];
    organizationList: ICodename[];
    dataDetailAttribute: IDataEditAttribute;

    setLanguages: (value: React.SetStateAction<string>) => void;
    onChangeDataDetail: (value: string, key: keyof IDataEditAttribute) => void;

    onChangeValueType: (value: any) => void;
    handleAddNewItemAttributeValue: () => void;
    handleDeleteItemAttributeValue: (row: IAttributeValue) => void;
    handleOnchangeValueItem: (newValue: any, key: string, idItem: string) => void;
}
const BasicInfoAttribute = ({
    mode,
    isModal,
    languages,
    isAdminService,
    dataValueType,
    organizationList,
    dataDetailAttribute,
    setLanguages,
    onChangeDataDetail,
    onChangeValueType,
    handleAddNewItemAttributeValue,
    handleDeleteItemAttributeValue,
    handleOnchangeValueItem,
}: IProps) => {
    return (
        <>
            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent={isModal ? "start" : "space-between"}>
                {!isModal && <Typography variant="h5">{Strings.ATTRIBUTE.TITLE_DETAIL_VIEW}</Typography>}
                <SelectMultiLanguage defaultLanguage={languages} onChangeLanguage={setLanguages} />
            </Box>
            <Grid container spacing={3} p={2}>
                <Grid item xs={12} lg={isModal ? 12 : 6}>
                    <FormField
                        required
                        mode={mode}
                        label={Strings.ATTRIBUTE.NAME}
                        placeholder={Strings.ATTRIBUTE.INPUT_NAME}
                        errorMessage={dataDetailAttribute?.title?.error}
                        defaultValue={Helpers.getDefaultValueMultiLanguage(dataDetailAttribute?.title, languages) || ""}
                        onBlur={(value) => onChangeDataDetail(value, "title")}
                    />
                </Grid>

                <Grid item xs={12} lg={isModal ? 12 : 6}>
                    {mode === Mode.View ? (
                        <TitleValue
                            title={Strings.ATTRIBUTE.VALUE_TYPE}
                            value={dataValueType.find((el) => el.code === dataDetailAttribute?.valueType?.value)?.name}
                        />
                    ) : (
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Typography variant="caption" color="secondary" fontWeight="regular">
                                {Strings.ATTRIBUTE.VALUE_TYPE}:
                            </Typography>
                            &emsp;
                            <FormControl disabled={!Helpers.isNullOrEmpty(dataDetailAttribute?.id)}>
                                <RadioGroup
                                    row
                                    value={dataDetailAttribute?.valueType?.value || dataValueType[0].code}
                                    onChange={(event: any, value) => {
                                        onChangeValueType(value);
                                    }}
                                >
                                    {dataValueType.map((item) => (
                                        <FormControlLabel key={item.code} value={item.code} label={item.name} control={<Radio />} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    )}
                </Grid>

                {dataDetailAttribute?.valueType?.value !== String(AttributeValueType.Upload) && (
                    <>
                        {/* Button Add New Attribute */}
                        <Grid item xs={12}>
                            <Box flexWrap="wrap" display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6">{"Danh sách giá trị thuộc tính"}</Typography>
                                {mode !== Mode.View && (
                                    <Button onClick={handleAddNewItemAttributeValue}>
                                        <Add />
                                        &nbsp;{Strings.Common.ADD_NEW}
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                        {/* list attributeValues */}
                        <Grid item xs={12} lg={12}>
                            {isModal ? (
                                <div
                                    style={{
                                        overflow: "auto",
                                        maxHeight: "135px",
                                    }}
                                >
                                    {[...(dataDetailAttribute?.attributeValues || [])]
                                        .filter((el) => el.action !== ActionRequest.Delete)
                                        .map((item, index) => (
                                            <RenderItemAttributeValue
                                                index={index}
                                                itemValue={item}
                                                mode={mode}
                                                isModal={isModal}
                                                languages={languages}
                                                dataValueType={dataValueType}
                                                isAdminService={isAdminService}
                                                organizationList={organizationList}
                                                dataDetailAttribute={dataDetailAttribute}
                                                handleOnchangeValueItem={handleOnchangeValueItem}
                                                handleDeleteItemAttributeValue={handleDeleteItemAttributeValue}
                                            />
                                        ))}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        padding: 2,
                                        overflow: "auto",
                                        borderTopLeftRadius: "0.75rem",
                                        borderTopRightRadius: "0.75rem",
                                    }}
                                >
                                    <Table>
                                        <Box component="thead">
                                            <TableRow>
                                                <TableCell style={{ width: "30px", backgroundColor: "#e5e7eb" }}>
                                                    <Typography
                                                        sx={{
                                                            opacity: 0.7,
                                                            fontWeight: 700,
                                                            color: "#7b809a",
                                                            textAlign: "left",
                                                            boxShadow: "none",
                                                            fontSize: "0.65rem",
                                                            position: "relative",
                                                            borderRadius: "none",
                                                            textTransform: "uppercase",
                                                        }}
                                                    >
                                                        {"STT"}
                                                    </Typography>
                                                </TableCell>
                                                {mode !== Mode.View && (
                                                    <TableCell style={{ width: "100px", backgroundColor: "#e5e7eb" }}>
                                                        <Typography
                                                            sx={{
                                                                opacity: 0.7,
                                                                fontWeight: 700,
                                                                color: "#7b809a",
                                                                textAlign: "left",
                                                                boxShadow: "none",
                                                                fontSize: "0.65rem",
                                                                position: "relative",
                                                                borderRadius: "none",
                                                                textTransform: "uppercase",
                                                            }}
                                                        >
                                                            {Strings.Common.ACTION}
                                                        </Typography>
                                                    </TableCell>
                                                )}
                                                <DataTableHeadCell width={200} sorted={false} align="left">
                                                    {Strings.ATTRIBUTE.VALUE}
                                                </DataTableHeadCell>
                                                {/* {propBasic?.isAdminService &&
                                                    AttributeType.Common === Number(propBasic?.dataDetailAttribute?.type?.value) && (
                                                        <DataTableHeadCell width={90} sorted={false} align="left">
                                                            {Strings.Common.USE_ALL_SYSTEM}
                                                        </DataTableHeadCell>
                                                    )}
                                                {propBasic?.isAdminService &&
                                                    AttributeType.Common === Number(propBasic?.dataDetailAttribute?.type?.value) && (
                                                        <DataTableHeadCell width={200} sorted={false} align="left">
                                                            {Strings.PRODUCT_TEMPLATE.ORGANIZATION}
                                                        </DataTableHeadCell>
                                                    )} */}
                                            </TableRow>
                                        </Box>
                                        <TableBody>
                                            {[...(dataDetailAttribute?.attributeValues || [])].length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={4} sx={{ borderBottom: "unset" }}>
                                                        <Box textAlign="center">
                                                            <Typography variant="button" color="secondary" fontWeight="regular">
                                                                {Strings.Common.NO_DATA}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                [...(dataDetailAttribute?.attributeValues || [])]
                                                    .filter((el) => el.action !== ActionRequest.Delete)
                                                    .map((item, index) => (
                                                        <RenderItemAttributeValue
                                                            index={index}
                                                            itemValue={item}
                                                            mode={mode}
                                                            isModal={isModal}
                                                            languages={languages}
                                                            dataValueType={dataValueType}
                                                            isAdminService={isAdminService}
                                                            organizationList={organizationList}
                                                            dataDetailAttribute={dataDetailAttribute}
                                                            handleOnchangeValueItem={handleOnchangeValueItem}
                                                            handleDeleteItemAttributeValue={handleDeleteItemAttributeValue}
                                                        />
                                                    ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

export default BasicInfoAttribute;
