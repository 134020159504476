import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface SettingCodeState {
    data: { key: string, value: any }[];
}

const initialState: SettingCodeState = {
    data: [],
}

export const settingCodeSlice = createSlice({
    name: "settingCode",
    initialState,
    reducers: {
        setItemSettingValue: (state, action: PayloadAction<{ key: string, value: any }>) => {
            const dataTemp = [...state.data || []];
            const index = dataTemp.findIndex(el => el.key === action.payload.key);
            if (index === -1) {
                dataTemp.push(action.payload);
            } else {
                dataTemp[index] = action.payload;
            }
            state.data = dataTemp;
        },
        resetDataSettingVaue: (state) => {
            state.data = [];
        },
    }
})

// Action creators are generated for each case reducer function
export const { setItemSettingValue, resetDataSettingVaue } = settingCodeSlice.actions

export default settingCodeSlice.reducer