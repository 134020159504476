import moment from "moment";
import printJS from "print-js";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, Grid, Step, StepLabel, Stepper } from "@mui/material";

import Helpers from "commons/helpers";
import Constants from "../../constants";
import Strings from "constants/strings";
import Screens from "constants/screens";
import ItemService from "services/sale/item.service";
import UserService from "services/identity/user.service";
import PartnerService from "services/sale/partner.service";
import CashBookService from "services/sale/cashBook.service";
import { AutocompleteLoadMore } from "components";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, IInput, ITitleRoute } from "commons/interfaces";
import { FormField, Autocomplete, Box, Button, Typography, DatePicker, Switch } from "@maysoft/common-component-react";
import {
    CashBookType,
    IsCustomerOrSupplier,
    Mode,
    PartnerType,
    PaymentType,
    PaymentVoucherType,
    RevenueRecognition,
    UserGroup,
    VoucherHistoryStatus,
} from "constants/enum";

interface IDataEdit {
    isEdit?: boolean;

    id?: string;
    groupId?: IInput;
    tenantCode?: string;
    organizationId?: string;
    type?: IInput;
    voucherStatus?: IInput;
    receiver?: IInput;
    paymentDate?: IInput;
    amount?: IInput;
    paymentMethod?: IInput;
    description?: IInput;
    revenueRecognition?: IInput;
    referenceCode?: IInput;
    paymentAccountRequest?: {
        id?: string;
        accountName?: IInput;
        accountId?: IInput;
        bankCode?: IInput;
        isDefault?: IInput;
    };
    updateTime?: any;
    userGroup?: IInput;
    name?: IInput;
    voucherCode?: string;
    currency?: IInput;
}

interface IModel {
    mode?: Mode;
    title?: string;
    route?: ITitleRoute[];
    partnerType?: number[];
}

const PaymentTicketScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const cashBookService = new CashBookService();
    const userService = new UserService();
    const itemService = new ItemService();
    const partnerService = new PartnerService();

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const userProfile = useSelector((state: RootState) => state.userInfoSlice?.userProfile);
    const organizationId = userProfile?.organizationId;

    const [model, setModel] = useState<IModel>({} as IModel);
    const [dataEdit, setDataEdit] = useState<IDataEdit>({});
    const [modelAddCustomer, setModelAddCustomer] = useState<boolean>(false);
    const [listReceiver, setListReceiver] = useState<ICodename[]>([]);
    const [dataCurrency, setDataCurrency] = useState<ICodename[]>([]);

    const [histories, setHistories] = useState<
        {
            id?: string;
            createTime?: string;
            createUser?: string;
            createUserName?: string;
            type?: string;
            voucherId?: string;
            voucherStatus?: string;
            status?: string;
            note?: string;
            updateTime?: string;
            updateUser?: string;
        }[]
    >([]);

    const [listType, setListType] = useState<ICodename[]>([
        { code: `${PaymentVoucherType.SelfCreated}`, name: Strings.PAYMENT_TICKET.SELF_CREATED },
        // { code: `${PaymentVoucherType.Automatic}`, name: Strings.PAYMENT_TICKET.AUTOMATIC },
        // { code: `${PaymentVoucherType.CustomerDeposit}`, name: Strings.PAYMENT_TICKET.CUSTOMER_DEPOSIT },
        // { code: `${PaymentVoucherType.CollectDebtFromShippingParners}`, name: Strings.PAYMENT_TICKET.COLLECT_DEBT_FROM_SHIPPING_PARNERS },
    ]);
    const listPaymentMethod: ICodename[] = [
        { code: `${PaymentType.Cash}`, name: Strings.ORDER.CASH },
        { code: `${PaymentType.Banking}`, name: Strings.ORDER.BANKING },
        { code: `${PaymentType.Paypal}`, name: Strings.ORDER.PAYPAL },
        { code: `${PaymentType.VnPay}`, name: Strings.ORDER.VNPAY },
    ];
    const recipientGroup: ICodename[] = [
        { code: `${IsCustomerOrSupplier.Staff}`, name: Strings.PAYMENT_TICKET.STAFF },
        { code: `${IsCustomerOrSupplier.Supplier}`, name: Strings.PAYMENT_TICKET.SUPPLIER },
    ];
    const voucherStatus: ICodename[] = [
        { code: `${VoucherHistoryStatus.Cancel}`, name: Strings.Common.CANCELED, group: "error" },
        { code: `${VoucherHistoryStatus.Completed}`, name: Strings.Common.COMPLETE, group: "success" },
        { code: `${VoucherHistoryStatus.Pending}`, name: Strings.Common.PROCESSING, group: "warning" },
        { code: `${VoucherHistoryStatus.Updated}`, name: Strings.PAYMENT_TICKET.UPDATED, group: "warning" },
        { code: `${VoucherHistoryStatus.Commit}`, name: Strings.PAYMENT_TICKET.COMMIT, group: "warning" },
        { code: `${VoucherHistoryStatus.CreateAuto}`, name: Strings.PAYMENT_TICKET.CREATE_AUTO, group: "warning" },
    ];

    const idPayment = searchParams.get("id");

    useEffect(() => {
        onStart();
    }, [userProfile?.organizationId]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.CASH_BOOK);
        navigate(Screens.CASH_BOOK + (itemPathName ? itemPathName.query : ""));
    };

    const onStart = async () => {
        try {
            dispatch(showLoading(true));
            await getSupportCurrency();
            await getDataReceive({ pageNumber: 1, searchText: undefined });
            let dataPayment: IDataEdit = {
                userGroup: { value: `${IsCustomerOrSupplier.Staff}` },
                revenueRecognition: { value: RevenueRecognition.True },
                paymentMethod: { value: `${PaymentType.Cash}` },
                paymentDate: { value: moment().unix() },
                type: { value: `${PaymentVoucherType.SelfCreated}` },
                currency: { value: Constants.CURRENCY_DEFAULT },
            };

            let mode = idPayment ? Mode.View : Mode.Create;
            let title = idPayment ? Strings.PAYMENT_TICKET.TITLE_DETAIL : Strings.PAYMENT_TICKET.TITLE_CREATE;
            let route = [
                { title: Strings.CASH_BOOK.TITLE, route: Screens.CASH_BOOK },
                { title: idPayment ? Strings.Common.DETAIL : Strings.Common.CREATE_NEW, route: "" },
            ];

            if (idPayment) {
                let listTypeTemp = listType || [];
                listTypeTemp.push({ code: `${PaymentVoucherType.Automatic}`, name: Strings.PAYMENT_TICKET.AUTOMATIC });
                setListType(listTypeTemp);
                dataPayment = await getDetailPayment(idPayment);
            }
            dataPayment.isEdit = false;
            setDataEdit(dataPayment);
            // setListReceiver(receiversTemp);
            setModel({ ...model, mode, title, route });

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getSupportCurrency = async () => {
        try {
            const result = await itemService.getSupportCurrency();
            const dataResult: ICodename[] = [];
            (result || []).forEach((item: any) => {
                dataResult.push({
                    code: item,
                    name: item,
                });
            });
            setDataCurrency(dataResult);
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getDetailPayment = async (id: string) => {
        try {
            dispatch(showLoading(true));
            const result = await cashBookService.getDetailPayment(id);
            setHistories(result.voucherHistoryResponse || []);

            let userGroup: any;
            switch (Number(result.partnerType)) {
                case PartnerType.CompanySupplier:
                case PartnerType.IndividualSupplier:
                    userGroup = `${UserGroup.Supplier}`;
                    break;
                case PartnerType.Staff:
                    userGroup = `${IsCustomerOrSupplier.Staff}`;
                    break;
                default:
                    break;
            }

            return {
                id: result.id,
                groupId: { value: result.groupId },
                organizationId,
                type: { value: String(result.type) },
                voucherStatus: { value: result.voucherStatus },
                receiver: { value: result.receiver },
                paymentDate: { value: result.paymentDate },
                amount: { value: result.amount },
                paymentMethod: { value: String(result.paymentMethod) },
                description: { value: result.description },
                revenueRecognition: { value: result.revenueRecognition },
                referenceCode: { value: result.referenceCode },
                userGroup: { value: userGroup },
                name: { value: result.partnerName },
                paymentAccountRequest: {
                    id: result.paymentAccountResponse?.id,
                    accountName: { value: result.paymentAccountResponse?.accountName },
                    accountId: { value: result.paymentAccountResponse?.accountId },
                    bankCode: { value: result.paymentAccountResponse?.bankCode },
                    isDefault: { value: result.paymentAccountResponse?.isDefault },
                },
                updateTime: result.updateTime,
                voucherCode: result.voucherCode,
                currency: { value: result.currency },
            };
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onValueChange = async (key: string, val: any, isPaymentAcc?: boolean) => {
        let data: any = { ...dataEdit };
        let partnerType: number[] = [];
        if (isPaymentAcc) {
            let payment: any = { ...dataEdit?.paymentAccountRequest };
            payment[key] = { value: val };
            data["paymentAccountRequest"] = payment;
        } else {
            data[key] = { value: val };
            if (key === "revenueRecognition") {
                data[key] = { value: val === true ? RevenueRecognition.True : RevenueRecognition.False };
            }
            if (key === "paymentMethod") {
                data["paymentAccountRequest"] = {};
            }
            if (key === "receiver") {
                data["receiver"] = { value: val };
                // data["name"] = { value: val?.name };
            }
            if (key === "userGroup") {
                data["receiver"] = { value: undefined };
                // data["name"] = { value: "" };
                setListReceiver([]);
                switch (Number(val)) {
                    // case PartnerType.Staff:
                    //     partnerType = [PartnerType.Staff]
                    //     break;
                    case UserGroup.Supplier:
                        partnerType = [PartnerType.IndividualSupplier, PartnerType.CompanySupplier];
                        break;
                }
            }
        }
        data.isEdit = true;
        setDataEdit(data);
        setModel({ ...model, partnerType });
    };

    const checkValidate = () => {
        let checked = true;
        let data: any = { ...dataEdit };
        const listKey: string[] = ["userGroup", "revenueRecognition", "type", "receiver", "amount", "currency"];

        listKey.forEach((key) => {
            if (Helpers.isNullOrEmpty(data[key]?.value)) {
                data[key] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
            if (!Helpers.isNullOrEmpty(data[key]?.error)) {
                checked = false;
            }
        });

        if (PaymentType.Banking === Number(dataEdit?.paymentMethod?.value)) {
            let paymentAccountTemp = { ...dataEdit.paymentAccountRequest };
            if (Helpers.isNullOrEmpty(paymentAccountTemp["accountId"]?.value)) {
                paymentAccountTemp["accountId"] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
            if (Helpers.isNullOrEmpty(paymentAccountTemp["bankCode"]?.value)) {
                paymentAccountTemp["bankCode"] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
            data["paymentAccountRequest"] = paymentAccountTemp;
        }

        if (!checked) {
            setDataEdit(data);
        }
        return checked;
    };

    const onSubmit = async (isSaveTemp: boolean, isPrint?: boolean) => {
        if (!checkValidate()) {
            return;
        }
        try {
            dispatch(showLoading(true));

            const dataSubmit: any = {
                type: Number(dataEdit?.type?.value),
                voucherStatus: dataEdit?.voucherStatus?.value,
                tenantCode: Constants.TENANT_CODE,
                organizationId,
                groupId: dataEdit.groupId?.value,
                receiver: dataEdit?.receiver?.value,
                paymentDate: dataEdit?.paymentDate?.value,
                amount: dataEdit?.amount?.value,
                paymentMethod: Number(dataEdit?.paymentMethod?.value),
                description: dataEdit?.description?.value,
                revenueRecognition: Number(dataEdit?.revenueRecognition?.value),
                referenceCode: dataEdit?.referenceCode?.value,
                paymentAccountRequest: {
                    id: dataEdit?.paymentAccountRequest?.id,
                    accountName: dataEdit?.paymentAccountRequest?.accountName?.value,
                    accountId: dataEdit?.paymentAccountRequest?.accountId?.value,
                    bankCode: dataEdit?.paymentAccountRequest?.bankCode?.value,
                    isDefault: dataEdit?.paymentAccountRequest?.isDefault?.value,
                },
                currency: dataEdit.currency.value,
            };
            if (dataEdit.id) {
                try {
                    dispatch(showLoading(true));
                    const result = await cashBookService.updatePayment({
                        ...dataSubmit,
                        id: dataEdit?.id,
                        // updateTime: dataEdit.updateTime,
                        voucherStatus: dataEdit?.voucherStatus?.value,
                    });
                    if (result.statusCode === Constants.ApiCode.SUCCESS) {
                        const data = await getDetailPayment(dataEdit.id);
                        setDataEdit({ ...data, isEdit: false });
                        dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
                    }
                    dispatch(showLoading(false));
                } catch (error) {
                    dispatch(showLoading(false));
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            } else {
                Helpers.showConfirmAlert(Strings.PAYMENT_TICKET.CONFIRM_COMPLETE, async () => {
                    try {
                        dispatch(showLoading(true));
                        if (isSaveTemp) dataSubmit.voucherStatus = VoucherHistoryStatus.Pending;
                        else dataSubmit.voucherStatus = VoucherHistoryStatus.Completed;
                        const result = await cashBookService.createPayment(dataSubmit);
                        if (isPrint) {
                            setDataEdit({ ...dataEdit, isEdit: false });
                            await handlePrintOrder(result.result.id);
                        } else {
                            dispatch(setDataAlert({ message: Strings.Message.CREATE_SUCCESS, type: "success" }));
                            handleGoBack();
                        }
                        dispatch(showLoading(false));
                    } catch (error) {
                        dispatch(showLoading(false));
                        const e = Helpers.renderExceptionError(error);
                        dispatch(setDataAlert({ message: e, type: "error" }));
                    }
                });
            }

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onComplete = () => {
        Helpers.showConfirmAlert(Strings.PAYMENT_TICKET.CONFIRM_COMPLETE, async () => {
            try {
                dispatch(showLoading(true));
                const result = await cashBookService.completePayment(dataEdit?.id);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    const data = await getDetailPayment(dataEdit.id);
                    setDataEdit({ ...data, isEdit: false });
                    dispatch(setDataAlert({ message: Strings.Message.SUCCESS, type: "success" }));
                }
                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    const onCancel = () => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_CANCEL, async () => {
            try {
                dispatch(showLoading(true));
                const result = await cashBookService.cancelPayment(dataEdit?.id);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    const data = await getDetailPayment(dataEdit.id);
                    setDataEdit({ ...data, isEdit: false });
                    dispatch(setDataAlert({ message: Strings.Message.CANCEL_SUCCESS, type: "success" }));
                }
                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    const handlePrintOrder = async (id: string) => {
        try {
            dispatch(showLoading(true));
            let data = {
                organizationId: organizationId,
                groupId: dataEdit.groupId?.value,
                fromDate: moment().unix(),
                toDate: moment().unix(),
                cashBookType: CashBookType.Payment,
                ids: [id],
            };
            const result = await cashBookService.export(Helpers.handleFormatParams(data));
            Helpers.getBase64(result, (data) =>
                printJS({
                    printable: data,
                    type: "pdf",
                    base64: true,
                    onPrintDialogClose: () => handleGoBack(),
                })
            );
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getDataReceive = async (data: any) => {
        let result: any;
        let resultData: ICodename[] = [];
        let request = {
            organizationId: userProfile?.organizationId || undefined,
            // groupId: dataEdit?.referenceCode?.value ? dataEdit?.referenceCode?.value : undefined,
            partnerType: model.partnerType || undefined,
            pageNumber: data?.pageNumber,
            searchText: data?.searchText,
            pageSize: Constants.ROW_PER_PAGE_10,
        };
        if (Number(dataEdit.userGroup?.value) === UserGroup.Supplier) {
            //getPartner
            result = await partnerService.getPaged(
                Helpers.handleFormatParams({
                    ...request,
                })
            );

            (result.items || []).forEach((item: any) => {
                resultData.push({ code: item.id, name: `${item.partnerName} (${item.phoneNumber})` });
            });
        } else {
            //getUser
            result = await userService.getPaged(Helpers.handleFormatParams(request));
            result?.items?.forEach((e: any) =>
                resultData.push({
                    code: e.id,
                    name: e.organizationUserProfile?.firstName || "",
                })
            );
        }
        setListReceiver(resultData);
    };

    return (
        <DashboardLayout
            isPermission
            title={model.title}
            route={model.route}
            isRequiredOrganization
            nameActionPress={model.mode === Mode.Create ? Strings.Common.SAVE : Strings.Common.EDIT}
            onBackPress={() => {
                if (dataEdit.isEdit) {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                } else {
                    handleGoBack();
                }
            }}
            onActionPress={
                // model.mode === Mode.Create ||
                Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                    ? undefined
                    : () => onSubmit(false)
            }
            onEditPress={
                Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                    ? undefined
                    : () => {
                          Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Pending ||
                          Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Updated
                              ? onComplete()
                              : onSubmit(true);
                      }
            }
            nameOtherPress={
                Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed
                    ? undefined
                    : Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Pending ||
                      Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Updated
                    ? Strings.Common.COMPLETE
                    : Strings.Common.SAVE_TEMP
            }
            onCancelPress={
                Number(dataEdit.voucherStatus?.value) === VoucherHistoryStatus.Pending ||
                Number(dataEdit.voucherStatus?.value) === VoucherHistoryStatus.Updated
                    ? onCancel
                    : undefined
            }
            colorOtherPress="info"
            onSaveAndPrintPress={!Helpers.isNullOrEmpty(idPayment) ? undefined : async () => await onSubmit(false, true)}
            nameSaveAndPrintPress={Strings.Common.SAVE_AND_PRINT}
        >
            <Grid container spacing={3} mt={2}>
                <Grid item xs={12} md={dataEdit?.id ? 9 : 12}>
                    <Box>
                        <Card>
                            <Box p={2}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6">{Strings.PAYMENT_TICKET.BACSIC_INFO}</Typography>
                                    {dataEdit.id && (
                                        <Box pl={3}>
                                            <Button
                                                style={{ cursor: "default" }}
                                                disabled={model.mode === Mode.View}
                                                // color={`${
                                                //     voucherStatus.find(
                                                //         (el) => el.code === `${dataEdit.voucherStatus?.value}`
                                                //     )?.group
                                                // }`}
                                            >
                                                {voucherStatus.find((el) => el.code === `${dataEdit.voucherStatus?.value}`)?.name}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                {model.mode === Mode.View && (
                                    <Box sx={{ px: 2, py: 1 }}>
                                        <Typography variant="h6">
                                            {Strings.PAYMENT_TICKET.CODE} : {dataEdit?.voucherCode}
                                        </Typography>
                                    </Box>
                                )}
                                <Box component="form">
                                    <Grid container spacing={3} p={2}>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                required
                                                data={recipientGroup || []}
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.PAYMENT_TICKET.RECIPIENT_GROUP}
                                                placeholder={Strings.PAYMENT_TICKET.SELECT_RECIPIENT_GROUP}
                                                defaultValue={dataEdit.userGroup?.value}
                                                errorMessage={dataEdit?.userGroup?.error}
                                                onChange={(value) => {
                                                    onValueChange("userGroup", value);
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} md={6}>
                                            <FormField
                                                disabled
                                                value={dataEdit.referenceCode?.value || ""}
                                                errorMessage={dataEdit.referenceCode?.error}
                                                label={Strings.PAYMENT_TICKET.CODE_TICKET}
                                                placeholder={Strings.PAYMENT_TICKET.ENTER_CODE_TICKET}
                                                onChangeValue={(value) => {
                                                    onValueChange("referenceCode", value);
                                                }}
                                            />
                                        </Grid> */}

                                        <Grid item xs={12} md={6}>
                                            <AutocompleteLoadMore
                                                disabled={
                                                    !Helpers.isNullOrEmpty(dataEdit?.userGroup?.value) &&
                                                    (Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                        Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel)
                                                        ? true
                                                        : false
                                                }
                                                key={dataEdit.receiver?.value}
                                                dataOptions={listReceiver || []}
                                                errorMessage={dataEdit.receiver?.error}
                                                defaultValue={dataEdit.receiver?.value || ""}
                                                label={Strings.PAYMENT_TICKET.RECIPIENT_NAME}
                                                placeholder={Strings.PAYMENT_TICKET.ENTER_RECIPIENT_NAME}
                                                onChange={(value) => {
                                                    onValueChange("receiver", value);
                                                }}
                                                allowLoadMore
                                                onGetAPI={(data) => getDataReceive(data)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                required
                                                data={listType || []}
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.PAYMENT_TICKET.TYPE_PAYMENT}
                                                placeholder={Strings.PAYMENT_TICKET.SELECT_TYPE_PAYMENT}
                                                defaultValue={dataEdit.type?.value}
                                                errorMessage={dataEdit?.type?.error}
                                                onChange={(value) => {
                                                    onValueChange("type", value);
                                                }}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                required
                                                data={listOrganization || []}
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) ===
                                                        VoucherHistoryStatus.Completed ||
                                                        Number(dataEdit?.voucherStatus?.value) ===
                                                        VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.PRODUCT_TEMPLATE.ORGANIZATION}
                                                placeholder={Strings.PRODUCT_TEMPLATE.SELECT_ORGANIZATION}
                                                defaultValue={dataEdit.groupId?.value}
                                                errorMessage={dataEdit?.groupId?.error}
                                                onChange={(value) => {
                                                    onValueChange("groupId", value);
                                                }}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} md={6}>
                                            <DatePicker
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                value={Helpers.getDateValue(dataEdit?.paymentDate?.value)}
                                                label={Strings.PAYMENT_TICKET.INVOICE_DATE}
                                                placeholder={Strings.PAYMENT_TICKET.SELECT_INVOICE_DATE}
                                                onChangeValue={(value: any) => onValueChange("paymentDate", value ? moment(value).unix() : undefined)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box mt={3}>
                        <Card>
                            <Box p={2}>
                                <Box>
                                    <Typography variant="h6">{Strings.PAYMENT_TICKET.RECOGNIZED_VALUE}</Typography>
                                </Box>
                                <Box component="form">
                                    <Grid container spacing={3} p={2}>
                                        <Grid item xs={3}>
                                            <FormField
                                                isMoney
                                                required
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                value={dataEdit.amount?.value || ""}
                                                errorMessage={dataEdit.amount?.error}
                                                label={Strings.PAYMENT_TICKET.MONEY}
                                                placeholder={Strings.PAYMENT_TICKET.ENTER_MONEY}
                                                onChangeValue={(value) => {
                                                    onValueChange("amount", value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                required
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.PRODUCT.VALUE}
                                                placeholder={Strings.PRODUCT.INPUT_VALUE}
                                                data={dataCurrency || []}
                                                defaultValue={dataEdit.currency?.value}
                                                errorMessage={dataEdit.currency?.error}
                                                onChange={(value) => onValueChange("currency", value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                data={listPaymentMethod || []}
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                defaultValue={dataEdit.paymentMethod?.value}
                                                errorMessage={dataEdit?.paymentMethod?.error}
                                                label={Strings.PAYMENT_TICKET.PAYMENT_METHODS}
                                                placeholder={Strings.PAYMENT_TICKET.SELECT_PAYMENT_METHODS}
                                                onChange={(value) => {
                                                    onValueChange("paymentMethod", value);
                                                }}
                                            />
                                        </Grid>

                                        {Number(dataEdit.paymentMethod?.value) === PaymentType.Banking && (
                                            <Grid item xs={12} md={6}>
                                                <FormField
                                                    disabled={
                                                        Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ? true : false
                                                    }
                                                    label={Strings.PAYMENT_TICKET.ACCOUNT_GET}
                                                    placeholder={Strings.PAYMENT_TICKET.ENTER_ACCOUNT_GET}
                                                    value={dataEdit.paymentAccountRequest?.accountId?.value || ""}
                                                    errorMessage={dataEdit.paymentAccountRequest?.accountId?.error}
                                                    onChangeValue={(value) => {
                                                        onValueChange("accountId", value, true);
                                                    }}
                                                    // required={
                                                    //     `${PaymentType.CreditCard}` === dataEdit.paymentMethod?.value ||
                                                    //     `${PaymentType.BankTransfer}` === dataEdit.paymentMethod?.value
                                                    // }
                                                />
                                            </Grid>
                                        )}
                                        {Number(dataEdit.paymentMethod?.value) === PaymentType.Banking && (
                                            <Grid item xs={12} md={6}>
                                                <FormField
                                                    disabled={
                                                        Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ? true : false
                                                    }
                                                    label={Strings.PAYMENT_TICKET.BANK_NAME}
                                                    placeholder={Strings.PAYMENT_TICKET.ENTER_BANK_NAME}
                                                    value={dataEdit.paymentAccountRequest?.bankCode?.value || ""}
                                                    errorMessage={dataEdit.paymentAccountRequest?.bankCode?.error}
                                                    onChangeValue={(value) => {
                                                        onValueChange("bankCode", value, true);
                                                    }}
                                                    // required={
                                                    //     `${PaymentType.CreditCard}` === dataEdit.paymentMethod?.value ||
                                                    //     `${PaymentType.BankTransfer}` === dataEdit.paymentMethod?.value
                                                    // }
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <FormField
                                                disabled={
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                    Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                value={dataEdit.description?.value || ""}
                                                errorMessage={dataEdit.description?.error}
                                                label={Strings.PAYMENT_TICKET.EXPLAIN}
                                                placeholder={Strings.PAYMENT_TICKET.ENTER_EXPLAIN}
                                                onChangeValue={(value) => {
                                                    onValueChange("description", value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid container p={2}>
                                            <Grid item xs={1} md={1}>
                                                <Switch
                                                    disabled={
                                                        Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Completed ||
                                                        Number(dataEdit?.voucherStatus?.value) === VoucherHistoryStatus.Cancel
                                                            ? true
                                                            : false
                                                    }
                                                    checked={Number(dataEdit?.revenueRecognition?.value) === RevenueRecognition.True ? true : false}
                                                    onChange={(event, checked) => {
                                                        onValueChange("revenueRecognition", checked);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={11} md={11}>
                                                <Typography variant="caption" fontWeight="bold">
                                                    {Strings.PAYMENT_TICKET.IS_DEFAULT}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
                {dataEdit?.id && (
                    <Grid item xs={12} md={3}>
                        <Card sx={{ height: "100%" }}>
                            <Box p={2}>
                                <Typography variant="h6" sx={{ textAlign: "center" }}>
                                    {Strings.PAYMENT_TICKET.HISTORY_UPDATE}
                                </Typography>
                                <Stepper activeStep={histories?.length - 1} orientation="vertical">
                                    {(histories || []).map((item) => (
                                        <Step key={item.id}>
                                            <StepLabel>
                                                <Typography variant="button" fontWeight="bold" sx={{ display: "block" }}>
                                                    {voucherStatus.find((el) => el.code === `${item.voucherStatus}`)?.name}
                                                </Typography>
                                                <Typography variant="button" sx={{ fontStyle: "italic", display: "block" }}>
                                                    {moment.unix(Number(`${item.updateTime}`)).format(Constants.DateFormat.DDMMYYYY_HHMM)}
                                                </Typography>
                                                <Typography variant="button" sx={{ fontStyle: "italic", display: "block" }}>
                                                    {`NV: ${item.createUserName}`}
                                                </Typography>
                                                {!Helpers.isNullOrEmpty(item.note) && (
                                                    <Typography
                                                        variant="button"
                                                        sx={{ display: "block" }}
                                                    >{`${Strings.Common.NOTE}: ${item.note}`}</Typography>
                                                )}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Card>
                    </Grid>
                )}
                {/* {modelAddCustomer && (
                    <AddCustomer
                        visibled={modelAddCustomer}
                        isCreateSupplier={Number(dataEdit?.userGroup?.value) === UserGroup.Supplier ? true : false}
                        onClose={() => setModelAddCustomer(false)}
                        onSave={(data: any) => {
                            let receiver = {value: data?.code};
                            let name = {value: data?.name};
                            setDataEdit({...dataEdit, name, receiver});
                        }}
                    />
                )}
                {addStaffVisible && (
                    <AddStaff
                        visibled={addStaffVisible}
                        onClose={() => setAddStaffVisible(false)}
                        onSave={(data: any) => {
                            let receiver = {value: data?.code};
                            let name = {value: data?.name};
                            setDataEdit({...dataEdit, name, receiver});
                        }}
                    />
                )} */}
            </Grid>
        </DashboardLayout>
    );
};

export default PaymentTicketScreen;
