import axios from "axios";
import moment from "moment";
import { setLocale } from "yup";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Constants from "./constants";
import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import NotFoundScreen from "screens/notFound";
import DashboardScreen from "screens/dashboard";
import ProtectedRoute from "routes/protectedRoute";
import OrderEditScreen from "screens/order/orderEditScreen";
import LoginRedirectScreen from "screens/login/loginRedirect";

import { RootState } from "store";
import { MainLayout } from "layout";
import { tabArrLanguage } from "assets/data";
import { useRenderRoute } from "routes/routes";
import { useAuth } from "providers/authProvider";
import { IRecordMenuDetail } from "commons/interfaces";
import { useMapScreenName } from "routes/mapScreenName";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setTargerScreen } from "store/slice/titleRoute.slice";

import { SaleCommonProvider } from "@maysoft/sale-common-react";
import { CircleCommonProvider } from "@maysoft/circle-common-react";
import { CommonComponentProvider, IItemRoute } from "@maysoft/common-component-react";

import "@maysoft/common-component-react/dist/index.css";
import "@maysoft/sale-common-react/dist/index.css";
import "./classes/globals.css";
import "./classes/utilities.css";

function App() {
    const auth = useAuth();
    const dispatchRedux = useDispatch();
    const dataMapScreenName = useMapScreenName();

    const [renderKey, setRenderKey] = useState(0);

    const routes = useRenderRoute(renderKey);

    const [menu, setMenu] = useState<IItemRoute[]>(routes);
    const [menuDetails, setMenuDetails] = useState<IRecordMenuDetail[]>([]);

    const userInfo = useSelector((state: RootState) => state.userInfo);
    const currentLanguage = Strings.getLanguage();

    const targetScreen: string = useSelector((state: RootState) => state.titleRoute.targerScreen);

    useEffect(() => {
        moment.locale(currentLanguage);
        setLocale({
            // use constant translation keys for messages without values
            mixed: {
                required: Strings.Validation.REQUIRED,
            },
            string: {
                email: Strings.Validation.EMAIL_ADDRESS,
            },
        });
    }, [currentLanguage]);

    useEffect(() => {
        const pathName = window.location.pathname;
        if (pathName !== Screens.LOGIN_REDIRECT && targetScreen !== `${pathName}${window.location.search}`) {
            dispatchRedux(setTargerScreen(`${pathName}${window.location.search}`));
        }

        const setKey = () => {
            setRenderKey(Date.now());
        };

        __EventEmitter.addListener(Constants.EventName.LANGUAGE_CHANGE, setKey);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.LANGUAGE_CHANGE, setKey);
        };
    }, []);

    // const handleCompareTwoArray = (array1: string[], array2: string | string[]) => {
    //     if (Array.isArray(array2)) {
    //         const index = array1.findIndex((element) => array2.includes(element));
    //         return index !== -1;
    //     } else {
    //         return array1.includes(array2);
    //     }
    // };

    // useEffect(() => {
    //     let routesTemp: any[] = [];

    //     const resourceMenu = [...(userInfo?.resourceMenu || []), Constants.ResourceCode.DASHBOARD];
    //     if (resourceMenu.length > 0) {
    //         routesTemp = routes.filter((item) => handleCompareTwoArray(resourceMenu, item.resourceCode));
    //         routesTemp.forEach((route, index) => {
    //             if (route.subMenu?.length > 0) {
    //                 const subMenuFilter = route.subMenu?.filter((item: any) => handleCompareTwoArray(resourceMenu, item.resourceCode));
    //                 if (subMenuFilter?.length > 0) {
    //                     const newRoute: any = { ...route };
    //                     newRoute.subMenu = subMenuFilter;
    //                     routesTemp[index] = newRoute;
    //                 }
    //             }
    //         });
    //     }

    //     setMenu(routesTemp);
    // }, [userInfo?.resourceMenu, routes]);

    useEffect(() => {
        if (auth?.user == null) {
            return;
        } else {
            setMenuDetails([...(userInfo?.menuDetails || [])]);
        }
    }, [auth.user, userInfo?.menuDetails]);

    const renderRoute = useMemo(() => {
        const arrRoute: any[] = [];

        // menu.forEach((item, key) => {
        //     if (item.subMenu?.length > 0) {
        //         item.subMenu.forEach((el, index) => {
        //             arrRoute.push(<Route key={`${key}+${index}}`} path={el.screenPath} element={el.screenName} />);
        //         });
        //     } else {
        //         arrRoute.push(<Route key={key} path={item.screenPath} element={item.screenName} />);
        //     }
        // });

        menuDetails.forEach((item, key) => {
            arrRoute.push(<Route key={key} path={item.externalUrl} element={dataMapScreenName[item.screenName] ?? <></>} />);

            if (!Helpers.isNullOrEmpty(item.extraInformation)) {
                const extraInformation = JSON.parse(item.extraInformation);
                if (Array.isArray(extraInformation)) {
                    extraInformation.forEach((el) => {
                        arrRoute.push(
                            <Route
                                key={key}
                                path={el.ScreenPath || el.screenPath}
                                element={dataMapScreenName[el.ScreenName || el.screenName] ?? <></>}
                            />
                        );
                    });
                } else {
                    arrRoute.push(
                        <Route
                            key={key}
                            path={extraInformation?.ScreenPath || extraInformation?.screenPath}
                            element={dataMapScreenName[extraInformation?.ScreenName || extraInformation?.screenName] ?? <></>}
                        />
                    );
                }
            }
        });

        return arrRoute;
    }, [menuDetails]);

    const renderMainLayout = useMemo(() => <MainLayout routes={menu} menuDetail={menuDetails} />, [menuDetails]);

    return (
        <CommonComponentProvider
            value={{
                axios: axios as any,
                userInfo: userInfo,
                language: currentLanguage,
                listMultiLanguage: tabArrLanguage,
                clientId: Constants.CLIENT_ID,
                tenantCode: Constants.TENANT_CODE,
                serviceCode: Constants.SERVICE_CODE,
                organizationId: userInfo?.currentOrganization,
                onShowLoading() {
                    dispatchRedux(showLoading(true));
                },
                onHideLoading() {
                    dispatchRedux(showLoading(false));
                },
                onSuccess(msg) {
                    dispatchRedux(setDataAlert({ message: msg, type: "success" }));
                },
                onError(msg, type) {
                    dispatchRedux(showLoading(false));
                    dispatchRedux(setDataAlert({ message: msg, type: type || "error" }));
                },
            }}
        >
            <SaleCommonProvider>
                <CircleCommonProvider>
                    <BrowserRouter key={renderKey}>
                        <Routes>
                            <Route path={Screens.LOGIN_REDIRECT} element={<LoginRedirectScreen />} />
                            <Route path={Screens.HOME} element={<ProtectedRoute>{renderMainLayout}</ProtectedRoute>}>
                                <Route index element={<DashboardScreen />} />
                                {renderRoute}
                                <Route path="*" element={<NotFoundScreen />} />
                            </Route>
                            <Route path={Screens.ORDER_EDIT} element={<OrderEditScreen />} />
                        </Routes>
                    </BrowserRouter>
                </CircleCommonProvider>
            </SaleCommonProvider>
        </CommonComponentProvider>
    );
}

export default App;
