import { Theme } from "@mui/material/styles";

export const table = (theme: Theme)  => ({
    minWidth: 650,
});

export const tabContainer = (theme: Theme)  => ({
    display: "flex",
    overFlow: "hidden",
    overflowX: "auto",
});

export const tab = (theme: Theme, ownerState?: { isActive?: boolean})  => {
    const isActive = ownerState?.isActive;
    return ({
        border: "1px solid #f3f3f3",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "5px",
        borderRadius: "27px",
        padding: "0px 10px",
        "&:hover": {
            backgroundColor: "#f3f3f3",
        },
        cursor: "pointer",
        ...(isActive && ({   backgroundColor: "#f3f3f3",}))
    })
};

 
export const textBox = (theme: Theme) => ({
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
export const tabText = (theme: Theme) => ({
    fontSize: 15,
    fontWeight: 500,
    width: 50,
});
export const headerContent = (theme: Theme) => ({
    padding: "10px !important",
});