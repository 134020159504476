import moment from "moment";

import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBack, Delete, Check, Close, AddCircleOutlineOutlined } from "@mui/icons-material";
import {
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {
    Box, Button, DataTable, Typography, Radio,
    FormField, Autocomplete, Modal, SearchInputAPI
} from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "../../constants";
import Screens from "constants/screens";
import ItemSevice from "services/sale/item.service";
import OrderService from "services/sale/order.service";
import PartnerService from "services/sale/partner.service";
import SystemSettingService from "services/sale/systemsetting.service";

import { RootState } from "store";
import { PageLayout } from "layout";
import { ReadMore } from "components";
import { ICodename } from "commons/interfaces";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import {
    PaymentType,
    OrderType,
    MultipleApply,
    ItemType,
    ActionRequest,
    ShippingMethod,
    DiscountType,
    DiscountDetailType,
    PromotionMethod,
    OrderDetailPromotion,
    StatusVariable,
    SystemSettingCode,
} from "constants/enum";
import { headerContent, tab, tabContainer, tabText } from "./styles";
interface IModel {
    id?: string;
    organizationId?: string;
    buyer?: string;
    seller?: string;
    paymentMethod?: number;
    type?: number;
    orderDetailRequests?: IOrderDetail[];
    //changeAmount?: any,
    //receiveAmount?: any,
    shippingAddress?: string;
    shippingMethod?: number;
    serviceCode?: string;
    discount?: number;
    discountRate?: number;
    discountCode?: string;
    note?: string;
    shoppingCardId?: string;
    price?: number;
    billDiscount?: {
        discountRate?: any;
        discount?: number;
    };
    voucherCodes?: string[];
    usePoint?: number;
    applyPoint?: number;
    promotionIds?: string[];
    location?: string;
}

interface IOrderDetail {
    id?: string;
    type?: number;
    orderId?: string;
    itemId?: string;
    // promotionId?: string,
    description?: string;
    quantity?: number;
    price?: string;
    discount?: {
        discountRate?: number;
        discount?: number;
        discountCode?: number;
    };
    location?: string;
}

interface IViewScreen {
    totalItem?: number; // tống sản phẩm
    amount?: number; //tồng tiền hàng
    totalAmountPay?: number; // tổng tiền thanh toán
    //applyPricingType?: any,  // Loại giá áp dụng
    alternativePricing?: any; //giá thay thế khi item ko có giá default user
    loyaltyPointApplied?: number; //ĐIểm áp dụng
    amountApplyPoint?: number; //tiền được giảm khi áp dụng điểm áp dụng điểm
    debtAmount?: number; //công nợ
    tax?: number; //
    amountReceiveFromCustomer?: number; //
    amountApplyPromotion?: number; //
    amountApplyVoucher?: number; //
    paymentTypeCash?: boolean; //
    paymentTypeDebt?: boolean; //
    paymentName?: string;
    // debtAmount?: number,
    loyaltyPoint?: number;
    loyaltyClassDiscount?: number;
    partnerLoyalty?: any;
    memberLoyalty?: any;
    customBillDiscount?: number;
    discountType?: any; //loại Chiết khấu 1: phần trăm, 2 tiền
    billDiscount?: any; //loại Chiết khấu 1: phần trăm, 2 tiền
    type?: number; // nhận biết là chỉnh sửa hay thanh toán từ url màn hình chi tiết qua
    amountExchanged?: string;
}

const OrderEditScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const itemService = new ItemSevice();
    const orderService = new OrderService();
    const partnerService = new PartnerService();
    const systemSettingService = new SystemSettingService();

    const authInfo = useSelector((state: RootState) => state.auth.authInfo);
    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const userProfile = useSelector((state: RootState) => state.userInfoSlice?.userProfile);

    //innitial khi add hóa đơn mới
    const [initialModel] = useState<IModel>({
        paymentMethod: PaymentType.Cash,
        type: OrderType.Sell,
        shippingMethod: ShippingMethod.Direct,
        location: Constants.CURRENCY_DEFAULT,
    });
    const [initialModelView] = useState<IViewScreen>({
        paymentTypeCash: true,
        paymentName: Strings.ORDER.CASH,
    } as IViewScreen);

    const [model, setModel] = useState<IModel>({});
    const [modelView, setModelView] = useState<IViewScreen>({});
    const [orderDetail, setOrderDetail] = useState<IOrderDetail>();

    const [modalChoosePaymentType, setModalChoosePaymentType] = useState<boolean>(false);
    const [modalVoucher, setModalVoucher] = useState<boolean>(false);

    const [itemListTemp, setItemListTemp] = useState<any[]>([]);
    const [tableItemList, setTableItemList] = useState<any[]>([]);
    const [modelPromotionProgram, setModelPromotionProgram] = useState<boolean>(false);
    const [modelTablePromotion, setModelTablePromotion] = useState<boolean>(false);
    const [promotionIds, setPromotionIds] = useState<string[]>([]);

    const [promotionProgramTable, setPromotionProgramTable] = useState<any>({
        columns: [
            { Header: "Tên chương trình", accessor: "name", width: "80px" },
            { Header: "Mã chương trình", accessor: "code", width: "80px" },
            { Header: "Loại khuyến mãi", accessor: "promotionType", width: "90px" },
            { Header: "Điều kiện", accessor: "condition", width: "80px" },
            { Header: "Chiết khấu", accessor: "extraction", width: "80px" },
            {
                Header: "Áp dụng đồng thời",
                accessor: "multipleApply",
                width: "50px",
                Cell: (value: any) => {
                    return (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            {Number(value.row?.original?.multipleApply) === MultipleApply.True ? (
                                <IconButton>
                                    {" "}
                                    <Check color="success" />
                                </IconButton>
                            ) : (
                                <IconButton>
                                    {" "}
                                    <Close color="error" />
                                </IconButton>
                            )}
                        </Box>
                    );
                },
            },
            { Header: "Ngày hết hạn", accessor: "expires", width: "80px" },
        ],
        rows: [],
    });
    const [tableItemPromotion, setTableItemPromotion] = useState<any[]>([]);
    // const [tableItemVoucherCode, setTableItemVoucherCode] = useState<any[]>([])

    const [partnerList, setPartnerList] = useState<ICodename[]>([]);
    const [reload, setReload] = useState<number>(0);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(authInfo?.access_token) || reload === 0) {
            setReload((prev) => prev + 1);
            return;
        }
        handleSetInitial();
        const { id } = Helpers.getUrlParams(["id"]);
        const { type } = Helpers.getUrlParams(["type"]);
        Helpers.setItemInLocalStorage(Strings.ORDER.ORDER_LIST, []);
        Helpers.setItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP, []);
        getPartner();
        if (id) {
            const result = async () => {
                dispatch(showLoading(true));
                let orderDetailResult = await getDetailOrder(id, Number(type));
                await handlePricing(orderDetailResult?.tableItemTemp, orderDetailResult?.data, orderDetailResult?.dataView);
                dispatch(showLoading(false));
            };
            result().catch(console.error);
        }
    }, [authInfo, reload]);

    const getPaymentMethods = async () => {
        const result = await systemSettingService.getDetail(SystemSettingCode.Payment);
    };

    const getDetailOrder = async (id: string, type: number) => {
        try {
            let tableItemTemp: any[] = [];
            let itemList: any[] = [];
            let partnerInfo: any;
            let promotionIds: string[] = [];
            let voucherCodes: string[] = [];
            const result = await orderService.getDetail(id);

            if (result?.buyer) {
                partnerInfo = await getDetailPartner(result?.buyer);
            }

            //get promotion được áp dụng
            result?.applyPromotions?.forEach((el: any) => {
                promotionIds.push(el.id);
            });

            //get khuyến mãi được áp dụng
            result?.applyVoucherCodes?.forEach((el: any) => {
                voucherCodes.push(el.code);
            });

            //nếu có khuyến mãi tặng hàng thì get lại item được khuyến mãi
            let ids: string[] = [];
            result?.orderDetails?.forEach((item: any) => {
                ids.push(item.itemId);
            });

            if (ids.length > 0) {
                itemList = await getItem(ids, true);
            }

            //table khi áp dụng khuyến mãi với mặt hàng
            result?.orderDetails?.forEach((e: any) => {
                let itemTarget = itemList?.find((el: any) => el.id === e.itemId);
                //nếu item trả về có promotionId và promotion đó áp dụng trên item với số lượng lớn hơn 0 thì cập nhật lại thong tin item trên tabe

                if (!Helpers.isNullOrEmpty(e?.itemDiscount?.promotionId)) {
                    let comboItemDetail: any[] = [];
                    if (!Helpers.isNullOrEmpty(e.orderDetailComboItems)) {
                        (e?.orderDetailComboItems || []).forEach((itemDetail: any) => {
                            comboItemDetail.push(`${itemDetail?.quantity}x ${itemDetail.itemName?.value.vi}`);
                        });
                    }
                    let itemTable = {
                        id: itemTarget?.id,
                        image: itemTarget?.itemPhotos?.[0]?.photoId || "",
                        // name: itemTarget?.itemNameValue.value.vi,
                        name: itemTarget.name,
                        comboName: comboItemDetail.length > 0 ? comboItemDetail : undefined,
                        quantity: e.quantity,
                        price: e.unitPrice,
                        amount: e.unitPrice * e.quantity + e.tax - e.discount,
                        promotionId: e?.itemDiscount?.promotionId,
                        discount: e.discount,
                    };
                    tableItemTemp.push(itemTable);
                } else {
                    let comboItemDetail: any[] = [];
                    if (!Helpers.isNullOrEmpty(e.orderDetailComboItems)) {
                        (e?.orderDetailComboItems || []).forEach((itemDetail: any) => {
                            comboItemDetail.push(`${itemDetail?.quantity}x ${itemDetail.itemName?.value.vi}`);
                        });
                    }

                    let itemTable = {
                        id: e.itemId,
                        image: e.itemPhotos[0].photoId,
                        name: e.itemName?.value?.vi,
                        quantity: e.quantity,
                        price: e.unitPrice,
                        // serviceCode: item.product?.serviceCode,
                        amount: e.unitPrice * e.quantity,
                        comboName: comboItemDetail.length > 0 ? comboItemDetail : undefined,
                    };
                    tableItemTemp.push(itemTable);
                }
            });

            let billDiscount: any = {};
            let billDiscountView: any = 0;
            let discountType: any;
            let usePoint: number = 0;
            result?.orderDiscounts?.forEach((el: any) => {
                switch (el.type) {
                    case DiscountDetailType.UsingPoint:
                        usePoint = Number(el.discountQuantity);
                        break;
                    case DiscountDetailType.CustomBillDiscount:
                        // usePoint = Number(el.discount)
                        if (Number(el.discountRate) > 0) {
                            discountType = DiscountType.Percent;
                            billDiscountView = Number(Helpers.decimalsToPercent(Number(el.discountRate)));
                            billDiscount.discountRate = el.discountRate;
                        } else if (Number(el.discount) > 0) {
                            discountType = DiscountType.Money;
                            billDiscountView = el.discount;
                            billDiscount.discount = el.discount;
                        }
                        break;
                    default:
                        break;
                }
            });

            Helpers.setItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP, itemList);

            let data: IModel = {
                ...model,
                buyer: result?.buyer,
                promotionIds,
                usePoint,
                // type: Number(type) === OrderStatus.Confirm ? OrderType.Sell : OrderType.Book,
                voucherCodes,
                billDiscount,
                note: result?.note,
                shippingMethod: result.shippingMethod,
                type: result.type,

                id,
                serviceCode: result?.serviceCode,
            };
            let dataView: IViewScreen = {
                ...partnerInfo,
                billDiscount: billDiscountView,
                discountType,
                type,
                amountExchanged: result?.amountExchanged?.value,
            };
            let orderDetailResult = {
                tableItemTemp,
                data,
                dataView,
            };
            setPromotionIds(promotionIds);
            return orderDetailResult;
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleSetInitial = () => {
        dispatch(showLoading(true));
        setModelView({ ...initialModelView });
        setModel({ ...initialModel });
        dispatch(showLoading(false));
    };

    // const getItem = async (ids?: string[], isOrderDetail?: boolean) => {
    //     try {
    //         let query: any = {
    //             organizationId,
    //             ids,
    //             pageSize: ids ? ids?.length : undefined
    //         };
    //         const data = await itemService.getPaged(Helpers.handleFormatParams(query))
    //         let itemList = Helpers.getItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP) || [];
    //         const result: any[] = [];
    //         data?.items.forEach((item: any) => {
    //             const attrName: string = Helpers.getAttributeNameByItem(item.attributeValues || []);
    //             if (isOrderDetail) {
    //                 result.push({ ...item, name: `${item.itemNameValue?.value.vi} ${attrName}` })
    //             } else {
    //                 let isExits = itemList?.find((e: any) => e.id === item.id);
    //                 !isExits && result.push({ ...item, name: `${item.itemNameValue?.value.vi} ${attrName}` })
    //             }
    //         })
    //         return result;
    //     } catch (error) {
    //         dispatch(showLoading(false));
    //         const e = Helpers.renderExceptionError(error);
    //         dispatch(setDataAlert({ message: e, type: "error" }));
    //     }
    // }

    const getPartner = async () => {
        let query = {
            organizationId: userProfile?.organizationId,
        };
        const data = await partnerService.getAll(Helpers.handleFormatParams(query));
        let partnerList: any[] = [];
        data?.forEach((item: any) => {
            partnerList.push({
                id: item.id,
                name: item.partnerName + "-" + item.phoneNumber,
                code: item.id,
                group: item.debtAmount,
            });
        });
        setPartnerList(partnerList);
    };

    const getDetailPartner = async (id: string) => {
        try {
            const data = await partnerService.getDetail(id);
            const modelViewResult: IViewScreen = { ...modelView };
            modelViewResult.loyaltyPoint = data?.partnerLoyalty?.loyaltyPoint;
            modelViewResult.memberLoyalty = data?.partnerLoyalty?.loyaltyName;
            return modelViewResult;
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.ORDER_LIST);
        navigate(Screens.ORDER_LIST + (itemPathName ? itemPathName.query : ""));
    };

    const handleResetData = () => {
        // setTableItemVoucherCode([])
        setModel(initialModel);
        setModelView(initialModelView);
        setTableItemList([]);
        setTableItemPromotion([]);
    };

    const handleDeleteItem = async (id: string) => {
        let tableItemListTemp: any[] = [...(tableItemList || [])];
        let dataResult = tableItemListTemp?.filter((el: any) => el.id !== id) || [];
        setTableItemList(dataResult);
        await handlePricing(dataResult, model, modelView);
    };

    const handleGetCombo = (item: any) => {
        let comboName: string[] = [];
        item?.comboItemDetail?.forEach((el: any) => {
            // const itemDetailAttrName: string = Helpers.getAttributeNameByItem(el.attributeValues || []);
            comboName.push(`${el?.quantity}x ${el?.name?.value?.vi || ""}`);
        });
        return comboName;
    };

    const handleAddItem = async (itemId: string, applyPromotion?: any, itemDetail?: any) => {
        try {
            let tableItemListTemp: any[] = [...(tableItemList || [])];
            let itemList = Helpers.getItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP) || [];

            if (applyPromotion) {
                //trường hợp chọn mua item được đề xuất khuyến mãi
                let itemTarget = itemList?.find((el: any) => el.id === itemId);

                let isExitsItem = tableItemList?.find((el: any) => el.id === itemId && el.promotionId === applyPromotion.promotionId);

                if (isExitsItem) {
                    tableItemList?.forEach((el: any) => {
                        if (el.id === itemTarget.id && el.promotionId) {
                            el.quantity = Number(el.quantity) + Number(applyPromotion.quantity);
                        }
                    });

                    setTableItemList([...tableItemList]);
                } else {
                    let itemTable = {
                        id: itemTarget?.id,
                        image: itemTarget?.itemPhotos?.[0]?.photoId || "",
                        name: itemTarget?.name,
                        // name: itemTarget?.itemNameValue.value.vi,
                        comboName: itemTarget?.type === ItemType.ComboService ? handleGetCombo(itemTarget) : undefined,
                        quantity: applyPromotion.quantity,
                        price: itemTarget?.price,
                        promotionId: applyPromotion.promotionId,
                    };
                    tableItemListTemp.splice(0, 0, itemTable);
                    setTableItemList(tableItemListTemp);
                }
            } else {
                //gán item được chọn vào mảng temp
                // const listItem = [...itemListTemp || []]
                let isExits = itemList?.find((e: any) => e.id === itemDetail?.id);
                !isExits && itemList.push(itemDetail);
                setItemListTemp(itemList);
                Helpers.setItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP, itemList);

                let isExitsItem = tableItemList?.find((el: any) => el.id === itemId && !el.promotionId);
                if (isExitsItem) {
                    tableItemList?.forEach((el: any) => {
                        if (el.id === itemDetail.id) {
                            el.quantity = Number(el.quantity) + 1;
                        }
                    });

                    setTableItemList([...tableItemList]);
                } else {
                    let itemTable = {
                        id: itemDetail?.id,
                        // sequency: tableItemList?.length + 1,
                        image: itemDetail?.itemPhotos?.[0]?.photoId || "",
                        name: itemDetail?.name,
                        comboName: itemDetail?.type === ItemType.ComboService ? handleGetCombo(itemDetail) : undefined,
                        quantity: 1,
                        price: itemDetail?.price,
                    };
                    tableItemListTemp.splice(0, 0, itemTable);
                    // tableItemListTemp.push()
                    setTableItemList(tableItemListTemp);
                }
            }

            await handlePricing(tableItemListTemp, model, modelView);
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getItem = async (ids?: string[], isOrderDetail?: boolean) => {
        try {
            let query: any = {
                ids,
                pageSize: ids ? ids?.length : undefined,
                organizationId: userProfile?.organizationId,
            };
            const data = await itemService.getPaged(Helpers.handleFormatParams(query));
            let itemList = Helpers.getItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP) || [];
            const result: any[] = [];
            data?.items.forEach((item: any) => {
                //const attrName: string = Helpers.getAttributeNameByItem(item.attributeValues || []);
                if (isOrderDetail) {
                    // result.push({ ...item, name: `${item.itemNameValue?.value.vi} ${attrName}` })
                    result.push({ ...item, name: item.name?.value.vi });
                } else {
                    let isExits = itemList?.find((e: any) => e.id === item.id);
                    !isExits && result.push({ ...item, name: item.name?.value.vi });
                }
            });
            return result;
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const deboundPricing = debounce(async () => {
        await handlePricing(tableItemList, model, modelView);
    }, 500);

    const handlePricing = async (tableItemListTemp: any[], data?: IModel, dataView?: IViewScreen) => {
        try {
            //dispatch(showLoading(true));
            let modelViewResult: IViewScreen = { ...dataView };
            let modelResult: IModel = { ...data };

            let serviceCode: string = "";
            let orDetail: any[] = [];
            let itemList = Helpers.getItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP) || [];
            let multipleApply: string[] = data?.promotionIds || [];
            let dataRow: any = [];
            let tableItemTemp: any[] = [];

            if (tableItemListTemp?.length > 0) {
                tableItemListTemp?.forEach((item, index) => {
                    // let getItem = itemList?.find((el: any) => el.id === item.id);
                    if (item?.promotionId) {
                        let isExitsPromotion = multipleApply?.find((e: string) => e === item?.promotionId);
                        if (isExitsPromotion) {
                            orDetail.push({
                                itemId: item.id,
                                promotionId: item.promotionId,
                                promotionMethod: item.promotionMethod,
                                quantity: item.quantity,
                            });
                        } else {
                            // tableItemTemp = tableItemTemp?.filter((el: any) => el.id !== item.id)//remove những item được khuyến mãi khỏi table khi đổi trường trình khuyến mãi
                        }
                    } else {
                        orDetail.push({
                            itemId: item?.id,
                            quantity: item?.quantity,
                        });
                    }
                });

                let request: any = {
                    id: data?.id,
                    organizationId: userProfile?.organizationId,
                    serviceCode: data?.serviceCode || serviceCode,
                    buyer: data?.buyer,
                    seller: data?.seller,
                    orderDetailRequests: orDetail,
                    discount: data?.discount,
                    discountRate: data?.discountRate,
                    discountCode: data?.discountCode,
                    type: data?.type,
                    shippingAddress: data?.shippingAddress,
                    shippingMethod: data?.shippingMethod,
                    paymentMethod: data?.paymentMethod,
                    note: data?.note,
                    usePoint: Helpers.isNullOrEmpty(data?.usePoint) ? Number(data?.usePoint) : undefined,
                    promotionIds: data?.promotionIds,
                    voucherCodes: data?.voucherCodes,
                    billDiscount: data?.billDiscount,
                    location: data?.location,
                };
                const result = await orderService.calcPricing(request);

                //nếu có khuyến mãi tặng hàng thì get lại item được khuyến mãi
                if (
                    result?.result.applyPromotions?.find(
                        (el: any) =>
                            el.promotionMethod === PromotionMethod.ItemDiscount ||
                            el.promotionMethod === PromotionMethod.ItemDiscountByQuantity ||
                            el.promotionMethod === PromotionMethod.Gift
                    )
                ) {
                    let ids: string[] = [];
                    result?.result?.orderDetails?.forEach((item: any) => {
                        let itemTarget = itemList?.find((el: any) => el.id === item.itemId);
                        if (!itemTarget) {
                            ids.push(item.itemId);
                        }
                    });
                    let itemResult = await getItem(ids);
                    itemList.push(...itemResult);
                }

                //applyVoucherCode
                let voucherCodes = result?.result?.applyVoucherCodes?.length > 0 ? [`${result?.result?.applyVoucherCodes[0]?.code}`] : [];

                if (result?.result?.availablePromotions?.length <= 0) {
                    multipleApply = [];
                } else {
                    //Lặp qua khuyến mãi được áp dụng
                    result?.result?.availablePromotions?.forEach((el: any) => {
                        dataRow.push({
                            id: el.id,
                            name: el.promotionName?.value?.vi || "",
                            code: el.promotionCode,
                            promotionType: Helpers.getPromotionMethodName(el.promotionMethod),
                            condition: `Đơn hàng từ ${el.promotionCriterias[0]?.minimumExpense}${Number(el.promotionCriterias[0]?.minimumQuantity) !== 0
                                ? ` - Số lượng từ ${el.promotionCriterias[0]?.minimumQuantity}`
                                : ""
                                }`,
                            extraction:
                                el.promotionCriterias[0]?.discount ||
                                el.promotionCriterias[0]?.discountRate ||
                                el.promotionCriterias[0]?.discountQuantity,
                            multipleApply: el.multipleApply,
                            expires:
                                Number(el.hasEndTime) === StatusVariable.False
                                    ? "Vô thời hạn"
                                    : moment(el.endTime * 1000).format(Constants.DateFormat.DDMMYYYY),
                        });
                    });
                }

                //table khi áp dụng khuyến mãi với mặt hàng
                let dataRowPromotion: any[] = [];
                result?.result?.orderDetails?.forEach((item: any) => {
                    let itemTarget = itemList?.find((el: any) => el.id === item.itemId);

                    //nếu item trả về có promotionId và promotion đó áp dụng trên item với số lượng lớn hơn 0 thì cập nhật lại thong tin item trên tabe
                    if (
                        !Helpers.isNullOrEmpty(item.promotionId) &&
                        Number(item.promotion) === OrderDetailPromotion.PromotionItem &&
                        item.suggestQuantity > 0
                    ) {
                        if (itemTarget) {
                            // push item vào table khuyến mãi
                            let itemTable = {
                                id: itemTarget?.id,
                                image: itemTarget?.itemPhotos?.[0]?.photoId || "",
                                name: itemTarget?.name,
                                comboName: itemTarget?.type === ItemType.ComboService ? handleGetCombo(itemTarget) : undefined,
                                quantity: item.suggestQuantity,
                                price: itemTarget?.price,
                                discount: item.discount,
                                // amount: (item.unitPrice * item.quantity) + item.tax - item.discount,
                                amount: item.amount - item.tax,
                                suggestDiscount: item.suggestDiscount,
                                suggestQuantity: item.suggestQuantity,
                                promotionId: item.promotionId,
                                unitPriceExchanged: item?.unitPriceExchanged?.value,
                                serviceCode: itemTarget.product?.serviceCode,
                                promotionMethod: result?.result.applyPromotions?.find((el: any) => el.id === item.promotionId)?.promotionMethod,
                            };
                            dataRowPromotion.push(itemTable);
                        }

                        if (
                            !Helpers.isNullOrEmpty(item.promotionId) &&
                            Number(item.promotion) === OrderDetailPromotion.PromotionItem &&
                            item.quantity > 0
                        ) {
                            let itemTable = {
                                id: itemTarget?.id,
                                image: itemTarget?.itemPhotos?.[0]?.photoId || "",
                                name: itemTarget?.name,
                                comboName: itemTarget?.type === ItemType.ComboService ? handleGetCombo(itemTarget) : undefined,
                                quantity: item.quantity,
                                price: itemTarget?.price,
                                amount: item.amount - item.tax,
                                promotionId: item.promotionId,
                                discount: item.discount,
                                serviceCode: itemTarget.product?.serviceCode,
                                unitPriceExchanged: item?.unitPriceExchanged?.value,
                                promotionMethod: result?.result.applyPromotions?.find((el: any) => el.id === item.promotionId)?.promotionMethod,
                            };
                            tableItemTemp.push(itemTable);
                        }
                    } else {
                        let itemTable = {
                            id: itemTarget?.id,
                            image: itemTarget?.itemPhotos?.[0]?.photoId || "",
                            name: itemTarget?.name,
                            comboName: itemTarget?.type === ItemType.ComboService ? handleGetCombo(itemTarget) : undefined,
                            quantity: item.quantity,
                            price: itemTarget?.price,
                            amount: item.amount - item.tax,
                            promotionId: item.promotionId,
                            discount: item.discount,
                            unitPriceExchanged: item?.unitPriceExchanged?.value,
                            promotionMethod: result?.result.applyPromotions?.find((el: any) => el.id === item.promotionId)?.promotionMethod,
                        };
                        tableItemTemp.push(itemTable);
                    }
                });
                setTableItemPromotion(dataRowPromotion);

                setTableItemList(tableItemTemp);

                let loyaltyClassDiscount: number = 0; // giảm giá thành viên
                let usePointTemp: number = 0; //số tiền giảm giá khi áp dụng điểm
                let amountApplyVoucher: number = 0; //Chiết khấu khách hàng

                result?.result?.discountDetails?.forEach((el: any) => {
                    switch (el.type) {
                        case DiscountDetailType.VoucherDiscount:
                            amountApplyVoucher += Number(el.discount);
                            break;
                        case DiscountDetailType.CustomBillDiscount:
                            amountApplyVoucher += Number(el.discount);
                            break;
                        case DiscountDetailType.CustomItemDiscount:
                            amountApplyVoucher += Number(el.discount);
                            break;
                        case DiscountDetailType.UsingPoint:
                            usePointTemp = Number(el.discount);
                            break;
                        case DiscountDetailType.LoyaltyClassDiscount:
                            // amountApplyVoucher += Number(el.discount)
                            loyaltyClassDiscount = Number(el.discount);
                            break;
                        default:
                            break;
                    }
                });

                modelResult = { ...modelResult, ...request };
                modelResult.voucherCodes = voucherCodes;
                modelResult.promotionIds = multipleApply;

                setPromotionProgramTable({ ...promotionProgramTable, rows: dataRow });
                Helpers.setItemInLocalStorage(Strings.ORDER.ITEM_LIST_TEMP, itemList);
                setItemListTemp(itemList);

                //let amount: number = result?.result?.amount - result?.result?.tax + result?.result?.discount; //Tổng tiền hàng
                modelViewResult.totalItem = result?.result?.totalItem;
                modelViewResult.amount = result?.result?.originAmount;
                modelViewResult.amountApplyPoint = usePointTemp;
                // //số tiền khuyến mãi  =  tiền discount - tiền voucher/Chiết khấu - tiền user sử dụng điểm.
                modelViewResult.amountApplyPromotion = result?.result?.discount - amountApplyVoucher - usePointTemp - loyaltyClassDiscount;
                modelViewResult.tax = result?.result?.tax;
                modelViewResult.totalAmountPay = result?.result?.amount;
                modelViewResult.loyaltyPoint = !data?.buyer ? undefined : dataView?.loyaltyPoint;
                modelViewResult.memberLoyalty = !data?.buyer ? undefined : dataView?.memberLoyalty;
                modelViewResult.amountApplyVoucher = amountApplyVoucher;
                modelViewResult.loyaltyClassDiscount = loyaltyClassDiscount;
                modelViewResult.type = dataView.type;
                modelViewResult.amountExchanged = result?.result?.amountExchanged?.value;
                setModelView(modelViewResult);

                // (!Helpers.isNullOrEmpty(model.receiveAmount) && Number(modelView.totalAmountPay) > 0
                //     && Number(model.receiveAmount) > 0) && model.receiveAmount < modelView.totalAmountPay &&
                //     setIsSuggestMoney(true)

                if (Number(result.statusCode) === Constants.ApiCode.BAD_REQUEST) {
                    dispatch(setDataAlert({ message: result.message, type: "success" }));
                    await handlePricing(tableItemTemp, modelResult, modelViewResult);
                }
            } else {
                modelViewResult.totalItem = 0;
                modelViewResult.amount = 0;
                modelViewResult.tax = 0;
                modelViewResult.totalAmountPay = 0;
                modelViewResult.loyaltyPoint = dataView?.loyaltyPoint;
                modelViewResult.memberLoyalty = dataView?.memberLoyalty;
                modelResult.buyer = data?.buyer;
                setTableItemPromotion([]);
            }
            setModel({ ...modelResult });
            setModelView({ ...modelViewResult });
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handlePayment = async () => {
        try {
            let request = { ...model };
            if (Helpers.isNullOrEmpty(request.buyer)) {
                dispatch(setDataAlert({ message: Strings.Validation.REQUIRED_CUSTOMER, type: "warning" }));
                return;
            }
            if (request?.orderDetailRequests?.length > 0) {
                dispatch(showLoading(true));
                let result: any;
                if (model.promotionIds?.length < 0) {
                    request.promotionIds = undefined;
                }
                if (request.id) {
                    result = await orderService.update(request);
                    dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
                    handleGoBack();
                } else {
                    result = await orderService.confirmOrder(request);
                    dispatch(setDataAlert({ message: Strings.Message.CREATE_SUCCESS, type: "success" }));
                    handleDeleteTab(tabValue);
                    setModel({ ...request, type: OrderType.Sell, id: result.id });
                }

                dispatch(showLoading(false));
                // navigate(Screens.ORDER_LIST)
            } else {
                dispatch(setDataAlert({ message: Strings.Validation.ORDER_ITEM_REQUIRED, type: "warning" }));
                return;
            }
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    // promotion
    const handleRemovePromotion = async (id: string) => {
        try {
            let promotionIds = model.promotionIds?.filter((el: string) => el !== id);
            onValueChange(promotionIds, "promotionIds");
            // await handlePricing(tableItemList, model, modelView)
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleClosePromotion = async () => {
        try {
            onValueChange([], "promotionIds");
            setModelPromotionProgram(false);
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onValueChange = async (value: any, key: string, required?: boolean) => {
        let data: IModel = { ...model };
        let dataView: IViewScreen = { ...modelView };

        switch (key) {
            case "buyer":
                data[key] = value;
                dispatch(showLoading(true));
                if (value) {
                    let partnerInfo = await getDetailPartner(value);
                    dataView = { ...partnerInfo };
                }
                await handlePricing(tableItemList, data, dataView);
                dispatch(showLoading(false));
                break;
            case "note":
                data[key] = value;
                setModel({ ...data });
                break;
            // case "type":
            //     if (value) {
            //         data[key] = OrderType.Book;
            //     } else {
            //         data[key] = OrderType.Sell;
            //     }

            //     setModel({ ...data });
            //     break;
            case "discountType":
                dataView[key] = value;
                dataView.billDiscount = "";
                data.billDiscount = undefined;
                setModel({ ...data });
                setModelView({ ...dataView });
                break;
            case "billDiscount":
                if (Number(dataView.discountType) === DiscountType.Percent) {
                    data[key] = {
                        discountRate: Number(Helpers.percentToDecimals(Number(value) > 100 ? 100 : value || 0)),
                        // discount: 0
                    };
                    dataView[key] = value > 100 ? 100 : value || 0;
                } else {
                    data[key] = {
                        discount: Number(value),
                        discountRate: 0,
                    };
                    dataView[key] = Number(value);
                }
                setModel({ ...data });
                setModelView({ ...dataView });
                break;
            case "voucherCodes":
                data[key] = value;
                setModel({ ...data });
                break;
            // case "usePoint":
            //     if (Number(value) > 0) {
            //         data[key] = Number(value) >= Number(modelView.loyaltyPoint) ? Number(modelView.loyaltyPoint) : value;
            //         await handlePricing(tableItemList, data, dataView);
            //     }
            //     break;
            case "paymentMethod":
                data[key] = Number(value);
                setModel({ ...data });
                break;
            case "promotionIds":
                data[key] = [...value];
                dispatch(showLoading(true));
                await handlePricing(tableItemList, data, dataView);
                dispatch(showLoading(false));
                break;
            case "usePoint":
                if (value >= 0) {
                    data[key] = modelView.loyaltyPoint && Number(value) > modelView.loyaltyPoint ? modelView.loyaltyPoint : value;
                    await handlePricing(tableItemList, data, dataView);
                }
                break;
            // case "groupId":
            //     dispatch(showLoading(true));
            //     data[key] = value;
            //     setModel({ ...data });
            //     setGroupId(value)
            //     dispatch(showLoading(false));
            //     break
            default:
                break;
        }
    };

    const listItem = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer sx={{ overflow: "hiden", height: "85vh", borderRadius: "0.75rem" }}>
                        <Table aria-label="simple table">
                            <TableHead style={{ display: "contents" }}>
                                <TableRow className="stickyHeader">
                                    <TableCell colSpan={1}>{Strings.ORDER_TABE_HEADER.SEQUENCE}</TableCell>
                                    <TableCell colSpan={2}>{Strings.ORDER_TABE_HEADER.IMAGE}</TableCell>
                                    <TableCell colSpan={4}>{Strings.ORDER_TABE_HEADER.NAME}</TableCell>
                                    <TableCell colSpan={1}>{Strings.ORDER_TABE_HEADER.QUANTITY}</TableCell>
                                    <TableCell colSpan={2}>{Strings.ORDER_TABE_HEADER.PRICE}</TableCell>
                                    <TableCell colSpan={2}>{Strings.ORDER_TABE_HEADER.AMOUNT}</TableCell>
                                    <TableCell colSpan={2}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableItemList?.map((row: any, index: number) => (
                                    <TableRow key={row.id}>
                                        <TableCell colSpan={1}>{index + 1}</TableCell>
                                        <TableCell colSpan={2}>
                                            <img className="img-Item" alt="" src={Helpers.getFileAccessUrl(row.image)} />
                                        </TableCell>
                                        <TableCell colSpan={4}>
                                            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                <Typography variant="caption" fontWeight="medium" color="text">
                                                    {row.name}
                                                </Typography>
                                                {row.comboName && handleShowComboName(row.comboName)}
                                            </Box>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <FormField
                                                disabled={
                                                    row.promotionId && row.promotionMethod !== PromotionMethod.ItemDiscountByQuantity ? true : false
                                                }
                                                value={row.quantity}
                                                type="number"
                                                style={{ width: "40%" }}
                                                hideBorder
                                                onChangeValue={async (value: any) => {
                                                    row.quantity = value;
                                                    setTableItemList([...tableItemList]);
                                                    await deboundPricing();
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell colSpan={2}>{Helpers.formatCurrency(Number(row.price), 2)}</TableCell>
                                        <TableCell colSpan={2}>
                                            <p className={row.promotionId ? "oldPrice" : ""}>
                                                {Helpers.formatCurrency(Number(row.promotionId ? row.amount + row.discount : row.amount), 2)}
                                            </p>
                                            {row.promotionId && <p>{Helpers.formatCurrency(Number(row.amount && row.amount), 2)}</p>}
                                            {!Helpers.isNullOrEmpty(row.unitPriceExchanged) && (
                                                <p style={{ fontSize: 12, display: "block" }}>{`~(${row.unitPriceExchanged})`}</p>
                                            )}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <IconButton onClick={() => handleDeleteItem(row.id)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {
                            //popup hàng khuyễn mãi
                            tableItemPromotion?.length > 0 && (
                                <Typography
                                    fontSize={12}
                                    variant="body2"
                                    fontWeight="bold"
                                    className="promotionTable"
                                    onClick={() => {
                                        setModelTablePromotion(true);
                                    }}
                                >
                                    {Strings.ORDER.PROMOTION_ITEM}
                                </Typography>
                            )
                        }
                    </TableContainer>
                </Grid>
            </Grid>
        );
    };

    const renderOrderDetail = () => (
        <Card className="left-container">
            <Box p={3} style={{ overflow: "auto" }}>
                <Box display="flex" alignItems="flex-end">
                    <Grid container xs={12} md={12}>
                        <Grid container alignItems="flex-end">
                            <Grid xs={8} md={12}>
                                <Autocomplete
                                    disabled={model.id ? true : false}
                                    data={partnerList || []}
                                    label={Strings.ORDER.CUSTOMER}
                                    placeholder={Strings.ORDER.GUEST}
                                    defaultValue={model.buyer}
                                    onChange={async (value) => {
                                        onValueChange(value, "buyer");
                                    }}
                                />
                            </Grid>
                            {/* <Grid md={2}>
                                <IconButton onClick={() => { setModelAddCustomer(true) }}>
                                    <AddCircleOutline />
                                </IconButton>
                            </Grid> */}
                        </Grid>
                        {model.buyer && (
                            <Grid container>
                                <Grid container mt={1} ml={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography fontSize={11} variant="body2" fontWeight="regular">{`${Strings.CUSTOMER.LOYALTY_CLASS}: ${modelView.memberLoyalty ? modelView.memberLoyalty : Strings.CUSTOMER.NOT_LOYALTY_CLASS
                                            }`}</Typography>
                                    </Grid>
                                    {modelView.memberLoyalty && (
                                        <Grid item xs={12} md={6}>
                                            <Typography fontSize={11} variant="body2" fontWeight="regular">{`${Strings.CUSTOMER.POINT}: ${modelView.loyaltyPoint && modelView.loyaltyPoint
                                                }`}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box pt={1} display="flex" alignItems="flex-end">
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Strings.ORDER.TOTAL_PRODUCT}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {modelView.totalItem}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Strings.ORDER.TOTAL_PRICE}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Helpers.formatCurrency(Number(modelView.amount || 0), 2)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* promotion */}
                            {/* <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid container xs={12} md={12}>
                                    <Grid item xs={6} md={7}>
                                        <Typography fontSize={12} variant="body2" fontWeight="bold" className="text-underline" onClick={() => { setModelPromotionProgram(true); }}>{Strings.ORDER.APPLY_PROMOTIONS_SHORT}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <Typography fontSize={12} variant="body2" fontWeight="bold">{Helpers.formatCurrency(Number(modelView.amountApplyPromotion || 0), 2)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} md={12} alignItems="center" mr={3}>
                                    {
                                        model.promotionIds?.map((promotionId: string) => {
                                            return (
                                                promotionProgramTable?.rows?.find((row: any) => row.id === promotionId)?.code ?
                                                    <Grid container xs={6} md={4} alignItems="center">
                                                        <Grid item xs={6} md={8}>
                                                            <Typography fontSize={12} variant="body2" fontWeight="regular">{`${promotionProgramTable?.rows?.find((row: any) => row.id === promotionId)?.code}`}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} md={1} onClick={() => handleRemovePromotion(promotionId)}>
                                                            <IconButton>
                                                                <Close color="error" style={{ fontSize: "14px" }} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    : null
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid> */}

                            <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Strings.ORDER.LOYALTY_CLASS_DISCOUNT}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Helpers.formatCurrency(Number(modelView.loyaltyClassDiscount || 0), 2)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Strings.ORDER.VAT_TAX}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Helpers.formatCurrency(Number(modelView.tax || 0), 2)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid container xs={12} md={12} alignItems="center">
                                    <Grid container xs={6} md={7} alignItems="center">
                                        <Grid item xs={6} md={7}>
                                            <Typography fontSize={12} variant="body2" fontWeight="bold" className="text-underline" onClick={() => { setModalVoucher(true) }}>{Strings.ORDER.VOUCHER}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <Typography fontSize={12} variant="body2" fontWeight="bold">{Helpers.formatCurrency(Number(modelView.amountApplyVoucher || 0), 2)}</Typography>
                                    </Grid>
                                </Grid>
                                {model.voucherCodes && model.voucherCodes?.length > 0 &&
                                    <Grid container xs={12} md={12} alignItems="center" mr={3}>

                                        <Grid container xs={12} md={12} alignItems="center">
                                            <Grid item xs={6} md={7}>
                                                <Typography fontSize={12} variant="body2" fontWeight="regular">{`${Strings.ORDER.VOUCHER_COCE}: ${model.voucherCodes[0]}`}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={1} onClick={async () => {
                                                handleApplyVoucherCode([])
                                            }}>
                                                <IconButton>
                                                    <Close color="error" style={{ fontSize: "14px" }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                }
                            </Grid> */}

                            {/* <Grid container alignItems="center" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {Strings.Common.USE}
                                        <FormField
                                            hideBorder
                                            // style={{ textAlign : "center" }}
                                            disabled={Number(model.type) === OrderType.Book ? true : false}
                                            containerClassName="inputQuantity"
                                            value={model.usePoint || 0}
                                            type="number"
                                            onChangeValue={async (value: number) => {
                                                onValueChange(value, "usePoint")
                                            }}
                                        />
                                        {Strings.CUSTOMER.POINT_SHORT}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">{Helpers.formatCurrency(Number(modelView.amountApplyPoint || 0), 2)}</Typography>
                                </Grid>
                            </Grid> */}

                            <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={15} variant="body2" fontWeight="bold" color="error">
                                        {Strings.ORDER.TOTAL_MONEY_PAYMENT}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={15} variant="body2" fontWeight="bold" color="error">
                                        {Helpers.formatCurrency(Number(modelView.totalAmountPay || 0), 2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!Helpers.isNullOrEmpty(modelView.amountExchanged) && (
                                <Grid container alignItems="flex-end" ml={1} mt={1}>
                                    <Grid item xs={6} md={7}></Grid>
                                    <Grid item xs={6} md={5}>
                                        <Typography
                                            fontSize={12}
                                            variant="body2"
                                            fontWeight="bold"
                                            color="secondary"
                                        >{`~(${modelView.amountExchanged})`}</Typography>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container alignItems="flex-start" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography
                                        fontSize={12}
                                        variant="body2"
                                        fontWeight="bold"
                                        className="text-underline"
                                        onClick={() => {
                                            setModalChoosePaymentType(true);
                                        }}
                                    >
                                        {Strings.ORDER.PAYMENT_METHOD}
                                    </Typography>
                                    {/* <Typography fontSize={11} variant="body2">{"(Chọn nhiều phương thức thanh toán)"}</Typography> */}
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">
                                        {modelView.paymentName}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">{Strings.ORDER.CASH}</Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <FormField
                                        value={model.receiveAmount}
                                        // label={}
                                        // placeholder={}
                                        isMoney
                                        // inputProps={{ min: 0 }}
                                        type="number"
                                        onChangeValue={(value: any) => {
                                            onValueChange(value, "receiveAmount")
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} />
                                {
                                    // (!Helpers.isNullOrEmpty(model.receiveAmount) && Number(modelView.totalAmountPay) > 0 && Number(model.receiveAmount) > 0) &&
                                    isSuggestMoney &&
                                    <Grid item xs={12} md={5}>
                                        <Box mt={1} display="flex" justifyContent="center">
                                            {
                                                handleSuggestAmount()
                                            }
                                        </Box>
                                    </Grid>
                                }
                            </Grid> */}

                            {/* <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={7}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">{Strings.ORDER.CHANGE_AMOUNT}</Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography fontSize={12} variant="body2" fontWeight="bold">{(model.receiveAmount && model.receiveAmount > 0 && modelView.totalAmountPay) ? Helpers.formatCurrency((model.receiveAmount) - (modelView.totalAmountPay)) : 0}</Typography>
                                </Grid>
                            </Grid> */}

                            {/* <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={6} md={4}>
                                    <ControlCheckbox
                                        disabled
                                        sx={{ mt: 2 }}
                                        label={Strings.ORDER.SHIP}
                                        onChangeValue={(value) => { }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <ControlCheckbox
                                        sx={{ mt: 2 }}
                                        value={Number(model.orderType === OrderType.Book) ? true : false}
                                        label={Strings.ORDER.ORDER_BOOKING}
                                        onChangeValue={(value) => {
                                            onValueChange(value, "orderType")
                                        }}
                                    />
                                </Grid>
                            </Grid> */}

                            <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <FormField
                                    value={model.note}
                                    label={Strings.Common.NOTE}
                                    placeholder={Strings.Common.NOTE}
                                    onChangeValue={(value: any) => {
                                        onValueChange(value, "note");
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Grid container direction={"row"} justifyContent="center" spacing={3} sx={{ padding: "20px" }} mt={1} ml={1}>
                <Button
                    color={model?.id ? "secondary" : "error"}
                    style={{ marginRight: 5 }}
                    onClick={() => {
                        if (model?.id) {
                            handleGoBack();
                        } else {
                            setModel(initialModel);
                            setModelView(initialModelView);
                            setTableItemList([]);
                            // setIsConfirmOrder(false)
                        }
                    }}
                >
                    {model?.id ? Strings.Common.GO_BACK : Strings.Common.CANCEL_ORDER}
                </Button>
                <Button color="info" onClick={() => handlePayment()}>
                    {model?.id ? Strings.Common.UPDATE : Strings.ORDER.CREATE_ORDER}
                </Button>
            </Grid>
        </Card>
    );

    const handleApplyVoucherCode = async (code: string[]) => {
        let data: IModel = { ...model };
        let dataView: IViewScreen = { ...modelView };
        data.voucherCodes = code;
        dispatch(showLoading(true));
        await handlePricing(tableItemList, data, dataView);
        dispatch(showLoading(false));
    };

    const handleCloseVoucher = async () => {
        try {
            dispatch(showLoading(true));
            setModalVoucher(false);
            let data: IModel = { ...model };
            let dataView: IViewScreen = { ...modelView };

            dataView.billDiscount = undefined;
            dataView.discountType = undefined;
            data.voucherCodes = [];
            data.billDiscount = undefined;
            await handlePricing(tableItemList, data, dataView);
            // setModel({ ...data });
            // setModelView({ ...dataView });
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    //Voucher áp dụng toàn hóa đơn
    const modalVoucherOrderView = () => {
        return (
            <Modal
                fullWidth
                maxWidth={"xs"}
                hasActionButton
                buttonAction={Strings.Common.APPLY}
                closeButton={Strings.Common.NOT_APPLY}
                title={Strings.ORDER.VOUCHER}
                visible={modalVoucher || false}
                // onClickCloseIcon={() => { setModalVoucher(false) }}
                onClose={() => {
                    handleCloseVoucher();
                }}
                onAction={async () => {
                    if (modelView.discountType && !modelView.billDiscount) {
                        dispatch(setDataAlert({ message: Strings.Message.PLEASE_ENTER_THE_DISCOUNT_VALUE, type: "warning" }));
                    } else {
                        dispatch(showLoading(true));
                        setModalVoucher(false);
                        await handlePricing(tableItemList, model, modelView);
                        dispatch(showLoading(false));
                    }
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid container alignItems="center" ml={1} mt={1}>
                                <Grid item xs={12} md={5}>
                                    <Typography fontSize={14} variant="body2" fontWeight="bold">
                                        {Strings.ORDER.VOUCHER_TYPE}
                                    </Typography>
                                </Grid>
                                <Grid container xs={12} md={7}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            name="radio-buttons-group"
                                            aria-labelledby="radio-buttons-group-label"
                                            value={modelView.discountType}
                                            onChange={(event, value) => {
                                                onValueChange(value, "discountType");
                                            }}
                                        >
                                            <FormControlLabel value={DiscountType.Percent} control={<Radio />} label={Strings.ORDER.PERCENT} />
                                            <FormControlLabel value={DiscountType.Money} control={<Radio />} label={Strings.ORDER.CASH} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-end" ml={1}>
                                <Grid item xs={12} md={5}>
                                    <Typography fontSize={14} variant="body2" fontWeight="bold">
                                        {Strings.ORDER.VOUCHER_VALUE}
                                    </Typography>
                                </Grid>
                                <Grid container xs={12} md={7}>
                                    <FormField
                                        type="number"
                                        inputProps={Number(modelView.discountType) === DiscountType.Percent ? { max: 100 } : {}}
                                        value={modelView.billDiscount}
                                        label={Strings.ORDER.VALUE}
                                        placeholder={Strings.ORDER.VALUE_INPUT}
                                        onChangeValue={(value: any) => {
                                            onValueChange(value, "billDiscount");
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-end" ml={1} mt={1}>
                                <Grid item xs={12} md={5}>
                                    <Typography fontSize={14} variant="body2" fontWeight="bold">
                                        {Strings.ORDER.VOUCHER_COCE}
                                    </Typography>
                                </Grid>
                                <Grid container xs={12} md={7}>
                                    <FormField
                                        disabled={Number(model.type) === OrderType.Book ? true : false}
                                        value={model.voucherCodes?.[0] || ""}
                                        label={Strings.ORDER.DISCOUNT_CODE}
                                        placeholder={Strings.ORDER.DISCOUNT_CODE_INPUT}
                                        onChangeValue={(value: any) => {
                                            onValueChange([value], "voucherCodes");
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        );
    };

    //Tên item trong combo
    const handleShowComboName = (comboName: string[]) => {
        return (
            <ReadMore>
                {comboName?.map((el: string) => {
                    return <p style={{ fontSize: "12px", fontWeight: 500, color: "darkgrey" }}>{`- ${el}`}</p>;
                })}
            </ReadMore>
        );
    };
    //Số tiền gợi ý
    // const handleSuggestAmount = () => {
    //     let arr: any[] = [];
    //     for (let i = 0; i <= Math.ceil(modelView.totalAmountPay)?.toString().length; i++) {
    //         if ((model.receiveAmount * Math.pow(10, i)) >= (modelView.totalAmountPay * 10)) { continue; }
    //         let temp = model.receiveAmount * Math.pow(10, i);
    //         Math.ceil(temp)?.toString().length >= 5 && arr.push(temp)
    //     }
    //     return arr.map((el: number) => {
    //         return (
    //             <Button variant="outlined" color="secondary" style={{ marginRight: 3 }} onClick={() => {
    //                 setModel({ ...model, receiveAmount: Number(el) });
    //                 setIsSuggestMoney(false)
    //             }}>
    //                 {Helpers.formatCurrency(el || 0)}
    //             </Button>
    //         )
    //     })
    // }

    const modalChoosePaymentTypeView = () => {
        return (
            <Modal
                fullWidth
                maxWidth={"xs"}
                hasActionButton
                // title={Strings.Order.ADD_NEW}
                visible={modalChoosePaymentType || false}
                onClose={() => setModalChoosePaymentType(false)}
                onAction={() => {
                    let paymentName: string = "";
                    switch (model.paymentMethod) {
                        case PaymentType.Cash:
                            paymentName = Strings.ORDER.CASH;
                            break;
                        case PaymentType.Banking:
                            paymentName = Strings.ORDER.BANKING;
                            break;
                        case PaymentType.Paypal:
                            paymentName = Strings.ORDER.PAYPAL;
                            break;
                        case PaymentType.VnPay:
                            paymentName = Strings.ORDER.VNPAY;
                            break;
                        default:
                            break;
                    }
                    setModelView({ ...modelView, paymentName });
                    setModalChoosePaymentType(false);
                }}
            >
                <Grid container spacing={1} direction="column">
                    <RadioGroup
                        row
                        name="radio-buttons-group"
                        aria-labelledby="radio-buttons-group-label"
                        value={model.paymentMethod}
                        onChange={(event, value) => {
                            onValueChange(value, "paymentMethod");
                        }}
                    >
                        <Grid item xs={12}>
                            <FormControlLabel value={PaymentType.Cash} control={<Radio />} label={Strings.ORDER.CASH} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel value={PaymentType.Banking} control={<Radio />} label={Strings.ORDER.BANKING} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel value={PaymentType.Paypal} control={<Radio />} label={Strings.ORDER.PAYPAL} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel value={PaymentType.VnPay} control={<Radio />} label={Strings.ORDER.VNPAY} />
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Modal>
        );
    };

    const promotionProgram = () => {
        return (
            <Modal
                fullWidth
                maxWidth={"lg"}
                hasActionButton
                closeButton={Strings.Common.NOT_APPLY}
                buttonAction={Strings.Common.APPLY}
                title={Strings.ORDER.PROMOTION_PROGRAM}
                visible={modelPromotionProgram || false}
                onClose={async () => handleClosePromotion()}
                onClickCloseIcon={() => setModelPromotionProgram(false)}
                onAction={async () => {
                    setModelPromotionProgram(false);
                    onValueChange(promotionIds, "promotionIds");
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DataTable
                            allowCheckbox
                            showFooter={false}
                            table={promotionProgramTable}

                            disabledCheckdbox
                            dataSelected={promotionIds || []}
                            handleOnChangeSelected={(data) => setPromotionIds([...data || []])}

                            totalCount={0}
                            pageNumber={1}
                            rowPerPage={Constants.ROW_PER_PAGE}
                            onChangePageSize={(pageSize) => { }}
                            onChangePageNumber={(pageNumber) => { }}
                        />
                    </Grid>
                </Grid>
            </Modal>
        );
    };

    const suggestQuantityView = () => {
        return (
            <Modal
                fullWidth
                maxWidth={"lg"}
                hasActionButton
                closeButton={Strings.Common.CLOSE}
                title={Strings.ORDER.PROMOTION_ITEM}
                visible={modelTablePromotion || false}
                onClose={() => {
                    setModelTablePromotion(false);
                }}
                onClickCloseIcon={() => {
                    setModelTablePromotion(false);
                }}
            >
                <Grid item xs={12}>
                    {/* <TableContainer sx={{ overflow: "auto", height: 200 }}> */}
                    <Table aria-label="simple table">
                        <TableHead style={{ display: "contents" }}>
                            <TableRow className="stickyHeader">
                                <TableCell colSpan={1}>{Strings.ORDER_TABE_HEADER.SEQUENCE}</TableCell>
                                <TableCell colSpan={2}>{Strings.ORDER_TABE_HEADER.IMAGE}</TableCell>
                                <TableCell colSpan={4}>{Strings.ORDER_TABE_HEADER.NAME}</TableCell>
                                <TableCell colSpan={1}>{Strings.ORDER_TABE_HEADER.QUANTITY}</TableCell>
                                {/* <TableCell colSpan={2}>{"Giới hạn số lượng"}</TableCell> */}
                                <TableCell colSpan={2}>{Strings.ORDER_TABE_HEADER.PRICE}</TableCell>
                                <TableCell colSpan={2}>{Strings.ORDER_TABE_HEADER.DISCOUNT}</TableCell>
                                <TableCell colSpan={2}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableItemPromotion?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={11}>
                                        <Box textAlign="center">
                                            <Typography variant="button" color="secondary" fontWeight="regular">
                                                {Strings.Common.NO_DATA}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                tableItemPromotion.map((row: any, index: number) => (
                                    <TableRow
                                        key={row.id}
                                    // style={{display: "flex", alignItems: "flex-end"}}
                                    >
                                        <TableCell colSpan={1}>{index + 1}</TableCell>
                                        <TableCell colSpan={2}>
                                            <img className="img-Item" alt="" src={Helpers.getFileAccessUrl(row.image)} />
                                        </TableCell>
                                        <TableCell colSpan={4}>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant="caption" fontWeight="medium" color="text">
                                                    {row.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <FormField
                                                value={row.quantity}
                                                type="number"
                                                inputProps={{ min: 1, max: Number(row.suggestQuantity) }}
                                                // style={{ width: "40%" }}
                                                hideBorder
                                                onChangeValue={(value: any) => {
                                                    if (value > 0 && value <= Number(row.suggestQuantity)) {
                                                        row.quantity = value;
                                                        setTableItemPromotion([...tableItemPromotion]);
                                                        // handlePricing(tableItemList, modelView.applyPricingType, model.buyer)
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        {/* <TableCell colSpan={2}>{Helpers.formatCurrency(Number(row.suggestQuantity || 0))}</TableCell> */}
                                        <TableCell colSpan={2}>{Helpers.formatCurrency(Number(row.price || 0), 2)}</TableCell>
                                        <TableCell colSpan={2}>
                                            {Helpers.formatCurrency(Number((row.suggestDiscount / row.suggestQuantity) * row.quantity || 0), 2)}
                                        </TableCell>
                                        {/* <TableCell colSpan={2}>{Helpers.formatCurrency(Number((row?.price - (row.suggestDiscount / row.suggestQuantity)) * row.quantity || 0))}</TableCell> */}
                                        <TableCell colSpan={2}>
                                            <Typography
                                                onClick={async () => {
                                                    dispatch(showLoading(true));
                                                    await handleAddItem(row.id, row);
                                                    dispatch(showLoading(false));
                                                }}
                                                style={{ cursor: "pointer" }}
                                                fontSize={14}
                                                variant="body2"
                                                color="success"
                                                fontWeight="bold"
                                            >
                                                {Strings.Common.APPLY}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                    {/* </TableContainer> */}
                </Grid>
            </Modal>
        );
    };

    const [tabList, setTabList] = useState<string[]>([Strings.ORDER.ORDER_ONE]);
    const [tabValue, setTabValue] = useState<number>(0);
    // const [orderList, setOrderList] = useState<any[]>([]) //mảng order lưu vào local
    const handleAddNewOrder = async (isExits: boolean, tab?: any) => {
        //orderTarget chỉ truyền khi order đã được lưu
        try {
            // dispatch(showLoading(true));
            let tabTemp: string[] = [...(tabList || [])];
            const listOrderLocal = Helpers.getItemInLocalStorage(Strings.ORDER.ORDER_LIST);
            // setIsConfirmOrder(false)
            if (isExits) {
                listOrderLocal?.forEach((el: any, i: number) => {
                    if (i === tabValue) {
                        el.model = { ...model };
                        el.modelView = { ...modelView };
                        el.tableItemList = tableItemList;
                    }
                });
                Helpers.setItemInLocalStorage(Strings.ORDER.ORDER_LIST, listOrderLocal); //lưu ds order lên local
            } else {
                dispatch(showLoading(true));
                if (tab) {
                    let listOrderTemp: any[] = [...listOrderLocal];
                    listOrderTemp.push({
                        model: { ...model },
                        modelView: { ...modelView },
                        tableItemList,
                    });
                    Helpers.setItemInLocalStorage(Strings.ORDER.ORDER_LIST, listOrderTemp);
                } else {
                    //set initial cho tab mới
                    await handleResetData();

                    //get curentName
                    //let tabLength = tabTemp.length + 1;
                    let currentName = tabTemp[tabTemp.length - 1];
                    let currentNameNumber = currentName?.split(" ")[1];
                    tabTemp.push(`Đơn ${Number(currentNameNumber || 0) + 1}`);
                    setTabValue(tabTemp.length - 1);
                    setTabList(tabTemp);
                    let listOrderTemp: any[] = [...listOrderLocal];

                    // let isExits = listOrderTemp?.find((el: any, i: number) => i === (tabTemp.length - 2));
                    // if (!isExits) {
                    listOrderTemp.push({
                        model: { ...model },
                        modelView: { ...modelView },
                        tableItemList,
                        //tabValue: tabTemp.length - 2
                    });
                    Helpers.setItemInLocalStorage(Strings.ORDER.ORDER_LIST, listOrderTemp); //lưu ds order lên local
                    // //set initial cho tab mưới
                    // setModel(initialModel)
                    // setModelView(initialModelView)
                    // setTableItemList([])
                    // }
                }
                dispatch(showLoading(false));
            }
            // dispatch(showLoading(false));
        } catch (err) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(err);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleTabChange = async (value: number, action?: number) => {
        try {
            dispatch(showLoading(true));
            //set initial cho tab mới
            await handleResetData();
            // setIsConfirmOrder(false)

            const listOrderTemp = Helpers.getItemInLocalStorage(Strings.ORDER.ORDER_LIST);

            //nếu không phải delete thì xét trường hợp add item
            if (action === ActionRequest.Delete) {
            } else {
                //xét nếu tab value hiện tại đã lưu vào local thì update còn ngược lại thì thêm
                if (listOrderTemp.length > 0) {
                    let isExits = listOrderTemp?.find((el: any, i: number) => i === tabValue);
                    //tham số true: là cập nhật lại order đã có, false là thêm mới, tabValue: thêm order tại tabValue khi tabChange
                    isExits ? handleAddNewOrder(true) : handleAddNewOrder(false, tabValue);
                }
            }

            let orderTarget = listOrderTemp?.find((el: any, i: number) => i === value);
            if (orderTarget?.model?.buyer) {
                // let modelViewResult = await getDetailPartner(orderTarget?.model?.buyer);
                // let voucherCode = await getVoucherCodeByCustomer(orderTarget?.model?.buyer);
                // voucherCode?.length > 0 && setTableItemVoucherCode(voucherCode)
                // setModelView({
                //     ...orderTarget?.modelView,
                //     memberLoyalty: modelViewResult.memberLoyalty,
                //     loyaltyPoint: modelViewResult.loyaltyPoint,
                //     debtAmount: modelViewResult.debtAmount
                // });
            } else {
                setModelView(orderTarget?.modelView);
            }
            setModel(orderTarget?.model);
            setTableItemList(orderTarget?.tableItemList);
            await handlePricing(orderTarget?.tableItemList, orderTarget?.model, orderTarget?.modelView);
            dispatch(showLoading(false));
        } catch (err) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(err);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const handleDeleteTab = async (value: number) => {
        dispatch(showLoading(true));

        //nếu mở tab mới mà không changetab hoặc mở thêm tab mới nữa thì khi delete phải gọi để lưu tab mới mở vào local
        const listOrderTemp = Helpers.getItemInLocalStorage(Strings.ORDER.ORDER_LIST);
        if (listOrderTemp.length > 0) {
            let isExits = listOrderTemp?.find((el: any, i: number) => i === tabValue);
            //tham số true: là cập nhật lại order đã có, false là thêm mới, tabValue: thêm order tại tabValue khi tabChange
            !isExits && handleAddNewOrder(false, tabValue);
        }

        let pageNumber = 0;
        if (tabList.length > 1) {
            const listOrderTemp = Helpers.getItemInLocalStorage(Strings.ORDER.ORDER_LIST);
            let listOrderRs = listOrderTemp?.filter((el: any, i: number) => i !== value);
            let tabListTemp = tabList?.filter((el: any, i: number) => i !== value);
            setTabList(tabListTemp);
            Helpers.setItemInLocalStorage(Strings.ORDER.ORDER_LIST, listOrderRs); //lưu ds order lên local

            if (value === tabList.length - 1) {
                if (tabValue === tabList.length - 1) {
                    pageNumber = tabValue - 1;
                } else {
                    pageNumber = tabValue;
                }
            }

            if (value === 0) {
                if (tabValue === 0) {
                    pageNumber = 0;
                } else {
                    pageNumber = tabValue - 1;
                }
            }

            if (value < tabList.length - 1 && value > 0) {
                if (tabValue === tabList.length - 1) {
                    pageNumber = tabValue - 1;
                } else if (tabValue === 0) {
                    pageNumber = 0;
                } else if (value < tabValue) {
                    pageNumber = tabValue - 1;
                } else {
                    pageNumber = tabValue;
                }
            }
            setTabValue(pageNumber);
            await handleTabChange(pageNumber, ActionRequest.Delete); //truyền trường hợp delete xong gọi tabChange
        } else {
            await handleResetData(); //reset data order target
            // setIsConfirmOrder(false)
            Helpers.setItemInLocalStorage(Strings.ORDER.ORDER_LIST, []);
        }
        dispatch(showLoading(false));
    };

    const handleSearchItem = async (data: any) => {
        try {
            let response: ICodename[] = [];
            const result = await itemService.getPaged(Helpers.handleFormatParams(data));
            result.items?.forEach(async (el: any) => {
                let price: number = el.itemPrices?.find(
                    (e: any) =>
                        moment().unix() >= e.startTime &&
                        moment().unix() <= e.endTime &&
                        (e.currency === model.location || e.currency === Constants.CURRENCY_DEFAULT)
                )?.price;
                response.push({
                    id: el.id,
                    code: el.id,
                    name: `${el.name?.value.vi}`,
                    detail: { ...el, name: `${el.name?.value.vi}` },
                });
            });
            return {
                data: response,
                currentPage: result.currentPage,
                hasNext: result.hasNext,
            };
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const header = () => {
        return (
            <Card>
                <Box p={3} sx={headerContent}>
                    <Grid container justifyContent="space-around">
                        <Grid container xs={12} md={6}>
                            <Grid item xs={1} md={1}>
                                <IconButton onClick={() => handleGoBack()}>
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10} md={9}>
                                <Box sx={tabContainer}>
                                    {tabList?.map((e, index) => (
                                        <Box
                                            key={index}
                                            sx={(theme: Theme) => tab(theme, { isActive: true })}
                                            onClick={async () => {
                                                if (index !== tabValue) {
                                                    setTabValue(index);
                                                    handleTabChange(index);
                                                }
                                            }}
                                        >
                                            <Typography sx={tabText}>{e}</Typography>
                                            <IconButton onClick={() => handleDeleteTab(index)} sx={{ width: "20%" }}>
                                                <Close sx={{ width: 20, height: 20 }} />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={1} md={1}>
                                <IconButton onClick={() => handleAddNewOrder(false)}>
                                    <AddCircleOutlineOutlined />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} md={4}>
                            <Box width={"85%"}>
                                <SearchInputAPI
                                    // key={model?.groupId}
                                    getApi={(data) => handleSearchItem(data)}
                                    placeholder={Strings.ORDER.SEARCH}
                                    value={orderDetail?.itemId || ""}
                                    onChangeValue={(value) =>
                                        setOrderDetail({
                                            ...orderDetail,
                                            itemId: value,
                                        })
                                    }
                                    isSearchOrder
                                    onClick={async (value) => {
                                        if (value) {
                                            dispatch(showLoading(true));
                                            await handleAddItem(`${value.code}`, false, value.detail);
                                            setOrderDetail({
                                                ...orderDetail,
                                                itemId: undefined,
                                            });
                                            dispatch(showLoading(false));
                                        }
                                    }}
                                    requestGetApiDefault={{
                                        organizationId: userProfile?.organizationId || undefined,
                                        types: undefined,
                                    }}
                                // actionCreateNewItem={() => setModelAddItem(true)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        );
    };

    return (
        <PageLayout>
            <Box mt={2} ml={2} mr={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {header()}
                    </Grid>
                    <Grid item xs={12} md={8} lg={8.5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card>{listItem()}</Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3.5}>
                        {renderOrderDetail()}
                    </Grid>
                </Grid>
            </Box>
            {modalChoosePaymentTypeView()}
            {modalVoucherOrderView()}
            {promotionProgram()}
            {suggestQuantityView()}
        </PageLayout>
    );
};

export default OrderEditScreen;
