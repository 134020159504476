/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Theme } from "@mui/material/styles";

function navbar(theme: Theme | any, ownerState: any) {
    const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
    const { transparentNavbar, absolute, light, darkMode } = ownerState;

    const { dark, white, text, background } = palette;
    const { navbarBoxShadow } = boxShadows;
    const { rgba, pxToRem } = functions;
    const { borderRadius } = borders;

    return {
        boxShadow: transparentNavbar || absolute ? "none" : navbarBoxShadow,
        backdropFilter: transparentNavbar || absolute ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
        backgroundColor:
            transparentNavbar || absolute
                ? "transparent !important"
                : rgba(darkMode ? background.default : white.main, 0.8),

        color: () => {
            let color;

            if (light) {
                color = white.main;
            } else if (transparentNavbar) {
                color = text.main;
            } else {
                color = dark.main;
            }

            return color;
        },
        top: absolute ? 0 : pxToRem(12),
        minHeight: pxToRem(75),
        display: "grid",
        alignItems: "center",
        borderRadius: borderRadius.xl,
        padding: "8px",
        width: "auto",
        "& > *": {
            transition: transitions.create("all", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "& .MuiToolbar-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            [breakpoints.up("sm")]: {
                minHeight: "auto",
                padding: `${pxToRem(4)} ${pxToRem(16)}`,
            },
        },
    };
}

const navbarContainer = ({ breakpoints }: Theme, { isMini }: any): any => ({
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "0px !important",

    [breakpoints.up(1024)]: {
        flexDirection: "row",
        alignItems: "start",
    },
    [breakpoints.between(900, 1024)]: {
        flexDirection: isMini ? "row" : "column",
        alignItems: "start",
    },
});

const navbarRow = ({ breakpoints }: Theme, { isMini }: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    [breakpoints.up(1024)]: {
        justifyContent: isMini ? "space-between" : "stretch",
        width: isMini ? "100%" : "max-content",
    },

    [breakpoints.between(900, 1024)]: {
        display: isMini ? "stretch" : "flex",
        justifyContent: "space-between",
    },

    [breakpoints.up("xl")]: {
        justifyContent: "stretch !important",
        width: "max-content !important",
    },
});

const navbarIconButton = ({ typography: { size }, breakpoints }: Theme) => ({
    pr: 1, pl: 0,

    "& .material-icons, .material-icons-round": {
        fontSize: `40px !important`,
    },

    "& .MuiTypography-root": {
        display: "none",

        [breakpoints.up("sm")]: {
            display: "inline-block",
            lineHeight: 1.2,
            ml: 0.5,
        },
    },
});

const navbarDesktopMenu = ({ breakpoints }: Theme) => ({
    display: "none !important",
    cursor: "pointer",

    [breakpoints.up("xl")]: {
        display: "inline-block !important",
    },
});

const navbarMobileMenu = ({ breakpoints }: Theme) => ({
    display: "inline-block",
    lineHeight: 0,

    [breakpoints.up("xl")]: {
        display: "none",
    },
});

const navbarProfile = ({ breakpoints }: Theme, { isMni }: any) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    [breakpoints.up("md")]: {
        marginRight: "5px"
    },

})

export {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarDesktopMenu,
    navbarMobileMenu,
    navbarProfile,
};
