import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";

import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "../../constants";
import OrderService from "services/sale/order.service";
import AssetService from "services/asset/asset.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, IFileUploaderRes, IInput, ITitleRoute } from "commons/interfaces";
import { DiscountDetailType, OrderStatus, PaymentType, Status } from "constants/enum";
import { Autocomplete, Box, Button, DataTable, Modal, Typography, MultipleImageUploader } from "@maysoft/common-component-react";
import { StatusDetail } from "components";

interface DataEdit {
    status: number;
    orderDate: number;
    orderCode: string;
    salesAgent: string;
    // salesBranch: string;
    customerName: string;
    customerPhone: string;
    customerAddress: string;
    returnOrderCode: string;
    applyPricingType: number;
    applyPricingTypeName: string;

    totalProduct: number;
    totalPrice: number;
    vatTax: number;
    applyPromotions: number;
    orderDiscount: number;
    loyaltyPoint: number;
    totalAmountPaid: number;
    paymentMethod: number;
    paymentMethodName: string;
    customerPaid: number;
    moneyBack: number;
    usePoint?: number;
    note?: string;
    loyaltyClassDiscount?: number;
    amountExchanged?: string;
}

interface OrderHistories {
    id: string;
    orderDate: number;
    orderStatus: number;
    staffName: string;
}

interface ICompleteOrder {
    orderId: string;
    paymentMethod: IInput;
    documents: [
        {
            documentId: string;
            documentUrl: string;
        }
    ];
}

interface IModel {
    dataTable: {
        columns: any[];
        rows: any[];
    };
    orderHistories: OrderHistories[];
    dataEdit: DataEdit;

    edited: boolean;

    completeOrderRequest?: ICompleteOrder;

    title: string;
    route: ITitleRoute[];
}

const Item = ({ title, value, color }: any) => (
    <>
        <Grid item xs={6}></Grid>
        <Grid item xs={4}>
            <Typography fontSize={12} variant="body2" fontWeight="bold" color={color}>
                {title}
            </Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography fontSize={12} variant="body2" fontWeight="bold" color={color}>
                {Helpers.isNumber(value) ? Helpers.formatCurrency(value, 2) : value}
            </Typography>
        </Grid>
    </>
);

const ViewOrderScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const orderService = new OrderService();
    const assetService = new AssetService();

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);

    const [modelCompleteOrder, setModelCompleteOrder] = useState<boolean>(false);
    const [paymentTypeList] = useState<ICodename[]>([
        {
            code: `${PaymentType.Banking}`,
            name: Strings.ORDER.BANKING,
        },
        {
            code: `${PaymentType.Cash}`,
            name: Strings.ORDER.CASH,
        },
        {
            code: `${PaymentType.Paypal}`,
            name: Strings.ORDER.PAYPAL,
        },
        {
            code: `${PaymentType.VnPay}`,
            name: Strings.ORDER.VNPAY,
        },
    ]);

    const [photoRequest, setPhotoRequest] = useState<IFileUploaderRes[]>([]);

    const [model, setModel] = useState<IModel>({
        dataTable: {
            columns: [
                { Header: Strings.ORDER.SEQUENCY, accessor: "sequency", width: "20px" },
                {
                    Header: Strings.ORDER.IMAGE,
                    accessor: "image",
                    width: "50px",
                    Cell: ({ value }: any) => <img className="img-Item" alt="" src={Helpers.getFileAccessUrl(value)} />,
                },
                { Header: Strings.ORDER.PRODUCT_CODE, accessor: "code", width: "80px" },
                {
                    Header: Strings.ORDER.PRODUCT_NAME,
                    accessor: "name",
                    width: "150px",
                    Cell: (row: any) => {
                        return (
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant="caption" fontWeight="medium" color="text">
                                        {row.value}
                                    </Typography>
                                </Box>
                                {(row.row?.original?.comboItemDetail || []).map((item: any, index: number) => (
                                    <Typography
                                        key={index}
                                        variant="caption"
                                        fontWeight="medium"
                                        color="info"
                                        sx={{ fontSize: 12, display: "block" }}
                                    >
                                        - {item.quantity}x {item.name}
                                    </Typography>
                                ))}
                                {row.row?.original?.status === Status.Deleted && (
                                    <Typography variant="caption" fontWeight="medium" color="error" sx={{ fontSize: 10 }}>
                                        {Strings.ORDER.THE_PRODUCT_HAS_BEEN_REMOVED}
                                    </Typography>
                                )}
                            </>
                        );
                    },
                },
                { Header: Strings.ORDER.QUANTITY, accessor: "quantity", width: "50px" },
                { Header: Strings.ORDER.PRICE, accessor: "price", width: "80px" },
                {
                    Header: Strings.ORDER.TOTAL,
                    accessor: "total",
                    width: "80px",
                    Cell: (row: any) => {
                        return (
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant="caption" fontWeight="medium" color="text">
                                        {row.value}
                                    </Typography>
                                </Box>
                                {row.row?.original?.unitPriceExchanged && (
                                    <Typography variant="caption" fontWeight="medium" color="info" sx={{ fontSize: 12, display: "block" }}>
                                        ~({row.row?.original?.unitPriceExchanged})
                                    </Typography>
                                )}
                            </>
                        );
                    },
                },
            ],
            rows: [],
        },
        title: Strings.ORDER.TITLE_DETAIL_VIEW,
        route: [
            {
                title: Strings.ORDER.TITLE_MENU,
                route: Screens.ORDER_LIST,
            },
            { title: Strings.Common.DETAIL, route: "" },
        ],
    } as IModel);

    const { id } = Helpers.getUrlParams(["id"]);

    useEffect(() => {
        getData();
    }, []);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.ORDER_LIST);
        navigate(Screens.ORDER_LIST + (itemPathName ? itemPathName.query : ""));
    };

    const getData = async () => {
        try {
            dispatch(showLoading(true));
            const result = await orderService.getDetail(id);

            let rows: any[] = [];

            (result?.orderDetails || []).forEach((e: any, index: number) => {
                let comboItemDetail: any[] = [];

                if (!Helpers.isNullOrEmpty(e.orderDetailComboItems)) {
                    (e?.orderDetailComboItems || []).forEach((itemDetail: any) => {
                        comboItemDetail.push({
                            id: itemDetail.itemId,
                            quantity: itemDetail?.quantity || 1,
                            name: itemDetail.itemName?.value.vi,
                        });
                    });
                }

                rows.push({
                    id: e.itemId,
                    sequency: index + 1,
                    image: e.itemPhotos[0].photoId,
                    code: e.itemSku,
                    name: e.itemName?.value?.vi,
                    quantity: e.quantity,
                    price: Helpers.formatCurrency(e.unitPrice, 2),
                    total: Helpers.formatCurrency(e.amount, 2),
                    // status: e.itemStatus,
                    comboItemDetail: comboItemDetail,
                    unitPriceExchanged: e.unitPriceExchanged?.value,
                });
            });

            let orderHistories: OrderHistories[] = [];
            (result.orderHistories || []).forEach((e: any) =>
                orderHistories.push({
                    id: e.id,
                    staffName: e.createUserName,
                    orderStatus: e.orderStatus,
                    orderDate: Number(e.createTime),
                })
            );

            let amountApplyVoucher: number = 0;
            let amountApplyPoint: number = 0; //số tiền giảm giá khi áp dụng điểm
            let usePoint: number = 0;
            let loyaltyClassDiscount: number = 0; // giảm giá thành viên

            (result?.orderDiscounts || [])?.forEach((el: any) => {
                switch (el.type) {
                    case DiscountDetailType.UsingPoint:
                        amountApplyPoint = Number(el.discount);
                        usePoint = Number(el.discountQuantity);
                        // amountApplyVoucher += Number(el.discount)
                        break;
                    case DiscountDetailType.LoyaltyClassDiscount:
                        // amountApplyVoucher += Number(el.discount)
                        loyaltyClassDiscount = Number(el.discount);
                        break;
                    default:
                        break;
                }
            });

            let dataEdit: any = {
                ...model.dataEdit,
                status: result.orderStatus,
                orderDate: Number(result.orderDate),
                orderCode: result.orderCode,
                salesAgent: result.sellerName,
                // salesBranch: result.organizationBranch?.name?.value?.vi || "",
                customerName: result.buyerName || Strings.ORDER.GUEST,
                customerPhone: result.buyerPhoneNumber,

                totalProduct: result.totalItem,
                totalPrice: result.amount - (result.tax - result.discount),
                vatTax: result.tax,
                applyPromotions: result.discount - amountApplyVoucher - amountApplyPoint - loyaltyClassDiscount,
                orderDiscount: amountApplyVoucher,
                loyaltyPoint: amountApplyPoint, // none
                usePoint,
                totalAmountPaid: result.amount,
                paymentMethod: result.paymentMethod,
                paymentMethodName: getPaymentMethod(result.paymentMethod),
                note: result.note,
                loyaltyClassDiscount,
                amountExchanged: result?.amountExchanged?.value,
            };
            setModel({
                ...model,
                dataEdit,
                orderHistories,
                dataTable: {
                    ...model.dataTable,
                    rows,
                },
            });
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getOrderStatus = (status: number) => {
        switch (status) {
            case OrderStatus.Cancel:
                return Strings.STATUS.ORDER.Cancel;
            case OrderStatus.Pending:
                return Strings.STATUS.ORDER.Pending;
            case OrderStatus.New:
                return Strings.STATUS.ORDER.New;
            case OrderStatus.Confirm:
                return Strings.STATUS.ORDER.Confirm;
            case OrderStatus.Editing:
                return Strings.STATUS.ORDER.Editing;
            case OrderStatus.PaymentConfirm:
                return Strings.STATUS.ORDER.PaymentConfirm;
            case OrderStatus.DebtConfirm:
                return Strings.STATUS.ORDER.DebtConfirm;
            case OrderStatus.ExchangeRequest:
                return Strings.STATUS.ORDER.ExchangeRequest;
            case OrderStatus.ExchangeCancel:
                return Strings.STATUS.ORDER.ExchangeCancel;
            case OrderStatus.Completed:
                return Strings.STATUS.ORDER.Completed;
            default:
                return "";
        }
    };

    const getPaymentMethod = (code: number) => {
        switch (code) {
            case PaymentType.Cash:
                return Strings.ORDER.CASH;
            // case PaymentType.CreditCard:
            //     return Strings.Common.CREDIT_CARD;
            // case PaymentType.OnlinePayment:
            //     return Strings.Common.ONLINE_PAYMENT;
            // case PaymentType.Momo:
            //     return Strings.Common.MOMO;
            default:
                return "";
        }
    };

    const renderStatus = (status: number) => {
        switch (status) {
            case OrderStatus.Cancel:
                return <StatusDetail bgColor="error" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.Cancel} />;
            case OrderStatus.Pending:
                return <StatusDetail bgColor="secondary" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.Pending} />;
            case OrderStatus.New:
                return <StatusDetail bgColor="info" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.New} />;
            case OrderStatus.Confirm:
                return <StatusDetail bgColor="info" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.Confirm} />;
            case OrderStatus.Editing:
                return <StatusDetail bgColor="success" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.Editing} />;
            case OrderStatus.PaymentConfirm:
                return <StatusDetail bgColor="success" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.PaymentConfirm} />;
            case OrderStatus.DebtConfirm:
                return <StatusDetail bgColor="warning" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.DebtConfirm} />;
            case OrderStatus.ExchangeRequest:
                return <StatusDetail bgColor="warning" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.ExchangeRequest} />;
            case OrderStatus.ExchangeCancel:
                return <StatusDetail bgColor="error" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.ExchangeCancel} />;
            case OrderStatus.Completed:
                return <StatusDetail bgColor="success" style={{ marginLeft: 4 }} title={Strings.STATUS.ORDER.Completed} />;
            default:
                <></>;
        }
    };

    const renderOrderInfo = () => (
        <Card>
            <Box p={3}>
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12} sx={{ borderBottom: "1px solid #f3f3f3", pb: 1 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body2" fontWeight="bold">
                                {Strings.ORDER.ORDER_CODE}: {model.dataEdit?.orderCode}
                            </Typography>
                            <>{renderStatus(model.dataEdit?.status)}</>
                        </div>
                        <Typography variant="body2">{Helpers.formatDate(model.dataEdit?.orderDate * 1000, "DD/MM/YYYY HH:mm")}</Typography>
                        <Typography variant="body2">
                            {Strings.ORDER.SALES_AGENT}: {model.dataEdit?.salesAgent}
                        </Typography>
                        {/* <Typography variant="body2">
                            {Strings.ORDER.SALES_BRANCH}: {model.dataEdit?.salesBranch}
                        </Typography> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Typography variant="body2" fontWeight={"bold"}>
                                    {Strings.ORDER.CUSTOMER}:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body2">{model.dataEdit?.customerName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" fontWeight={"bold"}>
                                    {Strings.ORDER.PHONE_NUMBER}:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body2">{model.dataEdit?.customerPhone}</Typography>
                            </Grid>
                            {/* <Grid item xs={3}>
                                <Typography variant="body2" fontWeight={"bold"}>
                                    {Strings.ORDER.ADDRESS}:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body2">{model.dataEdit?.customerAddress}</Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );

    const renderDetailOrder = () => (
        <Card>
            <Box p={3}>
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Typography variant="body2" fontWeight="bold">
                            {Strings.ORDER.DETAIL_PRODUCT}
                        </Typography>
                        {/* <Typography variant="body2" fontWeight="bold">{Strings.ORDER.APPLY_PRICING_TYPE}:
                            <span style={{ fontWeight: "normal" }}> {model.dataEdit?.applyPricingTypeName}</span>
                            </Typography> */}
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            table={model.dataTable}
                            showFooter={false}
                            totalCount={0}
                            pageNumber={1}
                            rowPerPage={Constants.ROW_PER_PAGE}
                            onChangePageSize={(pageSize) => {}}
                            onChangePageNumber={(pageNumber) => {}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Item title={Strings.ORDER.TOTAL_PRODUCT} value={model.dataEdit?.totalProduct} />
                            <Item title={Strings.ORDER.TOTAL_PRICE} value={model.dataEdit?.totalPrice} color="error" />
                            <Item title={Strings.ORDER.APPLY_PROMOTIONS} value={model.dataEdit?.applyPromotions} />
                            <Item title={Strings.ORDER.LOYALTY_CLASS_DISCOUNT} value={model.dataEdit?.loyaltyClassDiscount} />
                            <Item title={Strings.ORDER.VAT_TAX} value={model.dataEdit?.vatTax} />
                            <Item title={Strings.ORDER.ORDER_DISCOUNT} value={model.dataEdit?.orderDiscount} />
                            <Item
                                title={Strings.format(Strings.ORDER.USE_LOYALTY_POINT, Helpers.formatCurrency(model.dataEdit?.usePoint, 2))}
                                value={model.dataEdit?.loyaltyPoint}
                            />
                            <Item title={Strings.ORDER.TOTAL_AMOUNT_PAID_BY_CUSTOMER} value={model.dataEdit?.totalAmountPaid} color="error" />
                            {model.dataEdit?.amountExchanged && <Item title={""} value={`~(${model.dataEdit?.amountExchanged})`} color="error" />}
                            <Item title={Strings.ORDER.PAYMENT_METHOD} value={model.dataEdit?.paymentMethodName} />
                            <Item title={Strings.ORDER.NOTE} value={model.dataEdit?.note} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );

    const renderPaymentHistory = () => (
        <Card>
            <Box p={3}>
                <Typography variant="body2" fontWeight="bold">
                    {Strings.ORDER.PAYMENT_HISTORY}
                </Typography>
                <Stepper activeStep={model.orderHistories?.length - 1} orientation="vertical">
                    {model.orderHistories?.map((item) => (
                        <Step key={item.id}>
                            <StepLabel>
                                {getOrderStatus(item.orderStatus)}
                                <Typography fontSize={11} variant="body2">
                                    {Helpers.formatDate(item.orderDate * 1000, "DD/MM/YYYY HH:mm")}
                                </Typography>
                                <Typography fontSize={11} variant="body2">
                                    {item.staffName}
                                </Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Card>
    );

    const handleCancelOrder = async (id: string) => {
        try {
            dispatch(showLoading(true));
            await orderService.cancel(id);
            await getData();
            dispatch(setDataAlert({ message: Strings.Message.CANCEL_SUCCESS, type: "success" }));
            dispatch(showLoading(false));
        } catch (error: any) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const renderAction = () => (
        <Card>
            <Box p={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            color="error"
                            onClick={() =>
                                Helpers.showConfirmAlert(Strings.Message.ORDER_AFTER_CANCELIATION_CANNOT_BE_RETURNED, () => handleCancelOrder(id))
                            }
                            fullWidth
                        >
                            {Strings.ORDER.CANCEL}
                        </Button>
                    </Grid>
                    {model.dataEdit?.status !== OrderStatus.Cancel && model.dataEdit?.status !== OrderStatus.Completed && (
                        <Grid item xs={12}>
                            <Button color="info" onClick={() => setModelCompleteOrder(true)} fullWidth>
                                {Strings.ORDER.COMPLETE}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Card>
    );

    const onValueChange = async (value: any, key: string) => {
        let data: any = { ...model?.completeOrderRequest };
        data[key] = { value: value, error: "" };

        setModel({ ...model, completeOrderRequest: data });
    };

    const checkedValidated = () => {
        let checked: boolean = true;
        let data: any = { ...model?.completeOrderRequest };

        if (Helpers.isNullOrEmpty(model?.completeOrderRequest?.paymentMethod?.value)) {
            data["paymentMethod"] = { error: Strings.Validation.REQUIRED };
            checked = false;
        }
        setModel({ ...model, completeOrderRequest: data });
        return checked;
    };

    const handleCompleteOrder = async () => {
        if (!checkedValidated()) {
            return;
        } else {
            try {
                dispatch(showLoading(true));

                const photos: any[] = [];
                (photoRequest || [])?.forEach(async (item: IFileUploaderRes) => {
                    photos.push({
                        documentId: item.fileId,
                        documentUrl: item.fileUrl,
                    });
                    await assetService.setActiveById(item.fileId);
                });

                let data: any = {
                    orderId: id,
                    paymentMethod: Number(model?.completeOrderRequest?.paymentMethod?.value),
                    documents: photos,
                };
                const result = await orderService.completeOrder(data);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    dispatch(setDataAlert({ message: Strings.Message.SUCCESS, type: "success" }));
                    await handleCloseModel();
                    await getData();
                }

                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        }
    };

    const handlePhotoChange = (dataPhoto: IFileUploaderRes[]) => {
        let temp: IFileUploaderRes[] = [];
        (dataPhoto || []).forEach((el) => {
            let isExist = temp?.find((e) => e.fileId === el.fileId);
            !isExist &&
                temp.push({
                    fileId: el.fileId,
                    fileUrl: el.fileAccessUrl,
                });
        });
        setPhotoRequest(temp);
    };

    const handleCloseModel = () => {
        setPhotoRequest([]);
        setModel({ ...model, completeOrderRequest: undefined });
        setModelCompleteOrder(false);
    };

    const modalVoucherOrderView = () => {
        return (
            <Modal
                fullWidth
                maxWidth={"sm"}
                hasActionButton
                buttonAction={Strings.Common.COMPLETE}
                closeButton={Strings.Common.CANCEL}
                title={Strings.ORDER.COMPLETE_ORDER}
                visible={modelCompleteOrder || false}
                // onClickCloseIcon={() => { setModalVoucher(false) }}
                onClose={() => handleCloseModel()}
                onAction={() => handleCompleteOrder()}
            >
                <Grid container spacing={1}>
                    <Grid container spacing={1}>
                        <Grid container alignItems="center" ml={1} mt={2}>
                            <Grid item xs={12} md={4}>
                                <Typography fontSize={14} variant="body2" fontWeight="bold">
                                    {Strings.ORDER.PAYMENT_METHOD}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Autocomplete
                                    data={paymentTypeList || []}
                                    placeholder={Strings.ORDER.PAYMENT_METHOD}
                                    defaultValue={model.completeOrderRequest?.paymentMethod?.value}
                                    errorMessage={model.completeOrderRequest?.paymentMethod?.error}
                                    onChange={async (value) => {
                                        onValueChange(value, "paymentMethod");
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center" ml={1} mt={2}>
                            <Grid item xs={12}>
                                <Typography fontSize={14} variant="body2" fontWeight="bold">
                                    {Strings.ORDER.DOCUMENT}
                                </Typography>
                            </Grid>
                            <Grid container xs={12}>
                                <MultipleImageUploader
                                    folderPath={Constants.CloudStorage.SALE_IMAGE_FOLDER}
                                    defaultValue={photoRequest}
                                    onChange={(res) => handlePhotoChange(res)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        );
    };

    return (
        <DashboardLayout
            isPermission
            title={model.title}
            route={model.route}
            nameActionPress={Strings.Common.EDIT}
            onActionPress={
                model.dataEdit?.status === OrderStatus.Cancel || model.dataEdit?.status === OrderStatus.Completed
                    ? null
                    : () => navigate(Screens.ORDER_EDIT + `?id=${id}&type=${OrderStatus.Editing}`, { state: { id: id } }) //type confirm là thanh toán, edit là chỉnh sửa
            }
            onBackPress={() => {
                if (!model.edited) {
                    handleGoBack();
                } else {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, () => {
                        handleGoBack();
                    });
                }
            }}
        >
            <Box mt={3}>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {renderOrderInfo()}
                            </Grid>
                            <Grid item xs={12}>
                                {renderDetailOrder()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {renderPaymentHistory()}
                            </Grid>
                            {model.dataEdit?.status === OrderStatus.Cancel || model.dataEdit?.status === OrderStatus.Completed ? (
                                <></>
                            ) : (
                                <Grid item xs={12}>
                                    {renderAction()}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {modalVoucherOrderView()}
                </Grid>
            </Box>
        </DashboardLayout>
    );
};

export default ViewOrderScreen;
