
import Helpers from "commons/helpers";
import Constants from "../../constants";
import SaleService from "./sale.service";
import { IRequestGetPagedAttribute } from "screens/attribute/container/interface";

class AttributeService extends SaleService {
    public getPaged = async (data: IRequestGetPagedAttribute) => {
        const query = Helpers.handleFormatParams(data);

        const result = await this.api.get({
            path: Constants.ApiPath.ATTRIBUTE.GET_PAGED + `?${query}`,
        });
        return result.data.result;
    }

    public getAll = async (data?: {
        code?: string;
        attributeType?: number;
        organizationId?: string;
    }) => {
        const query = Helpers.handleFormatParams(data);

        const result = await this.api.get({
            path: Constants.ApiPath.ATTRIBUTE.GET_ALL + `?${query}`,
        });
        return result.data.result;
    }

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.ATTRIBUTE.DETAIL}/${id}`,
        });
        return result.data.result;
    }

    public getDetailByCode = async (data: {
        codes: string[];
        organizationId?: string;
    }) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ATTRIBUTE.DETAIL_BY_CODE,
            query: data
        });
        return result.data.result;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ATTRIBUTE.CREATE,
            data: data,
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ATTRIBUTE.UPDATE,
            data: data,
        });
        return result.data;
    }

    public delete = async (id: string) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.ATTRIBUTE.DELETE + `/${id}`,
        });
        return result.data;
    }
}

export default AttributeService;