import Strings from "constants/strings";
import { DashboardLayout } from "layout";
import { Box } from "@maysoft/common-component-react";
import { LoyaltyCustomerContainer } from "@maysoft/sale-common-react";

const SettingLoyalCustomerScreen = () => {
    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={Strings.LOYALTY_CUSTOMER.TITLE_MENU}
            route={[{ title: Strings.LOYALTY_CUSTOMER.TITLE_MENU, route: "" }]}
        >
            <Box mt={2}>
                <LoyaltyCustomerContainer />
            </Box>
        </DashboardLayout>
    );
};

export default SettingLoyalCustomerScreen;
