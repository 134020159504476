import React, { createContext, useContext } from "react";
import { AvailableLanguage } from "constants/enum";

export type ILanguageContext = string; 

export const LanguageContext = createContext<ILanguageContext>("vi");

export const useLanguageContext = () => useContext(LanguageContext);


export interface ILanguageProviderProps {
    value?: ILanguageContext;
    children: React.ReactNode;
 }

const LanguageProvider = React.memo((props: ILanguageProviderProps) => {
    const isValidLanguage = props.value && (Object.values(AvailableLanguage) as string[]).includes(props.value);
    let language = AvailableLanguage.Vietnamese;

    if (isValidLanguage) {
        language = (props.value as AvailableLanguage)!;
    }

    return (
        <LanguageContext.Provider value={language!}>
            {props.children}
        </LanguageContext.Provider>
    )
})

LanguageProvider.displayName = "LanguageProvider";

export default LanguageProvider;