import Constants from "../../constants";
import SaleService from "./sale.service";

class PartnerService extends SaleService {
    public getAll = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.PARTNER.GET_ALL}?${data}`,
        });
        return result.data.result;
    }

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.PARTNER.GET_PAGED}?${data}`
        });
        return result.data.result;
    }

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.PARTNER.DETAIL + `/${id}`,
        });
        return result.data.result;
    }

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.PARTNER.CREATE,
            data: data,
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.PARTNER.UPDATE,
            data: data,
        });
        return result.data;
    }

    public updateStatus = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.PARTNER.UPDATE_STATUS,
            data: data,
        });
        return result.data;
    }

}

export default PartnerService;