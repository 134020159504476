import { useSelector } from "react-redux";
import { useEffect, ReactNode } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// Declaring props types for PageLayout

import { Box, Loading } from "@maysoft/common-component-react";
import { RootState } from "store";
import { setLayout, useMaterialUIController } from "components/context";

interface Props {
    background?: "white" | "light" | "default";
    children: ReactNode;
}

function PageLayout({ background, children }: Props): JSX.Element {
    const { pathname } = useLocation();
    const [, dispatch] = useMaterialUIController();
    const loading = useSelector((state: RootState) => state.loadingAPI.loading);
    useEffect(() => {
        setLayout(dispatch, "page");
    }, [pathname]);

    return (
        <>
            <Loading visible={loading} />
            <Box width="100vw" height="100%" minHeight="100vh" bgColor={background} sx={{ overflowX: "hidden" }}>
                {children}
            </Box>
        </>
    );
}

// Declaring default props for PageLayout
PageLayout.defaultProps = {
    background: "default",
};

export default PageLayout;
