import { useEffect, useState } from "react";
import { IItemRoute } from "@maysoft/common-component-react";
import { AutoStoriesOutlined, Cached, CardMembership, Dashboard, Inventory, ListAlt, Settings } from "@mui/icons-material";

import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";

import DashboardScreen from "screens/dashboard";
import ProfileScreen from "screens/profile";

import AttributeScreen from "screens/attribute";
import AttributeEditScreen from "screens/attribute/editAttributeScreen";

import SettingLoyalCustomerScreen from "screens/systemSettingCommon/loyaltyCustomer";
import SystemSettingScreen from "screens/systemSettingCommon/systemSetting";

import OrderScreen from "screens/order";
import ViewOrderScreen from "screens/order/viewOrderScreen";

import CashBookScreen from "screens/cashBook";
import PaymentTicketScreen from "screens/cashBook/paymentTicketScreen";
import ReceiveVoucherEditScreen from "screens/cashBook/receiveVoucherEditScreen";

import SettingPaymentGatewayScreen from "screens/systemSettingCommon/settingPaymentGateway";

import BundleProductScreen from "screens/bundleProduct";
import CodenameScreen from "screens/codename";
import CustomerScreen from "screens/customer";
import MemberShipScreen from "screens/membership";
import OrderNewVersionScreen from "screens/orderNewVersion";
import ProductScreen from "screens/product";
import ProductTemplateScreen from "screens/productTemplate";
import PromotionScreen from "screens/promotion";
import SupplierScreen from "screens/supplier";

import AffiliateProgramScreen from "screens/affiliate";
import AffiliaterScreen from "screens/affiliater";
import SponsorProgramScreen from "screens/sponsorProgram";
import PartnerCommissionScreen from "screens/partnerCommission";
import ServiceFeeSettingScreen from "screens/systemSettingCommon/serviceFeeSetting";

export const useRenderRoute = (key: any) => {
    const [routes, setRoutes] = useState<IItemRoute[]>([]);

    useEffect(() => {
        const mainRoutes: IItemRoute[] = [
            {
                key: "dashboard",
                isVisible: true,
                icon: <Dashboard />,
                title: Strings.Home.TITLE,
                screenPath: Screens.DASHBOARD,
                screenName: <DashboardScreen />,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },
            {
                key: "profile",
                isVisible: false,
                screenPath: Screens.PROFILE,
                screenName: <ProfileScreen />,
                title: Strings.PROFILE.TITLE_MENU,
                resourceCode: Constants.ResourceCode.DASHBOARD,
            },

            //Customer
            {
                key: "customerList",
                icon: <ListAlt />,
                isVisible: true,
                screenName: <CustomerScreen />,
                screenPath: Screens.CUSTOMER_LIST,
                title: Strings.CUSTOMER.TITLE_MENU,
                resourceCode: Constants.ResourceCode.CUSTOMER,
            },
            {
                key: "customerEdit",
                icon: <ListAlt />,
                isVisible: false,
                screenName: <CustomerScreen />,
                screenPath: Screens.CUSTOMER_EDIT,
                title: Strings.CUSTOMER.TITLE_MENU,
                resourceCode: Constants.ResourceCode.CUSTOMER,
            },

            //SUPPLIER
            {
                key: "supplier_list",
                icon: <ListAlt />,
                isVisible: true,
                screenName: <SupplierScreen />,
                screenPath: Screens.SUPPLIER_LIST,
                title: Strings.SUPPLIER.TITLE_MENU,
                resourceCode: Constants.ResourceCode.SUPPLIER,
            },
            {
                key: "supplier_edit",
                icon: <ListAlt />,
                isVisible: false,
                screenName: <SupplierScreen />,
                screenPath: Screens.SUPPLIER_EDIT,
                title: Strings.SUPPLIER.TITLE_MENU,
                resourceCode: Constants.ResourceCode.SUPPLIER,
            },

            //Membership
            {
                key: "membershipList",
                icon: <CardMembership />,
                isVisible: true,
                screenName: <MemberShipScreen />,
                screenPath: Screens.MEMBERSHIP_LIST,
                title: Strings.MEMBERSHIP.TITLE_MENU,
                resourceCode: Constants.ResourceCode.MEMBERSHIP,
            },
            {
                key: "membershipEdit",
                icon: <CardMembership />,
                isVisible: false,
                screenName: <MemberShipScreen />,
                screenPath: Screens.MEMBERSHIP_EDIT,
                title: Strings.MEMBERSHIP.TITLE_MENU,
                resourceCode: Constants.ResourceCode.MEMBERSHIP,
            },

            // * Sản phẩm
            {
                key: "productManage",
                icon: <Inventory />,
                title: Strings.PRODUCT_MANAGEMENT.TITLE_MENU,
                isVisible: true,
                resourceCode: [
                    Constants.ResourceCode.ITEM,
                    Constants.ResourceCode.PRODUCT,
                    Constants.ResourceCode.ATTRIBUTE,
                    Constants.ResourceCode.SERVICE_FEE_SETTING,
                ],
                subMenu: [
                    // Product Template
                    {
                        key: "template",
                        isVisible: true,
                        screenName: <ProductTemplateScreen />,
                        screenPath: Screens.PRODUCT_TEMPLATE_LIST,
                        title: Strings.PRODUCT_TEMPLATE.PRODUCT_TITLE,
                        resourceCode: Constants.ResourceCode.PRODUCT_TEMPLATE,
                    },
                    {
                        key: "template_edit",
                        isVisible: false,
                        screenName: <ProductTemplateScreen />,
                        screenPath: Screens.PRODUCT_TEMPLATE_EDIT,
                        title: Strings.PRODUCT_TEMPLATE.PRODUCT_TITLE,
                        resourceCode: Constants.ResourceCode.PRODUCT_TEMPLATE,
                    },

                    {
                        key: "product",
                        isVisible: true,
                        screenName: <ProductScreen />,
                        screenPath: Screens.PRODUCT_LIST,
                        title: Strings.PRODUCT.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.PRODUCT,
                    },
                    {
                        key: "bundleProduct",
                        isVisible: true,
                        screenName: <BundleProductScreen />,
                        screenPath: Screens.BUNDLE_PRODUCT_LIST,
                        title: Strings.BUNDLE_PRODUCT.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.ITEM,
                    },

                    // {
                    //     key: "category",
                    //     isVisible: true,
                    //     screenPath: Screens.CATEGORY_LIST,
                    //     title: Strings.PRODUCT_TEMPLATE.TITLE_MENU,
                    //     screenName: <ProductScreen />,
                    //     resourceCode: Constants.ResourceCode.DASHBOARD,
                    // },
                    {
                        key: "attribute",
                        isVisible: true,
                        screenName: <AttributeScreen />,
                        screenPath: Screens.ATTRIBUTE_LIST,
                        title: Strings.ATTRIBUTE.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.ATTRIBUTE,
                    },

                    //hide menubar
                    {
                        key: "attribute_edit",
                        isVisible: false,
                        screenPath: Screens.ATTRIBUTE_EDIT,
                        screenName: <AttributeEditScreen />,
                        title: Strings.ATTRIBUTE.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.ATTRIBUTE,
                    },

                    {
                        key: "product_edit",
                        isVisible: false,
                        screenName: <ProductScreen />,
                        screenPath: Screens.PRODUCT_EDIT,
                        title: Strings.PRODUCT.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.PRODUCT,
                    },

                    {
                        key: "bundle_product_edit",
                        isVisible: false,
                        screenName: <BundleProductScreen />,
                        screenPath: Screens.BUNDLE_PRODUCT_EDIT,
                        title: Strings.BUNDLE_PRODUCT.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.ITEM,
                    },

                    {
                        key: "promotion",
                        isVisible: true,
                        screenName: <PromotionScreen />,
                        screenPath: Screens.PROMOTION_LIST,
                        title: Strings.PROMOTION.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.PROMOTION,
                    },
                    {
                        key: "promotion_edit",
                        isVisible: false,
                        screenName: <PromotionScreen />,
                        screenPath: Screens.PROMOTION_EDIT,
                        title: Strings.PROMOTION.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.PROMOTION,
                    },
                ],
            },

            // * Transaction
            {
                key: "transaction",
                isVisible: true,
                icon: <Cached />,
                title: Strings.TRANSACTION.TITLE_MENU,
                resourceCode: [Constants.ResourceCode.ORDER],
                subMenu: [
                    {
                        key: "order",
                        isVisible: true,
                        screenPath: Screens.ORDER_LIST,
                        title: Strings.ORDER.TITLE_MENU,
                        screenName: <OrderScreen />,
                        resourceCode: Constants.ResourceCode.ORDER,
                    },
                    {
                        key: "detail_order",
                        isVisible: false,
                        screenPath: Screens.ORDER_DETAIL,
                        title: Strings.ORDER.TITLE_MENU,
                        screenName: <ViewOrderScreen />,
                        resourceCode: Constants.ResourceCode.ORDER,
                    },

                    {
                        isVisible: true,
                        key: "order-new-version",
                        title: "Order New Version",
                        screenName: <OrderNewVersionScreen />,
                        screenPath: Screens.ORDER_NEW_VERSION_LIST,
                        resourceCode: Constants.ResourceCode.ORDER,
                    },
                    {
                        isVisible: false,
                        key: "detail_order-new-version",
                        title: "Order New Version",
                        screenName: <OrderNewVersionScreen />,
                        screenPath: Screens.ORDER_NEW_VERSION_DETAIL,
                        resourceCode: Constants.ResourceCode.ORDER,
                    },
                ],
            },

            // * Cashbook
            {
                key: "cashbook",
                isVisible: true,
                icon: <AutoStoriesOutlined />,
                title: Strings.CASH_BOOK.TITLE,
                screenPath: Screens.CASH_BOOK,
                screenName: <CashBookScreen />,
                resourceCode: Constants.ResourceCode.CASHBOOK,
            },
            {
                key: "receiveVoucher_edit",
                isVisible: false,
                title: Strings.CASH_BOOK.TITLE,
                screenPath: Screens.RECEIVE_VOUCHER_EDIT,
                screenName: <ReceiveVoucherEditScreen />,
                resourceCode: Constants.ResourceCode.CASHBOOK,
            },
            {
                key: "payment",
                isVisible: false,
                title: Strings.CASH_BOOK.TITLE,
                screenPath: Screens.PAYMENT_TICKET,
                screenName: <PaymentTicketScreen />,
                resourceCode: Constants.ResourceCode.CASHBOOK,
            },

            //System Setting Common
            {
                key: "systemSetting",
                isVisible: true,
                icon: <Settings />,
                title: "Thiết lập hệ thống",
                resourceCode: [
                    Constants.ResourceCode.LOYALTY_CUSTOMER,
                    Constants.ResourceCode.SYSTEM_SETTING,
                    Constants.ResourceCode.CODENAME,
                    Constants.ResourceCode.SETTING_PAYMENT_GATEWAY,
                    Constants.ResourceCode.SERVICE_FEE_SETTING,
                ],
                subMenu: [
                    {
                        key: "loyaltyCustomer",
                        isVisible: true,
                        screenName: <SettingLoyalCustomerScreen />,
                        title: Strings.LOYALTY_CUSTOMER.TITLE_MENU,
                        screenPath: Screens.LOYALTY_CUSTOMER,
                        resourceCode: Constants.ResourceCode.LOYALTY_CUSTOMER,
                    },
                    {
                        key: "codename_list",
                        isVisible: true,
                        title: Strings.Codename.TITLE,
                        screenName: <CodenameScreen />,
                        screenPath: Screens.CODENAME_LIST,
                        resourceCode: Constants.ResourceCode.CODENAME,
                    },
                    {
                        key: "codename_edit",
                        isVisible: false,
                        title: Strings.Codename.TITLE,
                        screenName: <CodenameScreen />,
                        screenPath: Screens.CODENAME_EDIT,
                        resourceCode: Constants.ResourceCode.CODENAME,
                    },
                    {
                        key: "system_setting",
                        isVisible: true,
                        title: Strings.SYSTEM_SETTING.TITLE_MENU,
                        screenPath: Screens.SYSTEM_SETTING,
                        screenName: <SystemSettingScreen />,
                        resourceCode: Constants.ResourceCode.SYSTEM_SETTING,
                    },
                    {
                        key: "setting_payment_gateway",
                        isVisible: true,
                        screenPath: Screens.SETTING_PAYMENT_GATEWAY,
                        screenName: <SettingPaymentGatewayScreen />,
                        title: Strings.SYSTEM_SETTING.PAYMENT_GATEWAY.Title,
                        resourceCode: Constants.ResourceCode.SETTING_PAYMENT_GATEWAY,
                    },

                    {
                        key: "service_fee_setting_list",
                        isVisible: true,
                        screenPath: Screens.SERVICE_FEE_LIST,
                        screenName: <ServiceFeeSettingScreen />,
                        title: Strings.SERVICE_FEE_SETTING.TITLE_MENU,
                        resourceCode: Constants.ResourceCode.SERVICE_FEE_SETTING,
                    },
                ],
            },

            {
                key: "program_sponsor",
                isVisible: true,
                screenPath: Screens.SPONSOR_PROGRAM_LIST,
                title: Strings.SPONSOR_PROGRAM.TITLE_MENU,
                screenName: <SponsorProgramScreen />,
                resourceCode: Constants.ResourceCode.SPONSOR,
            },
            {
                key: "program_sponsor_edit",
                isVisible: false,
                screenPath: Screens.SPONSOR_PROGRAM_EDIT,
                title: Strings.SPONSOR_PROGRAM.TITLE_MENU,
                screenName: <SponsorProgramScreen />,
                resourceCode: Constants.ResourceCode.SPONSOR,
            },

            {
                key: "affiliate_program",
                isVisible: true,
                screenPath: Screens.AFFILIATE_PROGRAM_LIST,
                title: Strings.AFFILIATE_PROGRAM.TITLE_MENU,
                screenName: <AffiliateProgramScreen />,
                resourceCode: Constants.ResourceCode.AFFILIATE,
            },
            {
                key: "affiliate_program_edit",
                isVisible: false,
                screenPath: Screens.AFFILIATE_PROGRAM_EDIT,
                title: Strings.AFFILIATE_PROGRAM.TITLE_MENU,
                screenName: <AffiliateProgramScreen />,
                resourceCode: Constants.ResourceCode.AFFILIATE,
            },

            {
                key: "program_affiliate",
                isVisible: true,
                screenPath: Screens.AFFILIATER_LIST,
                title: Strings.PROGRAM_AFFILIATE.TITLE_MENU,
                screenName: <AffiliaterScreen />,
                resourceCode: Constants.ResourceCode.PROGRAM_AFFILIATE,
            },

            {
                isVisible: true,
                key: "partner_commission_list",
                screenName: <PartnerCommissionScreen />,
                screenPath: Screens.PARTNER_COMMISSION_LIST,
                title: Strings.PARTNER_COMMISSION.TITLE_MENU,
                resourceCode: Constants.ResourceCode.PARTNER_COMMISSION,
            },
            {
                isVisible: false,
                key: "partner_commission_create",
                screenName: <PartnerCommissionScreen />,
                screenPath: Screens.PARTNER_COMMISSION_CREATE,
                title: Strings.PARTNER_COMMISSION.TITLE_MENU,
                resourceCode: Constants.ResourceCode.PARTNER_COMMISSION,
            },
            {
                isVisible: false,
                key: "partner_commission_detail",
                screenName: <PartnerCommissionScreen />,
                screenPath: Screens.PARTNER_COMMISSION_DETAIL,
                title: Strings.PARTNER_COMMISSION.TITLE_MENU,
                resourceCode: Constants.ResourceCode.PARTNER_COMMISSION,
            },
        ];
        setRoutes(mainRoutes);
    }, [key]);
    return routes;
};
