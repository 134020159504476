const Resources = {
    Images: {
        LOGO: require("../assets/images/logoApp.png"),
        ARROW: require("../assets/images/arrow.png"),
        THUMBNAIL: require("../assets/images/thumbnail.png"),
        AVATAR_THUMBNAIL: require("../assets/images/avatar_thumbnail.png"),
        SIDEBAR_BACKGROUND: require("../assets/images/Sidebar_Background.png"),
        LOGIN_BACKGROUND: require("../assets/images/login-background.jpeg"),
        EN: require("../assets/images/en.png"),
        VN: require("../assets/images/vi.png"),
    },
};

export default Resources;
