import Icon from "@mui/material/Icon";
import { Box, Button, Typography } from "@maysoft/common-component-react";

interface Props {
    icon: string;
    color: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "text" | "white";
    status: string;
}

function CustomCell({ icon, color, status }: Props): JSX.Element {
    return (
        <Box display="flex" alignItems="center">
            <Box mr={1}>
                <Button disabled variant="outlined" color={color as any} iconOnly circular>
                    <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
                </Button>
            </Box>
            <Box>
                <Typography variant="caption" fontWeight="medium" color={color} sx={{ lineHeight: 0 }}>
                    {status}
                </Typography>
            </Box>
        </Box>
    );
}

export default CustomCell;
