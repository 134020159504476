import Constants from "../../constants";
import SaleService from "./sale.service";

class OrderService extends SaleService{
    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path:`${Constants.ApiPath.ORDER.GET_PAGED}?${data}`,
        });
        return result.data.result;
    }

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.ORDER.DETAIL}/${id}`,
        });
        return result.data.result;
    }

    public confirmOrder = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.CONFIRM_ORDER,
            data: data,
        });
        return result.data.result;
    }

    public completeOrder = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.COMPLETE_ORDER,
            data: data,
        });
        return result.data;
    }

    public calcPricing = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.CALC_PRICE,
            data: data,
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.UPDATE,
            data: data,
        });
        return result.data;
    }

    public cancel = async (id: string) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.CANCEL + `/${id}`,
        });
        return result.data.result;
    }
}

export default OrderService;