import { Box, CustomIcon, DataTableBodyCell, Typography } from "@maysoft/common-component-react";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import Constants from "constants/index";
import Strings from "constants/strings";
import RenderInputValueByType from "./inputValueByType";

import { ICodename } from "commons/interfaces";
import { Mode } from "constants/enum";
import { RootState } from "store";
import { IAttributeValue, IDataEditAttribute } from "./interface";

const RenderItemAttributeValue = (props: {
    mode?: Mode;
    index: number;
    isModal: boolean;
    languages: string;
    isAdminService: boolean;
    itemValue: IAttributeValue;
    dataValueType: ICodename[];
    organizationList: ICodename[];
    dataDetailAttribute: IDataEditAttribute;

    handleDeleteItemAttributeValue: (row: IAttributeValue) => void;
    handleOnchangeValueItem: (newValue: any, key: string, idItem: string) => void;
}) => {
    const userInfo = useSelector((state: RootState) => state.userInfoSlice);

    const orgTemp = useMemo(
        () => (props?.isAdminService ? Constants.ORGANIZATION_ID_ADMIN_SERVICE : userInfo?.userProfile?.organizationId),
        [props?.isAdminService, userInfo?.userProfile?.organizationId]
    );

    const disabled = useMemo(() => !props?.isAdminService && props?.itemValue.organizationId?.value !== orgTemp, [orgTemp]);

    return props?.isModal ? (
        <Box key={`box_item_attr_${props?.index}`} display="flex" alignItems="center" mb={2}>
            <Box mr={3}>
                <Tooltip title={Strings.Common.DELETE}>
                    <IconButton
                        disabled={props?.mode === Mode.View}
                        onClick={() => {
                            props?.handleDeleteItemAttributeValue(props?.itemValue);
                        }}
                    >
                        <CustomIcon iconName={"delete"} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box width={"90%"}>
                <RenderInputValueByType
                    mode={props?.mode}
                    disabled={disabled}
                    isModal={props?.isModal}
                    itemValue={props?.itemValue}
                    languages={props?.languages}
                    dataValueType={props?.dataValueType}
                    dataDetailAttribute={props?.dataDetailAttribute}
                    handleOnchangeValueItem={props?.handleOnchangeValueItem}
                />
            </Box>
        </Box>
    ) : (
        <TableRow>
            <TableCell style={{ width: "30px", borderBottom: "none" }}>
                <Typography variant="button">{props?.index + 1}</Typography>
            </TableCell>
            {props?.mode !== Mode.View && (
                <TableCell style={{ width: "100px", borderBottom: "none" }}>
                    {disabled ? (
                        <></>
                    ) : (
                        <Box>
                            <Tooltip title={Strings.Common.DELETE}>
                                <IconButton
                                    onClick={() => {
                                        props?.handleDeleteItemAttributeValue(props?.itemValue);
                                    }}
                                >
                                    <CustomIcon iconName={"delete"} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </TableCell>
            )}
            <DataTableBodyCell noBorder width={200} align="left">
                <RenderInputValueByType
                    mode={props?.mode}
                    disabled={disabled}
                    isModal={props?.isModal}
                    itemValue={props?.itemValue}
                    languages={props?.languages}
                    dataValueType={props?.dataValueType}
                    dataDetailAttribute={props?.dataDetailAttribute}
                    handleOnchangeValueItem={props?.handleOnchangeValueItem}
                />
            </DataTableBodyCell>
            {/* {props?.isAdminService && AttributeType.Common === Number(props?.dataDetailAttribute?.type?.value) && (
                <DataTableBodyCell noBorder width={90} align="left">
                    <Switch
                        key="isPublic"
                        checked={props?.itemValue?.isPublic === IsTrue.True}
                        disabled={!Helpers.isNullOrEmpty(props?.itemValue?.id) || disabled}
                        onChange={(event: any, checked: boolean) => {
                            props?.handleOnchangeValueItem(checked, "isPublic", props?.itemValue?.id);
                        }}
                    />
                </DataTableBodyCell>
            )} */}
            {/* {props?.isAdminService && AttributeType.Common === Number(props?.dataDetailAttribute?.type?.value) && (
                <DataTableBodyCell noBorder width={200} align="left">
                    <Autocomplete
                        mode={props?.mode}
                        data={props?.organizationList || []}
                        defaultValue={props?.itemValue?.organizationId?.value}
                        errorMessage={props?.itemValue?.organizationId?.error}
                        disabled={disabled || props?.itemValue?.isPublic === IsTrue.False}
                        placeholder={`${Strings.Common.SELECT} ${Strings.Common.ORGANIZATION}`}
                        onChange={(value) => {
                            props?.handleOnchangeValueItem(value, "organizationId", props?.itemValue?.id);
                        }}
                    />
                </DataTableBodyCell>
            )} */}
        </TableRow>
    );
};

export default RenderItemAttributeValue;
