import Helpers from "commons/helpers";
import APIAccessor from "../apiProcessor";
import { IAssetInfo } from "commons/interfaces";
import Constants, { ASSET_API_URL } from "constants/index";

class AssetService {
    protected api: APIAccessor;
    constructor() {
        this.api = new APIAccessor(ASSET_API_URL);
    }

    public getAssetInfo = async (id: string): Promise<IAssetInfo> => {
        const result = await this.api.get({
            path: Helpers.urlJoin(Constants.ApiPath.ASSET.GET_ASSET_INFO, id)
        });
        return result.data.result;
    }

    public setActiveById = async (id: string) => {
        const result = await this.api.put({
            path: Constants.ApiPath.ASSET.SET_ACTIVE_BY_ID + `/${id}`,
        });
        return result.data.result;
    }

    public setDeletedById = async (id: string) => {
        const result = await this.api.put({
            path: Constants.ApiPath.ASSET.SET_DELETED_BY_ID + `/${id}`,
        });
        return result.data.result;
    }
}

export default AssetService;