import APIAccessor from "../apiProcessor";
import { IDENTITY_API_URL } from "constants/index";

class IdentityService {
    protected api: APIAccessor;

    constructor() {
        this.api = new APIAccessor(IDENTITY_API_URL);
    }
}

export default IdentityService;
