import { Card, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PartnerType } from "@maysoft/sale-common-react";
import {
    Autocomplete, Box, Button, ControlLabelCheckBox,
    DataTable, FormField, Modal, Typography
} from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "constants/index";
import SystemSettingService from "services/sale/systemsetting.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { ICodename, IInput } from "commons/interfaces";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { IsTrue, Mode, SystemSettingCode } from "constants/enum";
import AttributeService from "services/sale/attribute.service";

interface IListSetting {
    id?: string;
    status?: number;
    name?: string;
    description?: string;
    settingCode?: string;
    settingValue?: string;
    organizationId?: string;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
}

interface ISettingValueDefault {
    key: string; value: IInput
}

interface IModel {
    languages?: string;
    dataRows?: IListSetting[];
}

const attributeService = new AttributeService();
const systemSettingService = new SystemSettingService();

export const onUpdateSetting = async (props: {
    callback: () => void,
    dispatch: (args: any) => void,
    query: {
        id: string,
        settingCode: string,
        settingValue: string,
        serviceCode: string,
        organizationId: string,
    }
}) => {
    try {
        props.dispatch(showLoading(true));

        const result = await systemSettingService.update(props.query);
        if (result.statusCode === Constants.ApiCode.SUCCESS) {
            props.callback();
            props.dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
        }

        props.dispatch(showLoading(false));
    } catch (error) {
        props.dispatch(showLoading(false));
        const e = Helpers.renderExceptionError(error);
        props.dispatch(setDataAlert({ message: e, type: "error" }));
    }
}

const listPartnerType: ICodename[] = [
    { code: PartnerType.Agent, name: Strings.PARTNER.AGENT, },
    { code: PartnerType.Expert, name: Strings.PARTNER.EXPERT, },
    { code: PartnerType.Sponsor, name: Strings.PARTNER.SPONSOR, },
    { code: PartnerType.Customer, name: Strings.PARTNER.CUSTOMER, },
    { code: PartnerType.Referral, name: Strings.PARTNER.REFERRAL, },
    { code: PartnerType.Supplier, name: Strings.PARTNER.SUPPLIER, },
    { code: PartnerType.Manufacturer, name: Strings.PARTNER.MANUFACTURER, },
]

const SystemSettingScreen = () => {
    const dispatch = useDispatch();

    const userProfile = useSelector((state: RootState) => state.userInfoSlice?.userProfile);


    const [model, setModel] = useState<IModel>({ languages: "vi" });
    const [openModalDefault, setOpenModalDefault] = useState<boolean>(false);
    const [listAttributeSetting, setListAttributeSetting] = useState<ICodename[]>([]);
    const [dataSettingValueDefault, setDataSettingValueDefault] = useState<{
        id?: string;
        mode?: number;
        settingCode?: string;
        serviceCode?: string;
        organizationId?: string;
        settingValue?: ISettingValueDefault[];
    }>({});

    useEffect(() => {
        getAllSystemSettingCode();
    }, [userProfile?.organizationId]);

    const getAllAtribbute = async () => {
        try {
            const resultAttribute = await attributeService.getAll({
                organizationId: userProfile?.organizationId
            });

            let dataCode: ICodename[] = [];

            resultAttribute.forEach((item: any) => {
                dataCode.push({
                    code: item.code,
                    name: item.title?.value?.["vi"],
                });
            });

            setListAttributeSetting(dataCode);

        } catch (error) { }
    }

    const getAllSystemSettingCode = async () => {
        try {
            dispatch(showLoading(true));

            const result = await systemSettingService.getListSettingCode(
                {
                    settingCodes: [
                        SystemSettingCode.VnPay,
                        SystemSettingCode.PayPal,
                        SystemSettingCode.Payment,
                        SystemSettingCode.PartnerSetting,
                        SystemSettingCode.AttributeCommon,
                    ],
                    organizationId: userProfile?.organizationId,
                    serviceCode: Constants.SERVICE_CODE,
                }
            );

            const rows: IListSetting[] = [];
            [...result || []].forEach((element: IListSetting) => {
                const str = Strings.SYSTEM_SETTING?.[`${element.settingCode || ""}`]?.["Title"];
                rows.push({
                    ...element,
                    name: str,
                });
            });

            setModel({ ...model, dataRows: rows });

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getDetailBySettingCode = async (settingCode: string, modeModal: number) => {
        try {
            dispatch(showLoading(true));

            const result = await systemSettingService.getDetail(settingCode, userProfile?.organizationId);

            if (settingCode === SystemSettingCode.PartnerSetting) {
                getAllAtribbute();
            }

            const dataSettingValue: ISettingValueDefault[] = [];

            const settingValue = JSON.parse(result.settingValue);

            if (Array.isArray(settingValue)) {
                settingValue.forEach((item) => {
                    let newValueTemp = undefined;

                    if (settingCode === SystemSettingCode.PartnerSetting) {
                        newValueTemp = `${item.Code}`.split(",");
                        dataSettingValue.push({
                            key: item.Type,
                            value: { value: newValueTemp },
                        });
                    } else {

                    }
                });
            } else {
                Object.keys(settingValue).forEach((key) => {
                    let newValueTemp = settingValue[key];

                    if (settingCode === SystemSettingCode.PartnerSetting) {
                        newValueTemp = `${settingValue[key]}`.split(",");
                    }

                    dataSettingValue.push({
                        key: key,
                        value: { value: newValueTemp },
                    });
                });
            }

            setDataSettingValueDefault({
                id: result.id,
                mode: modeModal,
                settingCode: settingCode,
                settingValue: dataSettingValue,
                serviceCode: result.serviceCode || Constants.SERVICE_CODE,
                organizationId: (
                    Helpers.isNullOrEmpty(result.organizationId) ||
                    result.organizationId === "0" || result.organizationId === 0
                )
                    ? (Helpers.isNullOrEmpty(userProfile?.organizationId)
                        ? "0" : userProfile?.organizationId)
                    : result.organizationId,
            });

            setOpenModalDefault(true);

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onChangeValue = (index: number, value: any) => {
        let dataTemp = [...dataSettingValueDefault.settingValue || []];

        dataTemp[index] = {
            ...dataTemp[index],
            value: { value },
        };

        setDataSettingValueDefault({
            ...dataSettingValueDefault,
            settingValue: dataTemp,
        });

    };

    const checkValidatedData = () => {
        let checked: boolean = true;

        if (dataSettingValueDefault.settingCode !== SystemSettingCode.PartnerSetting) {
            let dataSettingValue = [...dataSettingValueDefault.settingValue || []];

            dataSettingValue.forEach((item) => {
                if (Helpers.isNullOrEmpty(item["value"]?.value)) {
                    checked = false;
                    item["value"] = { error: Strings.Validation.REQUIRED };
                }
            });

            if (dataSettingValueDefault.settingCode === SystemSettingCode.Payment) {
                let isExists = dataSettingValue.find((e) => (
                    dataSettingValueDefault.settingCode === SystemSettingCode.Payment &&
                    Number(e.value.value) === IsTrue.True
                ));

                if (!isExists) {
                    dispatch(setDataAlert({ message: "Bạn phải chọn ít nhất 1 phương thức thanh toán", type: "error" }));
                    return;
                }
            }

            if (!checked) {
                setDataSettingValueDefault({ ...dataSettingValueDefault, settingValue: dataSettingValue });
            }
        }

        return checked;
    }

    const onSubmitSettingValueDefault = async () => {
        if (dataSettingValueDefault.mode === Mode.View) {
            setDataSettingValueDefault({ ...dataSettingValueDefault, mode: Mode.Update });
        } else {
            if (!checkValidatedData()) {
                return
            } else {
                let settingValue: string = "";
                let dataSettingValue = [...dataSettingValueDefault.settingValue || []];

                if (dataSettingValueDefault.settingCode === SystemSettingCode.PartnerSetting) {
                    const arrValue: { Type: string, Code: string }[] = [];

                    dataSettingValue.forEach((item) => {
                        arrValue.push({
                            Type: item.key,
                            Code: [...item.value?.value || []].toString(),
                        })
                    });

                    settingValue = JSON.stringify(arrValue);
                } else {
                    let newValue: Object;

                    dataSettingValue.forEach((item) => {
                        newValue = { ...newValue, [item.key]: item.value?.value };
                    });

                    settingValue = JSON.stringify(settingValue);
                }

                const dataUpdate: any = {
                    id: dataSettingValueDefault.id,
                    settingValue: settingValue,
                    settingCode: dataSettingValueDefault.settingCode,
                    serviceCode: dataSettingValueDefault.serviceCode,
                    organizationId: dataSettingValueDefault.organizationId,
                };

                onUpdateSetting({
                    dispatch: dispatch,
                    query: dataUpdate,
                    callback: () => {
                        setDataSettingValueDefault({});
                        setOpenModalDefault(false);

                        getAllSystemSettingCode();
                    },
                });
            }
        }
    };

    const renderBoxContainerByCode = (item: ISettingValueDefault, index: number) => {
        if (dataSettingValueDefault.settingCode === SystemSettingCode.Payment) {
            return (
                <ControlLabelCheckBox
                    mode={dataSettingValueDefault.mode}
                    value={Number(item.value?.value) === IsTrue.True ? true : false}
                    label={Strings.SYSTEM_SETTING?.[`${dataSettingValueDefault.settingCode}`]?.[`${item.key}`]}
                    onChangeValue={(value) => {
                        onChangeValue(index, value ? IsTrue.True : IsTrue.False);
                    }}
                />
            );
        }
        if (dataSettingValueDefault.settingCode === SystemSettingCode.PartnerSetting) {
            return (
                <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                }}>
                    <Box width={"20%"}>
                        <Typography variant="button" fontWeight="bold">
                            {listPartnerType.find(el => el.code === Number(item.key))?.name || ""}
                        </Typography>
                    </Box>
                    <Box width={"79%"}>
                        <Autocomplete
                            multiple
                            errorMessage={item.value?.error}
                            defaultValue={item.value?.value}
                            data={listAttributeSetting || []}
                            disabled={dataSettingValueDefault.mode === Mode.View}
                            placeholder="Chọn thuộc tính"
                            onChange={(value) => {
                                onChangeValue(index, value);
                            }}
                        />
                    </Box>
                </Box>
            );
        }
        return (
            <FormField
                required
                variant="outlined"
                value={item.value?.value}
                errorMessage={item.value?.error}
                mode={dataSettingValueDefault.mode}
                label={Strings.SYSTEM_SETTING?.[`${dataSettingValueDefault.settingCode}`]?.[`${item.key}`]}
                placeholder={Strings.SYSTEM_SETTING?.[`${dataSettingValueDefault.settingCode}`]?.[`Enter_${item.key}`]}
                onChangeValue={(value) => {
                    onChangeValue(index, value);
                }}
            />
        );
    }

    const titleModal = useMemo(() => {
        const val1 = dataSettingValueDefault.mode === Mode.View
            ? Strings.SYSTEM_SETTING.DETAIL
            : Strings.SYSTEM_SETTING.UPDATE;

        const val2 = ` ${Strings.SYSTEM_SETTING?.[dataSettingValueDefault.settingCode]?.["Title"]}`;

        return val1 + val2
    }, [dataSettingValueDefault.mode, dataSettingValueDefault.settingCode]);

    const modalSettingValueDefault = () => {
        return (
            <Modal
                fullWidth
                maxWidth={"md"}
                title={titleModal}
                hasActionButton={false}
                visible={openModalDefault || false}
                onClose={() => { setOpenModalDefault(false); }}
            >
                <Grid container spacing={3} p={2}>

                    {[...dataSettingValueDefault.settingValue || []].map((item, index) => (
                        <Grid item xs={12} key={index}>
                            {renderBoxContainerByCode(item, index)}
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="end">
                            <Button
                                variant="outlined"
                                sx={{ marginRight: "16px" }}
                                onClick={() => setOpenModalDefault(false)}
                            >
                                {Strings.Common.EXIT}
                            </Button>
                            <Button onClick={onSubmitSettingValueDefault}>
                                {(dataSettingValueDefault.mode === Mode.View)
                                    ? Strings.Common.EDIT
                                    : Strings.Common.SAVE
                                }
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Modal>
        );
    };

    return (
        <DashboardLayout
            isPermission
            title={Strings.SYSTEM_SETTING.TITLE_LIST_VIEW}
            route={[{ title: Strings.SYSTEM_SETTING.TITLE_MENU, route: "" }]}
        >
            <Card>
                <Box p={2}>
                    <DataTable
                        isLocal
                        isShowIndex
                        pageNumber={1}
                        rowPerPage={Constants.ROW_PER_PAGE}
                        totalCount={model.dataRows?.length || 0}
                        table={{
                            rows: model.dataRows || [],
                            columns: [
                                { Header: Strings.SYSTEM_SETTING.CODE, accessor: "settingCode", },
                                { Header: Strings.SYSTEM_SETTING.NAME, accessor: "name", },
                            ],
                        }}
                        actionList={[
                            {
                                key: "detail",
                                iconName: "view",
                                title: Strings.Common.VIEW,
                                callback: (row: any) => getDetailBySettingCode(row.settingCode, Mode.View),
                            },
                            {
                                key: "edit",
                                iconName: "edit",
                                title: Strings.Common.EDIT,
                                callback: (row: any) => getDetailBySettingCode(row.settingCode, Mode.Update),
                            },
                        ]}
                    />
                </Box>
                {openModalDefault && modalSettingValueDefault()}
            </Card>
        </DashboardLayout>
    );
};

export default SystemSettingScreen;
