import Constants from "../../constants";
import OrderSevice from "./sale.service";


class CashBookService extends OrderSevice {
    public getPaged = async (data?: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.GET_PAGED}` + (data ? `?${data}` : ""),
        });
        return result.data.result;
    }

    public getDetailPayment = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.DETAIL_PAYMENT}/${id}`,
        });
        return result.data.result;
    }

    public createPayment = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CREATE_PAYMENT}`,
            data
        });
        return result.data;
    }

    public updatePayment = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.UPDATE_PAYMENT}`,
            data
        });
        return result.data;
    }

    public completePayment = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.COMPLETE_PAYMENT}/${id}`,
        });
        return result.data;
    }

    public cancelPayment = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CANCEL_PAYMENT}/${id}`,
        });
        return result.data;
    }

    public getDetailReceive = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.DETAIL_RECEIVE}/${id}`,
        });
        return result.data.result;
    }

    public createReceive = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CREATE_RECEIVE}`,
            data
        });
        return result.data;
    }

    public updateReceive = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.UPDATE_RECEIVE}`,
            data
        });
        return result.data;
    }

    public completeReceive = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.COMPLETE_RECEIVE}/${id}`,
        });
        return result.data;
    }

    public cancelReceive = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CANCEL_RECEIVE}/${id}`,
        });
        return result.data;
    }

    public export = async (data: any) => {
        const result = await this.api.getPDF({
            path: `${Constants.ApiPath.CASH_BOOK.EXPORT}?${data}`
        });
        return new Blob([result.data]);
    }

}

export default CashBookService;