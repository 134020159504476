import Strings from "constants/strings";

import { DashboardLayout } from "layout";
import { useDispatch } from "react-redux";
import { storeDataUserProfile } from "store/slice/userInfo.slice";
import { ProfileContainer, Box } from "@maysoft/common-component-react";

const ProfileScreen = () => {
    const dispatch = useDispatch();

    return (
        <DashboardLayout isPermission title={Strings.PROFILE.TITLE_MENU} route={[{ title: Strings.PROFILE.TITLE_MENU, route: "" }]}>
            <Box mt={1}>
                <ProfileContainer
                    isMultiCardContainer={false}
                    onDataUserProfile={(data) => {
                        dispatch(storeDataUserProfile(data));
                    }}
                />
            </Box>
        </DashboardLayout>
    );
};

export default ProfileScreen;
