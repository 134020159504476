import StringLocalization from "lib/localization";
import Constants from ".";
import en from "./../commons/locales/en";
import vi from "./../commons/locales/vn";
const Strings = StringLocalization({
    vi,
});
const currentLanguage = JSON.parse(localStorage.getItem(Constants.StorageKeys.LANGUAGE));
Strings.setLanguage((currentLanguage == null || currentLanguage == "" || currentLanguage == undefined)
    ? Constants.DefaultLanguage : currentLanguage);


export default Strings;
