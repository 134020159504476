import { useState, useEffect, useRef } from "react";
import moment from "moment";
import Strings from "../constants/strings";
import { useLanguageContext } from "providers/languageProvider";

const renewStringInstance = (language: string) => {
    Strings.setLanguage(language);
    const clone = Strings.shallowClone();
    Strings.setInstance(clone);
    return clone;
}

const useStrings = () => {
    const isFirstLoadRef = useRef(false);
    const language = useLanguageContext();
    const [value, setValue] = useState(() => {
        const currentStringInstance = Strings.getInstance();
        if (language === currentStringInstance.getLanguage()) {
            return currentStringInstance;
        }

        const clone = renewStringInstance(language);
        return clone;
    });

    moment.locale(language);

    useEffect(() => {
        if (!isFirstLoadRef.current) {
            isFirstLoadRef.current = true;
            return;
        }


        setValue(value => {
            if (value === Strings.getInstance()) {
                // First in-component useStrings hook called
                const clone = renewStringInstance(language);
                return clone;
            } else {
                return Strings.getInstance();
            }
        })
    }, [language])

    return value;
}

export default useStrings;