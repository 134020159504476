import { Box, Typography } from "@maysoft/common-component-react";

interface IProps {
    title: string;
    bgColor: "transparent" |
    "white" |
    "black" |
    "primary" |
    "secondary" |
    "info" |
    "success" |
    "warning" |
    "error" |
    "light" |
    "dark" |
    "text" |
    "grey-100" |
    "grey-200" |
    "grey-300" |
    "grey-400" |
    "grey-500" |
    "grey-600" |
    "grey-700" |
    "grey-800" |
    "grey-900";
    style?: any
}

const StatusDetail = ({ title, bgColor, style }: IProps) => {
    return (
        <Box
            variant="gradient"
            style={{
                borderRadius: "10px",
                padding: "6px 14px",
                textAlign: "center",
                ...style
            }} bgColor={bgColor} >
            <Typography sx={{
                fontSize: "12px",
                color: "#ffffff",
                fontWeight: "bold",
                textTransform: "uppercase",
                letterSpacing: "0.02857em",
                fontFamily: "Open Sans",
            }}>
                {title}
            </Typography>
        </Box>
    )
}

export default StatusDetail;