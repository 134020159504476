import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModuleProduct } from "@maysoft/sale-common-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "../../constants";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { Mode, ProductType } from "constants/enum";
import { setListPathName } from "store/slice/titleRoute.slice";



const MemberShipScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const idDetail: string = searchParams.get("id");
    const locationMode = (location?.state as any)?.mode || Mode.View;

    const modeList = (Screens.MEMBERSHIP_LIST.includes(window.location.pathname));

    const userInfo = useSelector((state: RootState) => state.userInfo.userProfile);
    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const itemPathName = listPathName.find((el) => el.pathName === Screens.MEMBERSHIP_LIST);

    const [mode, setMode] = useState<Mode>(locationMode);
    const [request, setRequest] = useState<any>({
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: itemPathName?.totalCount || 0,
        organizationId: userInfo?.organizationId || "0",
    });

    useEffect(() => {
        setRequest({
            searchText: searchParams.get("searchText"),
            pageNumber: Number(searchParams.get("pageNumber") || 1),
            pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
            totalCount: itemPathName?.totalCount || 0,
            organizationId: userInfo?.organizationId || "0",
        });
    }, [userInfo.organizationId]);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            setMode(Mode.Create);
        } else {
            setMode(locationMode);
        }
    }, [idDetail]);

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            return Strings.MEMBERSHIP.TITLE_CREATE_VIEW;
        } else {
            return mode === Mode.View ? Strings.MEMBERSHIP.TITLE_DETAIL_VIEW : Strings.MEMBERSHIP.TITLE_UPDATE_VIEW;
        }
    }, [idDetail, mode]);

    const route = useMemo(() => {
        const temp = [{ title: Strings.MEMBERSHIP.TITLE_MENU, route: Screens.MEMBERSHIP_LIST }];
        if (Helpers.isNullOrEmpty(idDetail)) {
            temp.push({
                title: Strings.Common.CREATE_NEW,
                route: "",
            });
        } else {
            temp.push({
                title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [idDetail, mode]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.MEMBERSHIP_LIST);
        navigate(Screens.MEMBERSHIP_LIST + (itemPathName ? itemPathName.query : ""));
    };

    return (
        <DashboardLayout
            isPermission
            title={modeList ? Strings.MEMBERSHIP.TITLE_LIST : title}
            route={modeList ? [{ title: Strings.MEMBERSHIP.TITLE_LIST, route: "" }] : route}
        >
            <ModuleProduct
                keyModuleContainer={{
                    list: { pathName: Screens.MEMBERSHIP_LIST },
                    detail: { pathName: Screens.MEMBERSHIP_EDIT },
                }}
                // list
                requestGetPaged={request}
                type={ProductType.Membership}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.MEMBERSHIP_LIST, query, totalCount }));
                    navigate(Screens.MEMBERSHIP_LIST + query, { replace: true });
                }}
                onNavigate={async (data) => {
                    const id = data.id ? `?id=${data.id}` : "";
                    navigate(Screens.MEMBERSHIP_EDIT + id, { state: { mode: data.mode } });
                }}
                // detail
                mode={mode}
                idDetail={idDetail}
                optionalAttributes={[]}
                onGoBack={() => handleGoBack()}
                onChangeMode={(mode: Mode) => setMode(mode)}
            />
        </DashboardLayout>
    );
};

export default MemberShipScreen;
