import { IManageUser } from "commons/interfaces";
import Constants from "../../constants";
import IdentityService from "./identity.service";

class UserService extends IdentityService {
    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.GET_PAGED}?${data}`,
        });
        return result.data.result;
    };

    public getDetail = async (id: string, organizationId: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.DETAIL}?Id=${id}&OrganizationId=${organizationId}`,
        });
        return result.data.result;
    };

    public create = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.USER.CREATE}`,
            data,
        });
        return result.data.result;
    };

    public update = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.USER.UPDATE}`,
            data,
        });
        return result.data.result;
    };

    public block = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.USER.BLOCK}?id=${id}`,
        });
        return result.data.result;
    };

    public delete = async (data: any) => {
        const result = await this.api.delete({
            path: `${Constants.ApiPath.USER.DELETE}`,
            data,
        });
        return result.data.result;
    };

    public getManageUser = async (): Promise<IManageUser[]> => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.GET_MANAGE_USER}`,
        });
        return result.data.result;
    };
}

export default UserService;
