import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, Grid, Step, StepLabel, Stepper } from "@mui/material";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "../../constants";
import { showLoading } from "store/slice/loadingAPI.slice";
import { ICodename, IInput, IResource, ITitleRoute } from "commons/interfaces";
import {
    CashBookType,
    GetPagedType,
    Mode,
    PartnerType,
    PaymentType,
    ReceiveVoucherType,
    RevenueRecognition,
    UserGroup,
    VoucherHistoryStatus,
} from "constants/enum";
import { RootState } from "store";
import CashBookService from "services/sale/cashBook.service";
import PartnerService from "services/sale/partner.service";
import printJS from "print-js";
import ItemService from "services/sale/item.service";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { AutocompleteLoadMore } from "components";
import { Autocomplete, Box, Button, DatePicker, Typography, FormField, Switch } from "@maysoft/common-component-react";

interface IDataEdit {
    id?: string;
    type?: IInput;
    voucherStatus?: number;
    organizationId?: string;
    groupId?: IInput;
    payer?: IInput;
    amount?: IInput;
    paymentMethod?: IInput;
    paymentAccountRequest?: IPaymentAccount;
    description?: IInput;
    revenueRecognition?: IInput;
    referenceCode?: IInput;
    receiveDate?: IInput;
    userGroup?: IInput;
    name?: IInput;
    voucherCode?: string;
    currency?: IInput;
}

interface IPaymentAccount {
    id?: string;
    accountName?: IInput;
    bankCode?: IInput;
    isDefault: number;
    accountId?: IInput;
}

interface IModel {
    dataEdit?: IDataEdit;

    edited?: IInput;
    resourceDetails?: any[];
    resourceList?: IResource[];

    title?: string;
    route?: ITitleRoute[];
    mode?: Mode;

    partnerType: number[];
}

const ReceiveVoucherEditScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const itemService = new ItemService();
    const partnerService = new PartnerService();
    const cashBookService = new CashBookService();

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const userProfile = useSelector((state: RootState) => state.userInfoSlice?.userProfile);
    const organizationId = userProfile?.organizationId;

    const [model, setModel] = useState<IModel>({} as IModel);
    const [listPayer, setListPayer] = useState<ICodename[]>([]);
    const [dataCurrency, setDataCurrency] = useState<ICodename[]>([]);

    const [histories, setHistories] = useState<
        {
            id?: string;
            createTime?: string;
            createUser?: string;
            createUserName?: string;
            type?: string;
            voucherId?: string;
            voucherStatus?: string;
            status?: string;
            note?: string;
            updateTime?: string;
            updateUser?: string;
        }[]
    >([]);

    // const [revenueRecognitionList, setRevenueRecognitionList] = useState<any[]>([
    //     { code: `${RevenueRecognition.Customer}`, name: Strings.RECEIVE_VOUCHER.CUSTOMER },
    //     { code: `${RevenueRecognition.Supplier}`, name: Strings.RECEIVE_VOUCHER.SUPPLIER },
    //     { code: `${RevenueRecognition.Other}`, name: Strings.RECEIVE_VOUCHER.OTHER },
    // ])

    const userGroupList: ICodename[] = [
        { code: `${UserGroup.Customer}`, name: Strings.RECEIVE_VOUCHER.CUSTOMER },
        // { code: `${UserGroup.Supplier}`, name: Strings.RECEIVE_VOUCHER.SUPPLIER },
        // { code: `${UserGroup.Other}`, name: Strings.RECEIVE_VOUCHER.OTHER },
    ];

    const paymentMethodList: ICodename[] = [
        { code: `${PaymentType.Cash}`, name: Strings.ORDER.CASH },
        { code: `${PaymentType.Banking}`, name: Strings.ORDER.BANKING },
        { code: `${PaymentType.Paypal}`, name: Strings.ORDER.PAYPAL },
        { code: `${PaymentType.VnPay}`, name: Strings.ORDER.VNPAY },
    ];

    const [typeList, setTypeList] = useState<ICodename[]>([
        { code: `${ReceiveVoucherType.SelfCreated}`, name: Strings.RECEIVE_VOUCHER.SELF_CREATED },
        { code: `${ReceiveVoucherType.CustomersOrder}`, name: Strings.RECEIVE_VOUCHER.CUSTOMER_ORDER },
        { code: `${ReceiveVoucherType.CollectionAndTransfer}`, name: Strings.RECEIVE_VOUCHER.COLLECTION_AND_TRANSFER },
    ]);

    const voucherStatusList: ICodename[] = [
        { code: `${VoucherHistoryStatus.Cancel}`, name: Strings.Common.CANCELED, group: "error" },
        { code: `${VoucherHistoryStatus.Completed}`, name: Strings.Common.COMPLETE, group: "success" },
        { code: `${VoucherHistoryStatus.Pending}`, name: Strings.Common.PROCESSING, group: "warning" },
        { code: `${VoucherHistoryStatus.Updated}`, name: Strings.PAYMENT_TICKET.UPDATED, group: "warning" },
        { code: `${VoucherHistoryStatus.Commit}`, name: Strings.PAYMENT_TICKET.COMMIT, group: "warning" },
        { code: `${VoucherHistoryStatus.CreateAuto}`, name: Strings.PAYMENT_TICKET.CREATE_AUTO, group: "warning" },
    ];

    const id = searchParams.get("id");

    useEffect(() => {
        onStart();
    }, [userProfile?.organizationId]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.CASH_BOOK);
        navigate(Screens.CASH_BOOK + (itemPathName ? itemPathName.query : ""));
    };

    const onStart = async () => {
        try {
            dispatch(showLoading(true));
            await getSupportCurrency();

            let dataEdit: IDataEdit = {
                revenueRecognition: { value: RevenueRecognition.True },
                paymentMethod: { value: `${PaymentType.Cash}` },
                receiveDate: { value: moment().unix() },
                type: { value: `${ReceiveVoucherType.SelfCreated}` },
                paymentAccountRequest: { isDefault: 0 },
                userGroup: { value: `${UserGroup.Customer}` },
            };
            await getDataPayer({ pageNumber: 1, searchText: undefined });

            let mode = id ? Mode.View : Mode.Create;
            let title = id ? Strings.RECEIVE_VOUCHER.TITLE_DETAIL : Strings.RECEIVE_VOUCHER.TITLE;
            let route = [
                { title: Strings.CASH_BOOK.TITLE, route: Screens.CASH_BOOK },
                { title: id ? Strings.Common.DETAIL : Strings.Common.CREATE_NEW, route: "" },
            ];

            if (id) {
                let listTypeTemp = typeList || [];
                listTypeTemp.push({ code: `${ReceiveVoucherType.Automatic}`, name: Strings.RECEIVE_VOUCHER.AUTOMATIC });
                setTypeList(listTypeTemp);
                dataEdit = await getDetailReceive(id);
                title =
                    Number(dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                    Number(dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                        ? Strings.RECEIVE_VOUCHER.TITLE_DETAIL
                        : Strings.RECEIVE_VOUCHER.TITLE_UPDATE_VIEW;
            }

            setModel({ ...model, dataEdit, mode, title, route, edited: { value: false } });

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getSupportCurrency = async () => {
        try {
            const result = await itemService.getSupportCurrency();
            const dataResult: ICodename[] = [];
            (result || []).forEach((item: any) => {
                dataResult.push({
                    code: item,
                    name: item,
                });
            });
            setDataCurrency(dataResult);
        } catch (err) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(err);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onValueChange = (key: string, newValue: any) => {
        let dataEditTemp: any = { ...model.dataEdit };
        let partnerType: number[] = [];
        dataEditTemp[key] = { value: newValue, error: "" };

        if (key === "revenueRecognition") {
            dataEditTemp[key] = { value: newValue === true ? RevenueRecognition.True : RevenueRecognition.False };
        }

        if (key === "paymentMethod") {
            dataEditTemp["paymentAccountRequest"] = {};
        }

        if (key === "accountId") {
            let paymentAccount: any = { ...dataEditTemp?.paymentAccountRequest };
            paymentAccount[key] = { value: newValue };
            dataEditTemp["paymentAccountRequest"] = paymentAccount;
        }
        if (key === "bankCode") {
            let paymentAccount: any = { ...dataEditTemp?.paymentAccountRequest };
            paymentAccount[key] = { value: newValue };
            dataEditTemp["paymentAccountRequest"] = paymentAccount;
        }

        if (key === "payer") {
            dataEditTemp["payer"] = { value: newValue };
        }

        if (key === "userGroup") {
            dataEditTemp["payer"] = { value: undefined };
            setListPayer([]);
            switch (Number(newValue)) {
                case UserGroup.Customer:
                    partnerType = [PartnerType.Customer, PartnerType.CompanyCustomer];
                    break;
                case UserGroup.Supplier:
                    partnerType = [PartnerType.IndividualSupplier, PartnerType.CompanySupplier];
                    break;
                case UserGroup.Other:
                    partnerType = [PartnerType.DeliveryCompany];
                    break;
                default:
                    break;
            }
        }
        setModel({ ...model, dataEdit: dataEditTemp, edited: { value: true }, partnerType });
    };

    const getDetailReceive = async (id: string) => {
        const result = await cashBookService.getDetailReceive(id);
        setHistories(result.voucherHistoryResponse || []);

        let userGroup: any;
        switch (Number(result.partnerType)) {
            case PartnerType.CompanyCustomer:
            case PartnerType.Customer:
                userGroup = `${UserGroup.Customer}`;
                break;
            case PartnerType.CompanySupplier:
            case PartnerType.IndividualSupplier:
                userGroup = `${UserGroup.Supplier}`;
                break;
            case PartnerType.DeliveryCompany:
                userGroup = `${UserGroup.Other}`;
                break;
            default:
                break;
        }

        return {
            id: result.id,
            groupId: { value: result.groupId },
            organizationId: result.organizationId,
            type: { value: String(result.type) },
            voucherStatus: result.voucherStatus,
            payer: { value: result.payer },
            receiveDate: { value: result.receiveDate },
            amount: { value: result.amount },
            paymentMethod: { value: String(result.paymentMethod) },
            description: { value: result.description },
            revenueRecognition: { value: result.revenueRecognition },
            referenceCode: { value: result.referenceCode },
            userGroup: { value: userGroup },
            name: { value: result.partnerName },
            voucherCode: result.voucherCode,
            paymentAccountRequest: {
                id: result.receiveAccountResponse?.id,
                accountName: { value: result.receiveAccountResponse?.accountName },
                accountId: { value: result.receiveAccountResponse?.accountId },
                bankCode: { value: result.receiveAccountResponse?.bankCode },
                isDefault: result.receiveAccountResponse?.isDefault,
            },
            currency: { value: result.currency },
        };
    };

    const onSubmit = async (isSaveTemp: boolean, isPrint?: boolean) => {
        if (!checkValidate()) {
            return;
        }

        try {
            dispatch(showLoading(true));
            const dataSubmit: any = {
                type: Number(model.dataEdit?.type?.value),
                tenantCode: Constants.TENANT_CODE,
                organizationId,
                groupId: model.dataEdit.groupId?.value,
                payer: model.dataEdit?.payer?.value,
                receiveDate: model.dataEdit?.receiveDate?.value,
                amount: model.dataEdit?.amount?.value,
                paymentMethod: Number(model.dataEdit?.paymentMethod?.value),
                description: model.dataEdit?.description?.value,
                revenueRecognition: Number(model.dataEdit?.revenueRecognition?.value),
                referenceCode: model.dataEdit?.referenceCode?.value,
                paymentAccountRequest: {
                    id: model.dataEdit?.paymentAccountRequest?.id,
                    accountName: model.dataEdit?.paymentAccountRequest?.accountName?.value,
                    accountId: model.dataEdit?.paymentAccountRequest?.accountId?.value,
                    bankCode: model.dataEdit?.paymentAccountRequest?.bankCode?.value,
                    isDefault: model.dataEdit?.paymentAccountRequest?.isDefault,
                },
                currency: model?.dataEdit?.currency?.value,
            };

            if (model.dataEdit.id) {
                try {
                    dispatch(showLoading(true));
                    const result = await cashBookService.updateReceive({
                        ...dataSubmit,
                        id: model.dataEdit?.id,
                        voucherStatus: model.dataEdit?.voucherStatus,
                    });
                    if (result.statusCode === Constants.ApiCode.SUCCESS) {
                        // navigate(`${Screens.RECEIVE_VOUCHER_EDIT}?id=${model.dataEdit.id}`,)
                        const data = await getDetailReceive(model.dataEdit.id);
                        setModel({
                            ...model,
                            dataEdit: data,
                            edited: { value: false },
                        });
                        dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
                    }
                    dispatch(showLoading(false));
                } catch (err) {
                    dispatch(showLoading(false));
                    const e = Helpers.renderExceptionError(err);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            } else {
                Helpers.showConfirmAlert(Strings.RECEIVE_VOUCHER.RECEIVE_CONFIRM_SAVE, async () => {
                    try {
                        dispatch(showLoading(true));
                        if (isSaveTemp) dataSubmit.voucherStatus = VoucherHistoryStatus.Pending;
                        else dataSubmit.voucherStatus = VoucherHistoryStatus.Completed;
                        const result = await cashBookService.createReceive(dataSubmit);
                        if (isPrint) {
                            setModel({
                                ...model,
                                edited: { value: false },
                            });
                            await handlePrintOrder(result.result);
                        } else {
                            dispatch(setDataAlert({ message: Strings.Message.CREATE_SUCCESS, type: "success" }));
                            handleGoBack();
                        }
                        dispatch(showLoading(false));
                    } catch (err) {
                        dispatch(showLoading(false));
                        const e = Helpers.renderExceptionError(err);
                        dispatch(setDataAlert({ message: e, type: "error" }));
                    }
                });
            }

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onComplete = () => {
        Helpers.showConfirmAlert(Strings.RECEIVE_VOUCHER.RECEIVE_CONFIRM_SAVE, async () => {
            try {
                dispatch(showLoading(true));
                const result = await cashBookService.completeReceive(model.dataEdit?.id);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    dispatch(setDataAlert({ message: Strings.Message.SUCCESS, type: "success" }));
                    try {
                        dispatch(showLoading(true));
                        const data = await getDetailReceive(model.dataEdit?.id);
                        setModel({
                            ...model,
                            dataEdit: data,
                            edited: { value: false },
                        });
                        dispatch(showLoading(false));
                    } catch (err) {
                        dispatch(showLoading(false));
                        const e = Helpers.renderExceptionError(err);
                        dispatch(setDataAlert({ message: e, type: "error" }));
                    }
                }
                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    const onCancel = () => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_CANCEL, async () => {
            try {
                dispatch(showLoading(true));
                const result = await cashBookService.cancelReceive(model.dataEdit?.id);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    dispatch(setDataAlert({ message: Strings.Message.CANCEL_SUCCESS, type: "success" }));
                    try {
                        dispatch(showLoading(true));
                        const data = await getDetailReceive(model.dataEdit?.id);
                        setModel({
                            ...model,
                            dataEdit: data,
                            edited: { value: false },
                        });
                        dispatch(showLoading(false));
                    } catch (err) {
                        dispatch(showLoading(false));
                        const e = Helpers.renderExceptionError(err);
                        dispatch(setDataAlert({ message: e, type: "error" }));
                    }
                }
                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    const checkValidate = () => {
        let checked = true;
        let data: any = { ...model.dataEdit };
        const listKey: string[] = ["userGroup", "revenueRecognition", "type", "payer", "currency", "amount"];

        listKey.forEach((key) => {
            if (Helpers.isNullOrEmpty(data[key]?.value)) {
                data[key] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
            if (!Helpers.isNullOrEmpty(data[key]?.error)) {
                checked = false;
            }
        });

        if (PaymentType.Banking === Number(model.dataEdit?.paymentMethod?.value)) {
            let paymentAccountRequest = { ...model.dataEdit?.paymentAccountRequest };
            if (Helpers.isNullOrEmpty(paymentAccountRequest["accountId"]?.value)) {
                paymentAccountRequest["accountId"] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
            if (Helpers.isNullOrEmpty(paymentAccountRequest["bankCode"]?.value)) {
                paymentAccountRequest["bankCode"] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
            data["paymentAccountRequest"] = paymentAccountRequest;
        }

        if (!checked) {
            setModel({ ...model, dataEdit: data });
        }

        return checked;
    };

    const handlePrintOrder = async (id: string) => {
        try {
            dispatch(showLoading(true));
            let data = {
                organizationId: organizationId,
                groupId: model.dataEdit.groupId?.value,
                fromDate: moment().unix(),
                toDate: moment().unix(),
                cashBookType: CashBookType.Receive,
                ids: [id],
            };
            const result = await cashBookService.export(Helpers.handleFormatParams(data));
            Helpers.getBase64(result, (data) =>
                printJS({
                    printable: data,
                    type: "pdf",
                    base64: true,
                    onPrintDialogClose: () => handleGoBack(),
                })
            );
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const getDataPayer = async (data: any) => {
        let result: any;
        let resultData: ICodename[] = [];
        let request = {
            organizationId: userProfile?.organizationId || undefined,
            // groupId: dataEdit?.referenceCode?.value ? dataEdit?.referenceCode?.value : undefined,
            partnerType: model.partnerType || undefined,
            pageNumber: data?.pageNumber,
            searchText: data?.searchText,
            pageSize: Constants.ROW_PER_PAGE_10,
        };

        result = await partnerService.getPaged(
            Helpers.handleFormatParams({
                ...request,
            })
        );

        (result.items || []).forEach((item: any) => {
            resultData.push({ code: item.id, name: `${item.partnerName} ${item.phoneNumber ? `(${item.phoneNumber})` : ""}` });
        });

        setListPayer(resultData);
    };

    return (
        <DashboardLayout
            isPermission
            title={model.title}
            route={model.route}
            onBackPress={() => {
                if (!model?.edited?.value) {
                    handleGoBack();
                } else {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                }
            }}
            nameActionPress={model.mode === Mode.Create ? Strings.Common.SAVE : Strings.Common.EDIT}
            onActionPress={
                // model.mode === Mode.Create ||
                Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                    ? undefined
                    : () => onSubmit(false)
            }
            onEditPress={
                Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                    ? undefined
                    : () => {
                          Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Pending ||
                          Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Updated
                              ? onComplete()
                              : onSubmit(true);
                      }
            }
            nameOtherPress={
                Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed
                    ? undefined
                    : Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Pending ||
                      Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Updated
                    ? Strings.Common.COMPLETE
                    : Strings.Common.SAVE_TEMP
            }
            onCancelPress={
                Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Pending ||
                Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Updated
                    ? onCancel
                    : undefined
            }
            colorOtherPress="info"
            onSaveAndPrintPress={!Helpers.isNullOrEmpty(id) ? undefined : async () => await onSubmit(false, true)}
            nameSaveAndPrintPress={Strings.Common.SAVE_AND_PRINT}
        >
            <Grid container spacing={3} mt={2}>
                <Grid item xs={12} md={model.dataEdit?.id ? 9 : 12}>
                    <Box>
                        <Card>
                            <Box p={2}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6">{Strings.RECEIVE_VOUCHER.TITLE_DETAIL_VIEW}</Typography>
                                    {model?.dataEdit?.id && (
                                        <Box pl={3}>
                                            <Button
                                                style={{ cursor: "default" }}
                                                disabled={Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ? true : false}
                                                // color={`${voucherStatusList.find(
                                                //     (el) => el.code === `${model.dataEdit?.voucherStatus}`
                                                // )?.group
                                                //     }`}
                                            >
                                                {voucherStatusList.find((el) => el.code === `${model.dataEdit?.voucherStatus}`)?.name}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                {model.mode === Mode.View && (
                                    <Box sx={{ px: 2, py: 1 }}>
                                        <Typography variant="h6">
                                            {Strings.RECEIVE_VOUCHER.CODE} : {model?.dataEdit?.voucherCode}
                                        </Typography>
                                    </Box>
                                )}
                                <Box component="form">
                                    <Grid container spacing={3} p={2}>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                required
                                                data={userGroupList || []}
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.RECEIVE_VOUCHER.REVENUE_RECOGNITION}
                                                placeholder={Strings.RECEIVE_VOUCHER.REVENUE_RECOGNITION}
                                                defaultValue={model.dataEdit?.userGroup?.value}
                                                errorMessage={model.dataEdit?.userGroup?.error}
                                                onChange={(value) => {
                                                    onValueChange("userGroup", value);
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} md={6}>
                                            <FormField
                                                disabled
                                                value={model.dataEdit?.referenceCode?.value || ""}
                                                errorMessage={model.dataEdit?.referenceCode?.error}
                                                label={Strings.RECEIVE_VOUCHER.VOUCHER_CODE}
                                                placeholder={`${Strings.Common.INPUT} ${Strings.RECEIVE_VOUCHER.VOUCHER_CODE}`}
                                                onChangeValue={(value) => {
                                                    onValueChange("referenceCode", value);
                                                }}
                                            />
                                        </Grid> */}

                                        <Grid item xs={12} md={6}>
                                            <AutocompleteLoadMore
                                                disabled={
                                                    !Helpers.isNullOrEmpty(model?.dataEdit?.userGroup?.value) &&
                                                    (Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                        Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel)
                                                        ? true
                                                        : false
                                                }
                                                key={model?.dataEdit?.payer?.value}
                                                dataOptions={listPayer || []}
                                                errorMessage={model?.dataEdit?.payer?.error}
                                                defaultValue={model?.dataEdit?.payer?.value || ""}
                                                label={Strings.RECEIVE_VOUCHER.PAYER}
                                                placeholder={`${Strings.Common.SELECT} ${Strings.RECEIVE_VOUCHER.PAYER}`}
                                                onChange={(value) => {
                                                    onValueChange("payer", value);
                                                }}
                                                allowLoadMore
                                                onGetAPI={(data) => getDataPayer(data)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                required
                                                data={typeList || []}
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.RECEIVE_VOUCHER.RECEIVE_TYPE}
                                                placeholder={`${Strings.Common.SELECT} ${Strings.RECEIVE_VOUCHER.RECEIVE_TYPE}`}
                                                defaultValue={model.dataEdit?.type?.value}
                                                errorMessage={model.dataEdit?.type?.error}
                                                onChange={(value) => {
                                                    onValueChange("type", value);
                                                }}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                required
                                                data={organizationBranchList || []}
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) ===
                                                        VoucherHistoryStatus.Completed ||
                                                        Number(model?.dataEdit?.voucherStatus) ===
                                                        VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.PRODUCT_TEMPLATE.ORGANIZATION}
                                                placeholder={Strings.PRODUCT_TEMPLATE.SELECT_ORGANIZATION}
                                                defaultValue={model.dataEdit?.groupId?.value}
                                                errorMessage={model.dataEdit?.groupId?.error}
                                                onChange={(value) => {
                                                    onValueChange("groupId", value);
                                                }}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} md={6}>
                                            <DatePicker
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                value={Helpers.getDateValue(model.dataEdit?.receiveDate?.value)}
                                                label={Strings.PAYMENT_TICKET.INVOICE_DATE}
                                                placeholder={Strings.PAYMENT_TICKET.SELECT_INVOICE_DATE}
                                                onChangeValue={(value: any) => onValueChange("receiveDate", value ? moment(value).unix() : undefined)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box mt={3}>
                        <Card>
                            <Box p={2}>
                                <Box>
                                    <Typography variant="h6">{Strings.RECEIVE_VOUCHER.RECEIVE_VALUE}</Typography>
                                </Box>
                                <Box component="form">
                                    <Grid container spacing={3} p={2}>
                                        <Grid item xs={12} md={3}>
                                            <FormField
                                                isMoney
                                                required
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                value={model.dataEdit?.amount?.value || ""}
                                                errorMessage={model.dataEdit?.amount?.error}
                                                label={Strings.RECEIVE_VOUCHER.MONEY}
                                                placeholder={`${Strings.Common.INPUT} ${Strings.RECEIVE_VOUCHER.MONEY}`}
                                                onChangeValue={(value) => {
                                                    onValueChange("amount", value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                required
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                label={Strings.PRODUCT.VALUE}
                                                placeholder={Strings.PRODUCT.INPUT_VALUE}
                                                data={dataCurrency || []}
                                                defaultValue={model?.dataEdit?.currency?.value}
                                                errorMessage={model?.dataEdit?.currency?.error}
                                                onChange={(value) => onValueChange("currency", value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                data={paymentMethodList || []}
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                defaultValue={model.dataEdit?.paymentMethod?.value}
                                                errorMessage={model.dataEdit?.paymentMethod?.error}
                                                label={Strings.RECEIVE_VOUCHER.PAYMENT_METHOD}
                                                placeholder={`${Strings.Common.SELECT} ${Strings.RECEIVE_VOUCHER.PAYMENT_METHOD}`}
                                                onChange={(value) => {
                                                    onValueChange("paymentMethod", value);
                                                }}
                                            />
                                        </Grid>
                                        {Number(model?.dataEdit?.paymentMethod?.value) === PaymentType.Banking && (
                                            <Grid item xs={12} md={6}>
                                                <FormField
                                                    disabled={
                                                        Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed
                                                            ? //     ||
                                                              // (`${PaymentType.CreditCard}` !==
                                                              //     model.dataEdit?.paymentMethod?.value &&
                                                              //     `${PaymentType.BankTransfer}` !==
                                                              //         model.dataEdit?.paymentMethod?.value)
                                                              true
                                                            : false
                                                    }
                                                    label={Strings.RECEIVE_VOUCHER.ACCOUNT}
                                                    placeholder={`${Strings.Common.INPUT} ${Strings.RECEIVE_VOUCHER.ACCOUNT}`}
                                                    value={model.dataEdit?.paymentAccountRequest?.accountId?.value || ""}
                                                    errorMessage={model.dataEdit?.paymentAccountRequest?.accountId?.error}
                                                    onChangeValue={(value) => {
                                                        onValueChange("accountId", value);
                                                    }}
                                                    // required={
                                                    //     `${PaymentType.CreditCard}` ===
                                                    //         model.dataEdit?.paymentMethod?.value ||
                                                    //     `${PaymentType.BankTransfer}` ===
                                                    //         model.dataEdit?.paymentMethod?.value
                                                    // }
                                                />
                                            </Grid>
                                        )}
                                        {Number(model?.dataEdit?.paymentMethod?.value) === PaymentType.Banking && (
                                            <Grid item xs={12} md={6}>
                                                <FormField
                                                    disabled={
                                                        Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed
                                                            ? //     ||
                                                              // (`${PaymentType.CreditCard}` !==
                                                              //     model.dataEdit?.paymentMethod?.value &&
                                                              //     `${PaymentType.BankTransfer}` !==
                                                              //         model.dataEdit?.paymentMethod?.value)
                                                              true
                                                            : false
                                                    }
                                                    label={Strings.RECEIVE_VOUCHER.BANK}
                                                    placeholder={`${Strings.Common.INPUT} ${Strings.RECEIVE_VOUCHER.BANK}`}
                                                    value={model.dataEdit?.paymentAccountRequest?.bankCode?.value || ""}
                                                    errorMessage={model.dataEdit?.paymentAccountRequest?.bankCode?.error}
                                                    onChangeValue={(value) => {
                                                        onValueChange("bankCode", value);
                                                    }}
                                                    // required={
                                                    //     `${PaymentType.CreditCard}` ===
                                                    //         model.dataEdit?.paymentMethod?.value ||
                                                    //     `${PaymentType.BankTransfer}` ===
                                                    //         model.dataEdit?.paymentMethod?.value
                                                    // }
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <FormField
                                                disabled={
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                    Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                        ? true
                                                        : false
                                                }
                                                value={model.dataEdit?.description?.value || ""}
                                                errorMessage={model.dataEdit?.description?.error}
                                                label={Strings.RECEIVE_VOUCHER.DESCRIPTION}
                                                placeholder={Strings.RECEIVE_VOUCHER.DESCRIPTION}
                                                onChangeValue={(value) => {
                                                    onValueChange("description", value);
                                                }}
                                            />
                                        </Grid>

                                        <Grid container p={2}>
                                            <Grid item xs={1} md={1}>
                                                <Switch
                                                    disabled={
                                                        Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Completed ||
                                                        Number(model?.dataEdit?.voucherStatus) === VoucherHistoryStatus.Cancel
                                                            ? true
                                                            : false
                                                    }
                                                    checked={
                                                        Number(model.dataEdit?.revenueRecognition?.value) === RevenueRecognition.True ? true : false
                                                    }
                                                    onChange={(event, checked) => {
                                                        onValueChange("revenueRecognition", checked);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={11} md={11}>
                                                <Typography variant="caption" fontWeight="bold">
                                                    {Strings.RECEIVE_VOUCHER.IS_DEFAULT}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
                {model?.dataEdit?.id && (
                    <Grid item xs={12} md={3}>
                        <Card sx={{ height: "100%" }}>
                            <Box p={2}>
                                <Typography variant="h6" sx={{ textAlign: "center" }}>
                                    {Strings.RECEIVE_VOUCHER.HISTORY_UPDATE}
                                </Typography>
                                <Stepper activeStep={histories?.length - 1} orientation="vertical">
                                    {(histories || []).map((item) => (
                                        <Step key={item.id}>
                                            <StepLabel>
                                                <Typography variant="button" fontWeight="bold" sx={{ display: "block" }}>
                                                    {voucherStatusList.find((el) => el.code === `${item.voucherStatus}`)?.name}
                                                </Typography>
                                                <Typography variant="button" sx={{ fontStyle: "italic", display: "block" }}>
                                                    {moment.unix(Number(`${item.updateTime}`)).format(Constants.DateFormat.DDMMYYYY_HHMM)}
                                                </Typography>
                                                <Typography variant="button" sx={{ fontStyle: "italic", display: "block" }}>
                                                    {`NV: ${item.createUserName}`}
                                                </Typography>
                                                {!Helpers.isNullOrEmpty(item.note) && (
                                                    <Typography
                                                        variant="button"
                                                        sx={{ display: "block" }}
                                                    >{`${Strings.Common.NOTE}: ${item.note}`}</Typography>
                                                )}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Card>
                    </Grid>
                )}
                {/* {modelAddCustomer && (
                    <AddCustomer
                        visibled={modelAddCustomer}
                        isCreateSupplier={
                            Number(model.dataEdit?.userGroup?.value) === UserGroup.Supplier ? true : false
                        }
                        isCreateDelivery={Number(model.dataEdit?.userGroup?.value) === UserGroup.Other ? true : false}
                        isCreateCustomer={
                            Number(model.dataEdit?.userGroup?.value) === UserGroup.Customer ? true : false
                        }
                        onClose={() => setModelAddCustomer(false)}
                        onSave={(data: any) => {
                            let payer = {value: data?.code};
                            let name = {value: data?.name};
                            setModel({
                                ...model,
                                dataEdit: {...model.dataEdit, name, payer},
                            });
                        }}
                    />
                )} */}
            </Grid>
        </DashboardLayout>
    );
};

export default ReceiveVoucherEditScreen;
